import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const UniqueValuePropositionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Unique Value Propositions</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Unique Value Propositions template helps you create a clear statement that describes the benefit of your offer in a powerful way. A strong value proposition is essential for differentiating your brand and attracting customers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Identify Your Target Audience</h3>
            <p>Understand the needs and pain points of your target audience. Tailor your value proposition accordingly to address these specific concerns and demonstrate the value your product or service provides.</p>
<h3 className="text-xl text-indigo-800 mb-2">2. Define Your Differentiators</h3>
<p>Identify what sets your offer apart from competitors. Highlight unique features, benefits, or advantages that your target audience values.</p>
<h3 className="text-xl text-indigo-800 mb-2">3. Craft a Compelling Statement</h3>
<p>Create a concise and compelling statement that communicates the unique benefits and value your offer provides. Make sure it's clear, specific, and resonates with your target audience.</p>
</div>
</section>
<section className="mb-8">
      <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
      <ul className="list-disc list-inside">
        <li><strong>Brand Differentiation:</strong> A unique value proposition helps differentiate your brand from competitors and creates a lasting impression on customers.</li>
        <li><strong>Customer Attraction:</strong> Clearly communicating the benefits of your offer attracts the right customers and encourages them to engage with your brand.</li>
        <li><strong>Increased Conversions:</strong> A compelling value proposition can lead to higher conversion rates and improved overall business performance.</li>
      </ul>
    </section>

    <section>
      <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
      <p className="mt-4">Craft a powerful unique value proposition for your offer <a className="text-md text-indigo-600" href="/templates/unique-value-propositions">here</a>.</p>
    </section>
  </div>
  <ElaborateFooter />
</>
);
};

export default UniqueValuePropositionsPage;
