// src/components/FAQs.js
import React from 'react';

const FAQs = () => {
  return (
    <section className="mt-16 bg-white px-6 py-12 sm:py-12 lg:px-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">Frequently Asked Questions</h2>
      <div className="mt-8 space-y-6">
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">How do I share my referral link?</h3>
          <p className="mt-2 text-gray-600">
            You can easily copy your referral link from your dashboard and share it with friends, family, or followers on social media.
          </p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">When will I receive payouts?</h3>
          <p className="mt-2 text-gray-600">
            Payouts are processed monthly, and you’ll receive your commission directly in your account once you reach the payout threshold.
          </p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">Is there a limit to how much I can earn?</h3>
          <p className="mt-2 text-gray-600">No, there’s no limit! You can earn as much as your referrals spend, so the more you refer, the more you earn.</p>
        </div>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">Can I track my referrals and earnings?</h3>
          <p className="mt-2 text-gray-600">Yes, you can track clicks, signups, and earnings directly in your affiliate dashboard.</p>
        </div>
      </div>
    </section>
  );
};

export default FAQs;

