import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import KeywordsExtractorInput from './assets/KeywordsExtractorOutput.png';
import KeywordsExtractorOutput from './assets/KeywordsExtractorInput.png';


const KeywordsExtractorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Keywords Extractor</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Extract keywords from your content for optimization, SEO, or content creation purposes with the Keywords Extractor tool. Identify relevant terms and phrases that can help improve your search engine rankings, enhance visibility, and attract more traffic to your website or blog.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Analysis:</strong> Analyze text content to identify important keywords and phrases.</li>
            <li><strong>Keyword Suggestions:</strong> Receive suggestions for related keywords based on your input.</li>
            <li><strong>SEO Optimization:</strong> Use extracted keywords to optimize your website or content for search engines.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Content</h3>
            <p>Paste or type the content from which you want to extract keywords into the input field.</p>
            <img src={KeywordsExtractorInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Keywords</h3>
            <p>Click the extract button to generate a list of keywords and phrases from the provided content.</p>
            <img src={KeywordsExtractorOutput} alt="Input Topic" className="mb-4"/>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Improvement:</strong> Enhance your website's visibility and search engine rankings by using relevant keywords.</li>
            <li><strong>Content Optimization:</strong> Optimize your content for search engines and improve its relevance to target keywords.</li>
            <li><strong>Content Creation:</strong> Use extracted keywords to brainstorm new content ideas and topics.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Strategy:</strong> Identify and prioritize keywords for inclusion in your SEO strategy.</li>
            <li><strong>Content Marketing:</strong> Create targeted content based on the keywords most relevant to your audience.</li>
            <li><strong>Website Optimization:</strong> Optimize website pages and blog posts for specific keywords to attract organic traffic.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default KeywordsExtractorPage;

