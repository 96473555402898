import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import BlogTitlesInput from './assets/BlogTitlesInput.png';
import BlogTitlesOutput from './assets/BlogTitlesOutput.png';

const BlogTitlesPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Blog Titles</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Blog Titles provides you with compelling and engaging titles that help boost traffic and engagement. Whether you're writing for your blog or creating marketing content, this tool helps you generate titles that attract readers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Titles:</strong> Generate attention-grabbing titles that entice readers.</li>
            <li><strong>SEO Optimization:</strong> Create titles that are optimized for search engines.</li>
            <li><strong>Customizable:</strong> Tailor titles to match your content's tone and style.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={BlogTitlesInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Titles</h3>
            <img src={BlogTitlesOutput} alt="Generated Titles" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Adjust the generated titles to better fit your content and audience.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Traffic:</strong> Create titles that attract more visitors to your content.</li>
            <li><strong>Improved Engagement:</strong> Engage readers with compelling and relevant titles.</li>
            <li><strong>SEO Benefits:</strong> Enhance your content's search engine ranking with optimized titles.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Generate titles that draw readers to your blog posts.</li>
            <li><strong>Content Creators:</strong> Create catchy titles for videos, podcasts, and other content formats.</li>
            <li><strong>Marketing Teams:</strong> Develop titles for marketing materials that drive engagement and conversions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BlogTitlesPage;
