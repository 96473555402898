import React from 'react'

import { GlobeAltIcon, EnvelopeIcon, PencilSquareIcon, SpeakerWaveIcon,ShoppingCartIcon,ListBulletIcon,} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Age Groups',
    description: 'Find voices from young, middle-age and old age groups',
    icon: ShoppingCartIcon,
  },
  {
    name: 'Genders',
    description: 'Graphia supports male, female & gender neutral voices',
    icon: PencilSquareIcon,
  },
  {
    name: 'Accents',
    description: 'Choose from accents like American, British, Australian, Swdish, Indian, Irish, Italian, & more',
    icon: SpeakerWaveIcon,
  },


]

export default function Example() {
  return (
    <div className="bg-gray-900 bg-gradient-to-r to-[#4665e5] from-[#7746e5] py-12 sm:py-12">
      <div className="mx-auto px-6 lg:px-8" style={{marginLeft: '3rem', marginRight: '3rem'}}>
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-white">Many voices, one platform</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
          	Find voices for your specific need
	  </p>
          <p className="mt-6 text-lg leading-8 text-white">
		Graphia brings to you all kinds of voices from all around the world.
          </p>
        </div>
        <div className="mx-auto mt-16 sm:mt-20 lg:mt-16">
          <dl className="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative border rounded" style={{"padding" : "1.5rem"}}>
                <dt className="text-base font-semibold leading-7 text-white text-2xl mt-0">
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-white">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

