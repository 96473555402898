import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ArticleGeneratorInput from './assets/ArticleGeneratorInput.png';
import ArticleGeneratorOutput1 from './assets/ArticleGeneratorOutput1.png';
import ArticleGeneratorOutput2 from './assets/ArticleGeneratorOutput2.png';

const ArticleGeneratorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Article Generator</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Article Generator allows you to effortlessly generate well-researched and engaging articles. Whether you need content for your blog, website, or publication, this tool saves you time and effort by providing high-quality articles tailored to your specifications.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Effortless Generation:</strong> Generate articles with just a few clicks, saving time and effort.</li>
            <li><strong>Well-Researched Content:</strong> Access articles that are based on thorough research and relevant sources.</li>
            <li><strong>Customization Options:</strong> Customize generated articles to match your style and requirements.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={ArticleGeneratorInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Article</h3>
            <img src={ArticleGeneratorOutput1} alt="Generated Article" className="mb-4"/>
            <img src={ArticleGeneratorOutput2} alt="Generated Article" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated article as needed to meet your specific requirements and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Time-Saving:</strong> Quickly generate articles without spending hours researching and writing.</li>
            <li><strong>Quality Content:</strong> Access well-researched and engaging articles that enhance your online presence.</li>
            <li><strong>Customization:</strong> Customize articles to fit your brand voice and target audience.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Marketers:</strong> Generate content for marketing campaigns and promotions.</li>
            <li><strong>Bloggers:</strong> Quickly create blog posts to maintain a consistent publishing schedule.</li>
            <li><strong>Website Owners:</strong> Add fresh and relevant content to your website to attract and engage visitors.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ArticleGeneratorPage;
