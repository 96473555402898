import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import Graphia from '../assets/GraphiaAI-white.svg'
import {
  Bars3Icon,
  ShoppingCartIcon,
  SparklesIcon,
  SpeakerWaveIcon,
  XMarkIcon,
  ChartBarIcon,
  PencilSquareIcon,
  PhotoIcon,
  ArrowRightEndOnRectangleIcon,
  ChatBubbleLeftRightIcon,


} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


const callsToAction = []

const usecases = [
  { name: 'Marketing', description: 'Ads, Blogs, SEO, Website Content, etc.', href: '/usecases/graphia-marketing', icon: ChartBarIcon },
  { name: 'Sales', description: 'Cold Emails, Sales Pitches, Outreach Sequences, etc.', href: '/usecases/graphia-sales', icon: SparklesIcon },
  { name: 'E-Commerce', description: 'Product Descriptions, Titles, Features, Industry Trends, etc.', href: '/usecases/graphia-ecommerce', icon: ShoppingCartIcon },
]

const products = [
  { name: 'AI Writer', description: 'Generate content with simple prompts', href: '/products/ai-writer', icon: PencilSquareIcon },
  { name: 'AI Voices', description: 'Convert text to speech in human-like voices', href: '/products/ai-voices', icon: SpeakerWaveIcon },
  { name: 'AI Images', description: 'Generate images based on text prompt', href: '/products/ai-images', icon: PhotoIcon },
  { name: 'AI Chats', description: 'Chat with AI assistants', href: '/products/ai-chats', icon: ChatBubbleLeftRightIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-gray-900 bg-gradient-to-r to-[#4665e5] from-[#7746e5] sticky top-0 z-50">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-10" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Graphia AI</span>
            <img className="h-8 w-auto" 
		src={Graphia} 
		alt="Graphia AI" 
		style={{height:'23px'}}
	    />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
	  <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-white">
              Products
              <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900 group-hover:text-indigo-600">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>


          {/*<Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-md font-semibold leading-6 text-white">
              Usecases
              <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {usecases.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900 group-hover:text-indigo-600">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover> */}
	
	  <a href="/templates" className="text-md font-semibold leading-6 text-white">
            Templates
          </a>

          <a href="/pricing" className="text-md font-semibold leading-6 text-white">
            Pricing
          </a>
          <a href="/blogs" className="text-md font-semibold leading-6 text-white">
            Blog
          </a>
          <a href="/affiliates" className="text-md font-semibold leading-6 text-white">
            Affiliates
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
	  <a href="https://app.graphia.ai" className="text-white bg-transparent border border-solid border-white hover:bg-white hover:text-indigo-900 active:bg-white font-bold px-4 py-2.5 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" target="_blank">
	<div className="flex flex-cols-2 gap-2">
  		<ArrowRightEndOnRectangleIcon className="h-6 w-6"/> Login to Dashboard
	</div>
</a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Graphia AI</span>
              <img
                className="h-8 w-auto"
                src={Graphia}
                alt="Graphia AI"
		style={{height:'23px'}}
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
		<Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-md font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Products
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                {/*<Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-md font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Usecases
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...usecases, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure> */}
		<a href="/templates" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Templates
                </a>
  		<a href="/pricing" className="text-md font-semibold leading-6 text-gray-900 -mx-3 block px-3 py-2.5 hover:bg-gray-50 leading-7">
            		Pricing
          	</a>
          	<a href="/blogs" className="text-md font-semibold leading-6 text-gray-900 -mx-3 block px-3 py-2.5 hover:bg-gray-50">
           		 Blog
          	</a>
                <a href="/affiliates" className="text-md font-semibold leading-6 text-gray-900 -mx-3 block px-3 py-2.5 hover:bg-gray-50">
                         Affiliates
                </a>
                <a
                  href="https://app.graphia.ai/"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-md font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  target="_blank"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

