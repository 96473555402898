import React from 'react';

const features = [
  {
    name: 'Narration',
    description: "Craft captivating experiences with our skilled narrators. From audiobooks to documentaries, we bring stories to life with emotion and clarity, leaving a lasting impact.",
    adjectives:["captivating", "emotion", "clarity"],
  },
  {
    name: 'Video Games',
    description: "Elevate gaming with tailored voice solutions. Our dynamic voices bring characters to life, ensuring an unforgettable journey through epic adventures and thrilling battles.",
    adjectives:["versatile", "dynamic", "unforgettable"],
  },
  {
    name: 'Meditation',
    description: "Enhance meditation with our soothing voice solution. Serene narrators guide mindfulness exercises, fostering tranquility and inner peace, promoting relaxation and rejuvenation.", 
    adjectives: ["soothing", "serene", "gentle"],
  },

  { 
    name: 'Conversational',
    description: "Transform conversational AI with our engaging voice solution. Backed by advanced speech tech, our natural voices offer authentic interactions, elevating user experiences for virtual assistants or customer service bots.", 
   adjectives: ["natural", "engaging", "authentic"],
  },
  { 
    name: "Children's Story",
    description: "Ignite imagination with enchanting voices. Vibrant characters and playful narration delight young listeners, fostering a love for reading and igniting creativity in a world of magic and adventure.", 
   adjectives: ["enchanting", "playful", "expressive"],   

  },
  { 
    name: "Audiobook",
    description: "Embark on a literary journey with immersive audiobooks. Expertly narrated voices capture characters and settings with precision and emotion, engaging and inspiring listeners across fiction and classics.", 
    adjectives: ["immersive", "expertly narrated", "precision"],
  },
   {
    name: "News",
    description:"Explore the future of news with AI-powered voice. Delivering authoritative updates, our seamless narration ensures clarity, credibility, and the gravitas of professional broadcasters, keeping you informed and engaged.", 
    adjectives: ["authoritative", "seamless", "insightful"],
  },
   {
    name: "Animation",
    description: "Elevate storytelling with versatile voices. Talented actors breathe personality into animated characters, adding depth and emotion to every line, captivating audiences in worlds of comedy, drama, and beyond.", 
    adjectives: ["versatile", "talented", "captivating"],
  },

   {
    name: "Interactive",
    description: "Immerse in dynamic experiences with our voice solution. From gaming to educational apps, our voices engage seamlessly, enhancing interactivity with expressive tones and natural language processing.",
   adjectives: ["dynamic", "seamless", "engaging"],
  },
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Usecases</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
		Many Needs, One Platform
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          </p>
        </div>
        <div className="mx-auto mt-16 justify-center max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center justify-center gap-x-3 text-base text-xl font-semibold leading-7 text-gray-900">
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base justify-center leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
		  <div class="justify-center my-8 select-none flex">
			{feature.adjectives.map((adjective) =>(
			  <button class="py-2 px-4 no-underline rounded-full bg-white text-indigo-900 font-sans font-semibold text-sm border border-indigo  hover:text-indigo-600 hover:bg-blue-light focus:outline-none mr-2">{adjective}</button>
			))}

		  </div>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

