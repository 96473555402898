
import React from 'react'
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';

export default function RefundPolicy() {

return ( 
<>
<Header/>
<div className="ml-10 mt-10 mb-10">
	<p>Last updated 19th September 2024</p>
<br/>
<br/>

<p>Welcome to the Graphia AI Affiliate Program. By participating in our program, you agree to comply with these Terms and Conditions. Please review them carefully before signing up.</p>
<br/>
<br/>
1. Acceptance of Terms
<br/>
By joining the Graphia AI Affiliate Program, you agree to comply with these Terms and Conditions. We reserve the right to modify these terms at any time, and your continued participation in the program constitutes acceptance of any changes.

<br/>
<br/>
2. Program Enrollment
<br/>
To become an affiliate, you must complete and submit the online application form. Graphia, Inc reserves the right to accept or reject any application at our sole discretion.

<br/>
<br/>

3. Affiliate Links and Promotion
<br/>
Affiliate Links: Upon acceptance into the program, you will receive a unique affiliate link. You must use this link to promote Graphia AI’s products/services.
Promotion Methods: Affiliates may promote our products/services through their website, social media, email newsletters, and other marketing channels. All promotional materials must adhere to our brand guidelines and be approved by us if required.
<br/>
<br/>
4. Commission Structure
<br/>
Commission Rates: Affiliates will earn a commission based on the sales or leads generated through their unique affiliate link. Commission rates will be outlined in your affiliate dashboard or agreement.
<br/>
Payment: Commissions are calculated monthly and paid on a monthly basis. Payments will be made via Bank Transfer, and affiliates must provide accurate payment information.
<br/>
<br/>
5. Tracking and Reporting
<br/>
We use tracking technology to monitor sales and leads generated through affiliate links. Affiliates will have access to a dashboard where they can view performance metrics and commission reports. It is the affiliate’s responsibility to ensure that their tracking information is correct.
<br/>
<br/>
6. Prohibited Activities
<br/>
Affiliates must not engage in the following activities:
<br/>
<br/>
Fraudulent Activities: Any fraudulent or deceptive practices, including click fraud or self-referrals, are strictly prohibited.
<br/>
Unauthorized Use: Use of unauthorized promotional materials, including but not limited to unapproved banners or trademarks, is not allowed.
<br/>
Spamming: Affiliates must not use spam or unsolicited emails to promote our products/services.
<br/>
<br/>
7. Termination
<br/>
We may terminate your participation in the affiliate program at any time, with or without cause, and without prior notice. Upon termination, you must cease all use of our affiliate links and promotional materials and return or destroy any proprietary information.
<br/>
<br/>
8. Intellectual Property
<br/>
Affiliates are granted a non-exclusive, non-transferable license to use our trademarks and logos solely for the purpose of promoting our products/services. This license terminates upon termination of your participation in the program.

<br/>
<br/>
9. Limitation of Liability
<br/>
Graphia, Inc will not be liable for any indirect, incidental, or consequential damages arising out of or related to the affiliate program. Our total liability is limited to the amount of commissions earned.

<br/>
<br/>

10. Confidentiality
<br/>
Affiliates agree to maintain the confidentiality of all non-public information received during the course of their participation in the program. This includes, but is not limited to, sales data, customer information, and marketing strategies.
<br/>
<br/>
11. Governing Law
<br/>
These Terms and Conditions are governed by and construed in accordance with the laws of the United States, Delaware, without regard to its conflict of law principles. Any disputes arising under these terms will be resolved in the courts located in Delaware.
<br/>
<br/>
12. Contact Information
<br/>
For any questions or concerns regarding these Terms and Conditions or the affiliate program, please contact us at:

<br/>
<br/>
Graphia, Inc
<br/>
Email: admin@graphia.ai
<br/>
Website: graphia.ai
<br/>

<br/>
<br/>
<br/>

<br/>
</div>
<ElaborateFooter/>

</>

)

}
