import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import FeaturesAdvantagesBenefitsInput from './assets/FeaturesAdvantagesBenefitsInput.png';
//import FeaturesAdvantagesBenefitsOutput from './assets/FeaturesAdvantagesBenefitsOutput.png';

const FeaturesAdvantagesBenefitsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
      <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Features-Advantages-Benefits</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Features-Advantages-Benefits template is designed to help businesses and marketers create persuasive email marketing campaigns. By inputting product features, the tool generates corresponding advantages and benefits, allowing for more effective communication with potential customers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customizable:</strong> Tailor advantages and benefits to fit the unique selling points of your product.</li>
            <li><strong>Clear Communication:</strong> Present features, advantages, and benefits in a concise and compelling manner.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Product Features</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Advantages and Benefits</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Utilize in Marketing Campaigns</h3>
            <p>Incorporate the advantages and benefits into your email marketing materials and product descriptions.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Effective Communication:</strong> Clearly articulate the value proposition of your product to potential customers.</li>
            <li><strong>Influence Purchase Decisions:</strong> Highlight the benefits that resonate with your target audience, encouraging them to take action.</li>
            <li><strong>Increased Conversions:</strong> Persuasive messaging leads to higher conversion rates and improved sales performance.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default FeaturesAdvantagesBenefitsPage;
