import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ContentShortenerInput from './assets/ContentShortenerInput.png';
import ContentShortenerOutput from './assets/ContentShortenerOutput.png';

const ContentShortenerPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Content Shortener</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Shorten your content in a different voice and style to appeal to different readers. This tool helps you create concise and impactful versions of your content, making it more accessible and engaging.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Concise Content:</strong> Create shorter versions of your content while retaining key messages.</li>
            <li><strong>Enhanced Readability:</strong> Make your content more accessible to a wider audience.</li>
            <li><strong>Customizable:</strong> Tailor the shortened content to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Content</h3>
            <img src={ContentShortenerInput} alt="Input Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Shortened Content</h3>
            <img src={ContentShortenerOutput} alt="Generated Shortened Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the shortened content to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Make your content more engaging by presenting it concisely.</li>
            <li><strong>Improved Accessibility:</strong> Reach a wider audience with shorter, more readable content.</li>
            <li><strong>Time-Saving:</strong> Quickly create concise versions of your content.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Create shorter versions of your blog posts.</li>
            <li><strong>Content Creators:</strong> Make your content more accessible by shortening it.</li>
            <li><strong>Marketers:</strong> Create concise and impactful marketing content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ContentShortenerPage;
