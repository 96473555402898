import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import TikTokScriptInput from './assets/TikTokScriptInput.png';
//import TikTokScriptOutput from './assets/TikTokScriptOutput.png';

const TikTokScriptPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">TikTok Script</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Develop an attention-grabbing TikTok video script that captivates viewers and encourages them to keep watching. This tool helps you create engaging and viral video scripts tailored for TikTok.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Content:</strong> Create scripts that captivate and retain viewer attention.</li>
            <li><strong>Viral Potential:</strong> Craft scripts designed to increase the chances of your video going viral.</li>
            <li><strong>Creative Ideas:</strong> Generate creative and unique video concepts.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Script</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Record and Share</h3>
            <p>Use the generated script to record your TikTok video and share it with your audience.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Create scripts that attract likes, comments, and shares.</li>
            <li><strong>Creative Freedom:</strong> Generate fresh and creative video ideas quickly.</li>
            <li><strong>Time-Saving:</strong> Save time on brainstorming and scriptwriting.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Creators:</strong> Generate unique and engaging content for your TikTok channel.</li>
            <li><strong>Businesses:</strong> Create promotional videos that resonate with your target audience.</li>
            <li><strong>Marketers:</strong> Develop creative video scripts for marketing campaigns.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default TikTokScriptPage;
