import React from 'react';
import PricingComponent from './components/Pricing.js';
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import CTA from './components/CTA.js';
import FAQs from './components/FAQs.js';
import Headline from './components/Headline.js';

function Pricing() {
 return (
   <React.Fragment>
	<Header/>
	<Headline/>
	<PricingComponent/>
        <FAQs/>
	<CTA/>
	<ElaborateFooter/>
   </React.Fragment>
)
}

export default Pricing;


