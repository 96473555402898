import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import TextSummarizerInput from './assets/TextSummarizerInput.png';
import TextSummarizerOutput from './assets/TextSummarizerOutput.png';

const TextSummarizerPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Text Summarizer</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Get the key points from a piece of text. This tool helps you extract the most important information, making it easier to quickly understand and digest large amounts of content.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Key Point Extraction:</strong> Summarize text to highlight the most important information.</li>
            <li><strong>Improved Readability:</strong> Make large amounts of content more digestible.</li>
            <li><strong>Customizable:</strong> Tailor the summary to fit your specific needs and focus.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Text</h3>
            <img src={TextSummarizerInput} alt="Input Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Summary</h3>
            <img src={TextSummarizerOutput} alt="Generated Summary" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the summary to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Efficiency:</strong> Quickly understand large amounts of content.</li>
            <li><strong>Improved Focus:</strong> Extract and focus on the most important information.</li>
            <li><strong>Time-Saving:</strong> Save time by summarizing lengthy texts.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Summarize key points from blog posts.</li>
            <li><strong>Content Creators:</strong> Extract important information from lengthy content.</li>
            <li><strong>Marketers:</strong> Create concise summaries for marketing materials.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default TextSummarizerPage;
