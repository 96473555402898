export default function Example() {
  return (
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
	<h2 className="text-lg font-semibold text-indigo-600">Pricing</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
		Flexible Pricing Plans
        </p>
        <p className="mt-1 text-lg text-gray-600">
		Choose a plan that suits your needs and budget.
        </p>
      </div>
    </div>
  )
}

