import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ListicleInput from './assets/ListicleInput.png';
import ListicleOutput from './assets/ListicleOutput.png';

const ListiclePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Listicle</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate a numbered list based on a topic. Perfect for filling in detail of a blog post. This tool helps organize information into an easily digestible format, making it ideal for blog posts, articles, and presentations.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Organized Content:</strong> Structure information into a clear and concise numbered list.</li>
            <li><strong>Engaging Format:</strong> Capture readers' attention with a visually appealing and easy-to-read format.</li>
            <li><strong>Content Enhancement:</strong> Add depth and detail to blog posts and articles with a listicle format.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Topic</h3>
            <img src={ListicleInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Listicle</h3>
            <img src={ListicleOutput} alt="Generated Listicle" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Review and Customize</h3>
            <p>Review the generated listicle and customize it as needed to fit your content requirements.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Structured Information:</strong> Present information in a logical and organized manner.</li>
            <li><strong>Increased Engagement:</strong> Capture readers' attention with a visually appealing format.</li>
            <li><strong>Content Variety:</strong> Add variety to your content with a listicle format.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Blog Posts:</strong> Enhance blog posts with listicles to break down complex topics.</li>
            <li><strong>Articles:</strong> Structure articles with numbered lists for better readability.</li>
            <li><strong>Presentations:</strong> Create engaging presentations with organized lists.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ListiclePage;
