import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ArticleBlogIdeasInput from './assets/ArticleBlogIdeasInput.png';
import ArticleBlogIdeasOutput1 from './assets/ArticleBlogIdeasOutput1.png';
import ArticleBlogIdeasOutput2 from './assets/ArticleBlogIdeasOutput2.png';


const ArticleBlogIdeasPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Article & Blog Ideas</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Article & Blog Ideas is a tool designed to help businesses and content creators generate fresh, engaging topics for articles and blogs. By providing suggestions tailored to your industry and audience, this tool helps drive increased traffic, generate leads, and boost sales for your business.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Topic Suggestions:</strong> Generate ideas for articles and blogs based on your niche and audience interests.</li>
            <li><strong>SEO Optimization:</strong> Get topic suggestions that are optimized for search engines to improve visibility.</li>
            <li><strong>Trend Analysis:</strong> Access trending topics in your industry to stay relevant and timely.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Niche</h3>
            <img src={ArticleBlogIdeasInput} alt="Input Niche" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Ideas</h3>
            <img src={ArticleBlogIdeasOutput1} alt="Generated Ideas" className="mb-4"/>
            <img src={ArticleBlogIdeasOutput2} alt="Generated Ideas" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Select and Customize</h3>
            <p>Choose the generated ideas that best suit your needs and customize them further to match your brand's voice.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Time-Saving:</strong> Quickly generate a list of relevant topics without spending hours brainstorming.</li>
            <li><strong>Increased Traffic:</strong> Create content that attracts more visitors by leveraging SEO-optimized topics.</li>
            <li><strong>Lead Generation:</strong> Develop engaging content that converts readers into potential customers.</li>
            <li><strong>Enhanced Relevance:</strong> Stay current with industry trends and provide valuable content to your audience.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Regularly update your blog with fresh and engaging content ideas.</li>
            <li><strong>Marketing Teams:</strong> Develop content strategies that align with your marketing goals and audience interests.</li>
            <li><strong>Content Creators:</strong> Find inspiration for your next article, blog post, or social media update.</li>
            <li><strong>Small Businesses:</strong> Generate topics that help you connect with your audience and promote your products or services.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ArticleBlogIdeasPage;
