import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import TweetMachineInput from './assets/TweetMachineInput.png';
//import TweetMachineOutput from './assets/TweetMachineOutput.png';

const TweetMachinePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-black text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-black">Tweet Machine</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-black mb-4">Overview</h2>
          <p>Generate viral tweets that captivate your audience and increase your social media engagement.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Viral Potential:</strong> Create tweets designed to go viral and boost your social media presence.</li>
            <li><strong>Engaging Content:</strong> Generate engaging tweets that resonate with your audience.</li>
            <li><strong>Easy to Use:</strong> Simply input your topic and get a variety of tweet suggestions.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-black mb-2">1. Input Your Topic</h3>
            <h3 className="text-xl text-black mb-2">2. Generate Tweets</h3>
            <h3 className="text-xl text-black mb-2">3. Post Tweets</h3>
            <p>Select the most compelling tweets and post them on your Twitter account.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Boost your social media engagement with captivating tweets.</li>
            <li><strong>Time-Saving:</strong> Generate multiple tweet ideas quickly and efficiently.</li>
            <li><strong>Improved Reach:</strong> Expand your reach by creating tweets that are more likely to be shared.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Social Media Managers:</strong> Create engaging content for your brand's Twitter account.</li>
            <li><strong>Influencers:</strong> Generate viral tweets to grow your follower base.</li>
            <li><strong>Businesses:</strong> Increase your social media presence and engagement.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-black mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-black" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default TweetMachinePage;
