import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import DefinitionInput from './assets/DefinitionInput.png';
import DefinitionOutput from './assets/DefinitionOutput.png';

const DefinitionPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Definition</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>A definition for a word, phrase, or acronym that's used often by your target buyers. This tool helps you create clear and precise definitions to improve understanding.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Clear Definitions:</strong> Provide precise and easy-to-understand definitions.</li>
            <li><strong>Contextual Examples:</strong> Include examples to illustrate the definition.</li>
            <li><strong>Customizable:</strong> Tailor the definitions to fit your specific needs and audience.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-400 mb-2">1. Input the Term</h3>
            <img src={DefinitionInput} alt="Input Term" className="mb-4"/>
            <h3 className="text-xl text-indigo-400 mb-2">2. Generate the Definition</h3>
            <img src={DefinitionOutput} alt="Generated Definition" className="mb-4"/>
            <h3 className="text-xl text-indigo-400 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated definition to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Understanding:</strong> Help your audience understand complex terms and concepts.</li>
            <li><strong>Enhanced Communication:</strong> Provide clear definitions to facilitate better communication.</li>
            <li><strong>Time-Saving:</strong> Quickly generate definitions without manual research.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Marketers:</strong> Define industry-specific terms for your audience.</li>
            <li><strong>Educators:</strong> Provide clear definitions for educational materials.</li>
            <li><strong>Content Creators:</strong> Clarify terms and acronyms used in your content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default DefinitionPage;
