import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ArticleIntrosInput from './assets/ArticleIntrosInput.png';
import ArticleIntrosOutput from './assets/ArticleIntrosOutput.png';

const ArticleIntrosPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Article Intros</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Article Intros helps you create attractive introductions for articles and blogs that captivate your audience's interest. These intros are designed to engage readers right from the start, encouraging them to continue reading.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Introductions:</strong> Generate compelling intros that grab the reader's attention immediately.</li>
            <li><strong>Customizable:</strong> Tailor the introductions to match your content's tone and style.</li>
            <li><strong>SEO-Friendly:</strong> Create intros that are optimized for search engines to improve visibility.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={ArticleIntrosInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Intro</h3>
            <img src={ArticleIntrosOutput} alt="Generated Intro" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Adjust the generated introduction to better fit your content and style.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Capture your readers' attention right from the start, increasing the likelihood they will read the entire article.</li>
            <li><strong>Time-Saving:</strong> Quickly generate effective introductions without spending too much time brainstorming.</li>
            <li><strong>Improved SEO:</strong> Create intros that are optimized for search engines, helping to improve your article's visibility.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Craft engaging introductions that draw readers into your blog posts.</li>
            <li><strong>Content Writers:</strong> Improve the opening of your articles to maintain reader interest.</li>
            <li><strong>Marketing Teams:</strong> Generate captivating intros for content marketing pieces to boost engagement.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ArticleIntrosPage;
