import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import LinkedInPostInput from './assets/LinkedInPostInput.png';
//import LinkedInPostOutput from './assets/LinkedInPostOutput.png';

const LinkedInPostPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">LinkedIn Post</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft an engaging LinkedIn post that captures attention and tells an immersive story about your chosen topic. This tool helps you create professional and compelling LinkedIn posts that drive engagement and connections.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Your Post</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Share on LinkedIn</h3>
            <p>Review and refine your post, then share it directly on LinkedIn to engage your professional network.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Professional Engagement:</strong> Create posts that resonate with your professional audience.</li>
            <li><strong>Time-Saving:</strong> Quickly generate polished content.</li>
            <li><strong>Increased Visibility:</strong> Boost your presence and reach on LinkedIn.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Job Seekers:</strong> Share your achievements and professional updates to attract potential employers.</li>
            <li><strong>Business Owners:</strong> Promote your services, products, or company news.</li>
            <li><strong>Thought Leaders:</strong> Share insights and opinions to establish your expertise.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LinkedInPostPage;
