import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What are the different pricing plans available?",
    answer: "We offer four pricing plans: Monthly, Yearly, Free, Basic, Advanced, and Elite.",
  },
  {
    question: "What is included in the Free plan?",
    answer: "The Free plan is perfect for hobbyists and includes access to the AI Writer with GPT3.5 Turbo model, offering up to 100 credits per month.",
  },
  {
    question: "What features are included in the Basic plan?",
    answer: "The Basic plan is ideal for freelancers and includes access to the AI Writer with GPT4 model, AI Image Generator with DALLE-2 and DALLE-3 models, and email support.",
  },
  {
    question: "What benefits does the Advanced plan offer?",
    answer: "The Advanced plan is suitable for small businesses and includes all features of the Basic plan, plus AI Voiceover with OpenAI and ElevenLabs models, and email support with a shorter response time.",
  },
  {
    question: "What makes the Elite plan stand out?",
    answer: "The Elite plan is perfect for growing teams and includes all features of the Advanced plan, as well as chat support with a one business day response time.",
  },
  {
    question: "Can I switch between plans at any time?",
    answer: "Yes, you can upgrade or downgrade your plan at any time.",
  },
  {
    question: "Is there a limit on the number of workspace members I can invite to collaborate?",
    answer: "No, all plans offer unlimited workspace members for sharing and collaboration. You can invite as many team members as you need.",
  },
  {
    question: "Are there any restrictions on how I can use the credits included in each plan?",
    answer: "Credits can be used for accessing AI services such as writing, image generation, and voiceover. However, there are fair usage policies in place to prevent abuse of the system. Please refer to the terms of service for more details.",
  },
  {
    question: "Can I purchase additional credits if I exceed the monthly limit included in my plan?",
    answer: "Yes, additional credits can be purchased if you exceed the monthly limit included in your plan. Pricing for additional credits may vary depending on your subscription level.",
  },
  {
    question: "What happens if I need technical assistance beyond what is covered in my plan's support offering?",
    answer: "We request you to upgrade to a plan that provides assitance in case you need it."
  },
  {
    question: "Can I cancel my subscription at any time?",
    answer: "Yes, you can cancel your subscription at any time. However, please note that cancellation may result in the loss of access to certain features or services included in your plan. You will be able to access features in the plan till your billing cycle ends. At the end of your billing cycle, your plan will be automatically downgraded",
  },
    {
  question: "Can I get a refund on my cancelled subscription?",
  answer: "No, refunds are not typically offered for subscription cancellations. It's important to review the refund policy outlined in the terms of service before purchasing a subscription. Refunds may only be granted in specific circumstances outlined in the refund policy, such as technical issues or service disruptions that are the responsibility of the provider. Be sure to familiarize yourself with the terms and conditions regarding refunds to understand your rights and obligations as a subscriber."
},
 {
    question: "How can I get unlimited GPT 4o access?",
    answer: "Graphia AI provides uninterrupted, unlimited GPT 4o access with its Basic, Elite & Advanced plans. Along with GPT4o access, you get other features like AI chat, AI image generator, AI voiceover.", 
  },

];


export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-20 sm:py-40 lg:px-8 lg:py-20">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked pricing related questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

