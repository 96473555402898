// src/components/HowItWorks.js
import React from 'react';

const HowItWorks = () => {
  return (
    <section className="mt-16 bg-white px-6 py-12 sm:py-12 lg:px-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">How It Works</h2>
      <div className="flex flex-col md:flex-row mt-8 space-y-8 md:space-y-0 md:space-x-8">
        <div className="flex-1 bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">Step 1: Invite Your Friends</h3>
          <p className="mt-2 text-gray-600">Share your personalized referral link with friends, family, or your audience.</p>
        </div>
        <div className="flex-1 bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">Step 2: They Sign Up</h3>
          <p className="mt-2 text-gray-600">When your friends sign up using your link, they’ll be connected to your account.</p>
        </div>
        <div className="flex-1 bg-white shadow-md p-6 rounded-lg">
          <h3 className="text-lg font-semibold text-gray-800">Step 3: You Earn Commission</h3>
          <p className="mt-2 text-gray-600">You’ll receive 15% commission for every purchase your referrals make, forever!</p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;

