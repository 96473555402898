import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import StoryWriterInput from './assets/StoryWriterInput.png';
import StoryWriterOutput from './assets/StoryWriterOutput.png';

const StoryWriterPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-700 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-700">Story Writer</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-700 mb-4">Overview</h2>
          <p>Create engaging and persuasive stories that will attract your reader's attention and interest. This tool helps you craft compelling narratives to captivate your audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-700 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Narratives:</strong> Craft stories that captivate and engage readers.</li>
            <li><strong>Persuasive Elements:</strong> Use persuasive techniques to make your stories more impactful.</li>
            <li><strong>Customizable:</strong> Tailor your stories to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-700 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-600 mb-2">1. Input Your Ideas</h3>
            <img src={StoryWriterInput} alt="Input Ideas" className="mb-4"/>
            <h3 className="text-xl text-indigo-600 mb-2">2. Generate Your Story</h3>
            <img src={StoryWriterOutput} alt="Generated Story" className="mb-4"/>
            <h3 className="text-xl text-indigo-600 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated story to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-700 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Capture your audience's attention with compelling stories.</li>
            <li><strong>Improved Persuasion:</strong> Influence your readers through persuasive storytelling.</li>
            <li><strong>Time-Saving:</strong> Quickly create engaging stories without starting from scratch.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-700 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Create captivating stories for your blog posts.</li>
            <li><strong>Content Creators:</strong> Craft engaging narratives to enhance your content.</li>
            <li><strong>Marketers:</strong> Develop persuasive stories for marketing campaigns.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-700 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-700" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default StoryWriterPage;
