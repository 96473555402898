import React from 'react';
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import CTA from '../common/CTA.js';
import Descriptions from './assets/descriptions.svg';
import Features from './assets/features.svg';
import Trends from './assets/trends.svg';
import { CheckBadgeIcon} from '@heroicons/react/20/solid'

const descriptions = [
  {
    name: 'Product Description',
    description: 'templates for features, benefits, storytelling & more',
    icon: CheckBadgeIcon,
  },
  {
    name: 'SEO Optimization',
    description: 'Enhanced visibility on search engines for increased discoverability',
    icon: CheckBadgeIcon,
  },
]

const titles = [
  {
    name: 'Feature and Title',
    description: 'Templates for highlighting unique attributes, benefits, and value propositions',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Keyword Optimization',
    description: 'Optimized for search engines to improve product visibility and attract relevant traffic',
    icon: CheckBadgeIcon,
  },
]

const websites = [
	{ 
    name: 'Trend Analysis',
    description: 'Study emerging trends and market dynamics',
    icon: CheckBadgeIcon,
  },
  { 
    name: 'Continuous Updates',
    description: 'Stay updated with real-time data and ongoing trend monitoring',
    icon: CheckBadgeIcon,
  },
]


export default function Marketing() {
  return (
  <React.Fragment>
    <Header/>
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-indigo-600">E-Commerce</p>
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Convert Browsers into Buyers <br/> with AI Content</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
		Increase e-commerce conversions with compelling content for product descriptions,features, titles, benefits & CTAs. 
        </p>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Product Descriptions</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Compelling Narratives. <br/>Irresistible Buyers.</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		 Attract Unstoppable Buyers with E-commerce Product Descriptions that Illuminate the Unique Value and Benefits of Your Offerings
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {descriptions.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
	    src={Descriptions}
            alt="Descriptions"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
	    style={{height: '500px', width : '500px'}}
          />
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Features & Titles</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Turning Heads. <br/> Driving Sales.</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Craft Attention-Grabbing Features and Titles that Propel E-commerce Success, Turning Heads and Driving Conversions.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {titles.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={Features}
              alt="Features"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
	      style={{height: '500px', width : '500px'}}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Industry Trends</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Shifting Digital Landscape? <br/>Stay Ahead of the Curve.</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Proactively Position Your Business to Stay Ahead of Evolving Trends and Capitalize on Emerging Opportunities for Sustainable Growth and Success.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {websites.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={Trends}
            alt="Trends"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
	    style={{height: '500px', width : '500px'}}
          />
        </div>
      </div>
    </div>
   <CTA/>
    <ElaborateFooter/>
 </React.Fragment>
  )
}
 
