import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import BlogOutlineInput from './assets/BlogOutlineInput.png';
import BlogOutlineOutput from './assets/BlogOutlineOutput.png';

const BlogOutlinePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Blog Outline</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Develop structured and engaging blog outlines with our Blog Outline tool. This tool helps you organize your thoughts and structure your blog posts for maximum impact and readability.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Structured Outlines:</strong> Create detailed and organized outlines for your blog posts.</li>
            <li><strong>Customization:</strong> Customize outlines to fit your specific content and style needs.</li>
            <li><strong>SEO-Friendly:</strong> Ensure your blog posts are structured in a way that improves SEO.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={BlogOutlineInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Outline</h3>
            <img src={BlogOutlineOutput} alt="Generated Outline" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated outline to better fit your content requirements.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Efficiency:</strong> Quickly organize your thoughts and structure your blog posts.</li>
            <li><strong>Clarity:</strong> Ensure your blog posts are clear and well-organized.</li>
            <li><strong>Engagement:</strong> Improve reader engagement with well-structured content.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Plan and structure your blog posts effectively.</li>
            <li><strong>Content Marketers:</strong> Create outlines for marketing content to ensure consistency.</li>
            <li><strong>Writers:</strong> Use outlines to organize content for various writing projects.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BlogOutlinePage;
