import React, { useState } from "react";

const CookieConsentBanner = () => {
  const [consentGiven, setConsentGiven] = useState(
    localStorage.getItem("cookie_consent") === "true"
  );

  const handleAccept = () => {
    localStorage.setItem("cookie_consent", "true");
    setConsentGiven(true);
  };

  const handleDeny = () => {
    // Perform any action you want when the user denies consent
    console.log("User denied consent");
  };

  if (consentGiven) {
    return null; // If consent is given, don't render the banner
  }

  return (
    <div className="fixed bottom-4 left-4 bg-gray-800 text-white p-4 rounded-lg cursor-pointer max-w-sm w-full">
     <div className="flex gap-5">
      <p>
	We use cookies to give you the best experience on our website.
      </p>
      
      <div className="flex items-center justify-end gap-1">
        <button
          className="text-purple-500 font-medium mr-2 focus:outline-none"
          onClick={handleAccept}
        >
          Ok
        </button>
      </div>
	</div>
    </div>
  );
};

export default CookieConsentBanner;

