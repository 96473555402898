import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import PainPointsBrainstormingInput from './assets/PainPointsBrainstormingInput.png';
//import PainPointsBrainstormingOutput from './assets/PainPointsBrainstormingOutput.png';

const PainPointsBrainstormingPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Brainstorm Pain Points</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Identify pain points and language for target personas to enhance your marketing strategy.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Persona Analysis:</strong> Understand your target personas' pain points.</li>
            <li><strong>Customizable Templates:</strong> Tailor pain points to fit your audience's needs.</li>
            <li><strong>Language Optimization:</strong> Use the right language to resonate with your audience.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Persona Details</h3>
            {/*<img src={PainPointsBrainstormingInput} alt="Input Persona Details" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Pain Points</h3>
            {/*<img src={PainPointsBrainstormingOutput} alt="Generated Pain Points" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Implement</h3>
            <p>Review identified pain points and integrate them into your strategy.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Better Audience Understanding:</strong> Gain insights into your audience's challenges.</li>
            <li><strong>Enhanced Messaging:</strong> Craft messages that resonate with your audience's needs.</li>
            <li><strong>Improved Engagement:</strong> Increase engagement by addressing specific pain points.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default PainPointsBrainstormingPage;
