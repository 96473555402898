import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const HomepageTitleMetaDescriptionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Homepage - Title and Meta Descriptions</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Homepage - Title and Meta Descriptions template is designed to help you write SEO-optimized title tags and meta descriptions for your homepage. A well-crafted title and meta description can significantly impact your website's search engine rankings and click-through rates.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Identify Key Keywords</h3>
            <p>Identify relevant keywords and phrases that your target audience is likely to search for.</p>
            <h3 className="text-xl text-indigo-800 mb-2">2. Craft Compelling Titles</h3>
            <p>Create attention-grabbing titles that accurately describe the content of your homepage while incorporating target keywords.</p>
            <h3 className="text-xl text-indigo-800 mb-2">3. Write Informative Meta Descriptions</h3>
            <p>Write concise meta descriptions that summarize the content of your homepage and encourage users to click through to your site.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Visibility:</strong> SEO-optimized title tags and meta descriptions improve your website's visibility in search engine results pages (SERPs).</li>
            <li><strong>Higher Click-Through Rates:</strong> Compelling titles and meta descriptions attract more clicks from search engine users, driving traffic to your site.</li>
            <li><strong>Enhanced User Experience:</strong> Clear and informative meta descriptions provide users with valuable information about your homepage, improving user experience.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4">Optimize your homepage for search engines with effective title tags and meta descriptions <a className="text-md text-indigo-600" href="/templates/homepage-title-meta-descriptions">here</a>.</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default HomepageTitleMetaDescriptionsPage;
