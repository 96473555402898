import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import BulletPointAnswersInput from './assets/BulletPointAnswersInput.png';
import BulletPointAnswersOutput from './assets/BulletPointAnswersOutput.png';

const BulletPointAnswersPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Bullet Point Answers</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Provide precise and informative answers that offer quick and valuable points to your customers' questions. This tool helps you create concise and impactful responses.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Concise Answers:</strong> Provide quick and valuable points in response to questions.</li>
            <li><strong>Enhanced Readability:</strong> Make your answers more accessible and easier to understand.</li>
            <li><strong>Customizable:</strong> Tailor your answers to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Question</h3>
            <img src={BulletPointAnswersInput} alt="Input Question" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Bullet Points</h3>
            <img src={BulletPointAnswersOutput} alt="Generated Bullet Points" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated bullet points to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Clarity:</strong> Provide clear and concise answers to questions.</li>
            <li><strong>Improved Engagement:</strong> Make your responses more engaging and easy to read.</li>
            <li><strong>Time-Saving:</strong> Quickly create informative answers without starting from scratch.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customer Support:</strong> Provide quick and informative answers to customer queries.</li>
            <li><strong>Content Creators:</strong> Create concise responses for your content.</li>
            <li><strong>Educators:</strong> Develop clear and precise answers for student questions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BulletPointAnswersPage;
