import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import LinkedInBooleanSearchInput from './assets/LinkedInBooleanSearchInput.png';
//import LinkedInBooleanSearchOutput from './assets/LinkedInBooleanSearchOutput.png';

const LinkedInBooleanSearchPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">LinkedIn Boolean Search</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Conduct more targeted searches on LinkedIn Sales Navigator using boolean search techniques. Boolean searches allow you to combine keywords and operators to find specific profiles that match your criteria.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Precise Searches:</strong> Use boolean operators to refine your searches.</li>
            <li><strong>Time-Saving:</strong> Find the right profiles faster and more efficiently.</li>
            <li><strong>Enhanced Targeting:</strong> Reach more relevant prospects.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Search Criteria</h3>
            {/*<img src={LinkedInBooleanSearchInput} alt="Input Search Criteria" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Boolean Search</h3>
            {/*<img src={LinkedInBooleanSearchOutput} alt="Generated Boolean Search" className="mb-4" /> */}
            <h3 className="text-xl text-indigo-700 mb-2">3. Execute and Review</h3>
            <p>Execute the search on LinkedIn Sales Navigator and review the results.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Better Leads:</strong> Identify high-quality leads more easily.</li>
            <li><strong>Increased Efficiency:</strong> Save time and effort in your prospecting process.</li>
            <li><strong>Improved ROI:</strong> Maximize your return on investment in sales tools.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LinkedInBooleanSearchPage;
