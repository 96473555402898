import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const CustomerCaseStudyPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to Templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Customer Case Study</h1>
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>The Customer Case Study template is designed to help businesses create compelling customer success stories. By highlighting the positive experiences of your satisfied customers, you can build trust with potential clients and showcase the value of your products or services.</p>
        </section>
        
        {/* How It Works Section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <ol className="list-decimal list-inside">
            <li><strong>Collect Information:</strong> Gather details about the customer's experience with your product or service.</li>
            <li><strong>Structure the Case Study:</strong> Organize the information into a compelling narrative that highlights the key benefits and outcomes.</li>
            <li><strong>Visualize Success:</strong> Include visuals such as graphs, charts, or testimonials to add credibility to the case study.</li>
            <li><strong>Share and Promote:</strong> Publish the case study on your website, social media platforms, and marketing materials to attract potential customers.</li>
          </ol>
        </section>
        
        {/* Benefits Section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Builds Credibility:</strong> Customer case studies provide tangible evidence of your product's effectiveness and value.</li>
            <li><strong>Drives Sales:</strong> Compelling success stories can influence purchasing decisions and drive sales conversions.</li>
            <li><strong>Engages Prospects:</strong> Stories of real-world success capture the attention of potential customers and encourage engagement.</li>
            <li><strong>Strengthens Relationships:</strong> Highlighting positive customer experiences fosters trust and strengthens relationships with existing and potential clients.</li>
          </ul>
        </section>

        {/* Use Cases Section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>B2B Companies:</strong> Showcase how your product or service has helped other businesses achieve their goals.</li>
            <li><strong>SaaS Providers:</strong> Highlight the success stories of clients who have experienced significant improvements with your software.</li>
            <li><strong>E-commerce Stores:</strong> Share customer testimonials and case studies to demonstrate the benefits of your products.</li>
            <li><strong>Service Providers:</strong> Illustrate the value of your services by sharing real-world examples of successful projects or collaborations.</li>
          </ul>
        </section>

        {/* How to Use This Template Section */}
        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">How to Use This Template</h2>
          <p className="mt-4">To create a compelling customer case study:</p>
          <ol className="list-decimal list-inside">
            <li>Identify a satisfied customer willing to participate in the case study.</li>
            <li>Gather information about their experience with your product or service, focusing on key benefits and outcomes.</li>
            <li>Structure the case study into a narrative format, highlighting the customer's journey and success story.</li>
            <li>Include visuals such as testimonials, charts, or graphs to enhance credibility and engagement.</li>
            <li>Publish and promote the case study across various channels to attract potential customers and showcase your brand's success.</li>
          </ol>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default CustomerCaseStudyPage;
