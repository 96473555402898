// FacebookAdPage.js
import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import FacebookAdInput from './assets/FacebookAdInput.png';
import FacebookAdOutput from './assets/FacebookAdOutput.png';


const FacebookAdPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Facebook Ad</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Create compelling ad copy for effective Facebook campaigns with the Facebook Ad template. Craft attention-grabbing messages that resonate with your target audience and drive engagement.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Compelling Copy:</strong> Write persuasive ad copy that captures audience attention.</li>
            <li><strong>Engagement:</strong> Craft messages that encourage audience interaction and clicks.</li>
            <li><strong>Targeted Messaging:</strong> Tailor ad content to specific demographics and interests.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Define Your Audience</h3>
            <p>Identify your target audience and their interests to create relevant ad content.</p>
            <h3 className="text-xl text-indigo-500 mb-2">2. Give Product Benefits</h3>
            <p>Write persuasive ad copy that highlights the benefits of your product or service.</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Include a Call to Action</h3>
            <p>Encourage audience engagement with a clear call to action prompting them to take the next step.</p>
            <img src={FacebookAdInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-500 mb-2">4. Generate Ad Copies</h3>
            <img src={FacebookAdOutput} alt="Input Topic" className="mb-4"/>

          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increase Engagement:</strong> Drive more clicks and conversions with compelling ad copy.</li>
            <li><strong>Targeted Messaging:</strong> Reach your ideal audience with tailored ad content.</li>
            <li><strong>Improved ROI:</strong> Maximize your advertising budget with ads that generate results.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Promotion:</strong> Promote new products or services to a targeted audience.</li>
            <li><strong>Lead Generation:</strong> Capture leads by offering valuable resources or incentives in your ad copy.</li>
            <li><strong>Event Promotion:</strong> Drive attendance to events or webinars with engaging ad content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default FacebookAdPage;
