// ContentRewriterPage.js
import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const ContentRewriterPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Content Rewriter</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Enhance content quality through revision with the Content Rewriter tool. Refine and improve your written material to make it more engaging, informative, and effective in communicating your message.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Enhancement:</strong> Improve the quality and clarity of your content through rewriting.</li>
            <li><strong>Language Polishing:</strong> Polish language usage, grammar, and style to enhance readability.</li>
            <li><strong>SEO Optimization:</strong> Optimize content for search engines by incorporating relevant keywords and phrases.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Input Content</h3>
            <p>Paste or type the content you want to rewrite into the input field.</p>
            <h3 className="text-xl text-indigo-500 mb-2">2. Rewrite Content</h3>
            <p>Click the rewrite button to generate a revised version of the input content.</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Review and Edit</h3>
            <p>Review the rewritten content and make any necessary edits or adjustments.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Quality:</strong> Enhance the quality and effectiveness of your written material.</li>
            <li><strong>Language Enhancement:</strong> Polish language usage and style for clearer and more concise communication.</li>
            <li><strong>SEO Enhancement:</strong> Optimize content for search engines to improve visibility and reach.</li>
          </ul>
        </section>

<section className="mb-8">
  <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
  <ul className="list-disc list-inside">
    <li><strong>Content Creation:</strong> Enhance the quality of blog posts, articles, and other written content.</li>
    <li><strong>Marketing Materials:</strong> Improve the effectiveness of marketing copy and promotional materials.</li>
    <li><strong>SEO Content:</strong> Optimize website content for search engines to increase organic traffic.</li>
  </ul>
</section>

<section>
  <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
  <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
</section>
</div>
<ElaborateFooter />
</>
);
};

export default ContentRewriterPage;

