import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import RewriteWithKeywordsInput from './assets/RewriteWithKeywordsInput.png';
import RewriteWithKeywordsOutput from './assets/RewriteWithKeywordsOutput.png';

const RewriteWithKeywordsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Rewrite with Keywords</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Rewrite your existing content to include more keywords and boost your search engine rankings. This tool helps optimize your content for better visibility and reach.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Optimization:</strong> Enhance your content with relevant keywords to improve search engine rankings.</li>
            <li><strong>Content Refresh:</strong> Update existing content to keep it relevant and competitive in search results.</li>
            <li><strong>Keyword Suggestions:</strong> Receive suggestions for relevant keywords to incorporate into your content.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Content</h3>
            <img src={RewriteWithKeywordsInput} alt="Input Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Rewritten Content</h3>
            <img src={RewriteWithKeywordsOutput} alt="Generated Rewritten Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Review and Optimize</h3>
            <p>Review the rewritten content and optimize it further as needed to achieve desired keyword density and relevance.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved SEO:</strong> Boost your search engine rankings by incorporating relevant keywords.</li>
            <li><strong>Content Enhancement:</strong> Refresh and optimize existing content for better performance.</li>
            <li><strong>Time Saving:</strong> Quickly rewrite content with suggested keywords, saving time and effort.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Marketing:</strong> Optimize blog posts, articles, and web pages for improved visibility.</li>
            <li><strong>SEO Strategy:</strong> Enhance website content to align with SEO goals and target keywords.</li>
            <li><strong>Content Refresh:</strong> Update older content with relevant keywords to maintain its relevance and effectiveness.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default RewriteWithKeywordsPage;
