import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import YouTubeVideoDescriptionInput from './assets/YouTubeVideoDescriptionInput.png';
//import YouTubeVideoDescriptionOutput from './assets/YouTubeVideoDescriptionOutput.png';

const YouTubeVideoDescriptionPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">YouTube Video Description</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft a concise and compelling YouTube video description that encourages your target audience to watch the video on your chosen topic.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Optimization:</strong> Includes keywords to help your video rank higher in search results.</li>
            <li><strong>Engaging Content:</strong> Write descriptions that capture attention and drive views.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Video Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Description</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Use Description</h3>
            <p>Copy the generated description and use it in your YouTube video details.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Views:</strong> Engaging descriptions attract more viewers.</li>
            <li><strong>Better Engagement:</strong> Informative descriptions encourage viewers to like, comment, and subscribe.</li>
            <li><strong>Improved SEO:</strong> Optimized descriptions help your videos rank higher.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Creators:</strong> Enhance your video descriptions to grow your audience.</li>
            <li><strong>Businesses:</strong> Promote products or services through engaging video content.</li>
            <li><strong>Educational Channels:</strong> Provide detailed descriptions for educational content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default YouTubeVideoDescriptionPage;
