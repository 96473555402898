import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ArticleOutlinesInput from './assets/ArticleOutlinesInput.png';
import ArticleOutlinesOutput from './assets/ArticleOutlinesOutput.png';

const ArticleOutlinesPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Article Outlines</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Article Outlines provides detailed outlines to assist you in producing consistently improved content. Whether you're writing blog posts, articles, or essays, these outlines help organize your thoughts and structure your writing for maximum impact.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Detailed Outlines:</strong> Get comprehensive outlines tailored to various types of content and subjects.</li>
            <li><strong>Structure Guidance:</strong> Follow a proven structure that ensures your content is well-organized and coherent.</li>
            <li><strong>Customizable:</strong> Adapt the outlines to suit your specific writing style and preferences.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={ArticleOutlinesInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Outline</h3>
            <img src={ArticleOutlinesOutput} alt="Generated Outline" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Adjust the outline as needed to fit your specific requirements and writing style.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Efficiency:</strong> Save time by starting with a structured outline rather than a blank page.</li>
            <li><strong>Consistency:</strong> Ensure that your content maintains a consistent quality and style.</li>
            <li><strong>Clarity:</strong> Clarify your thoughts and arguments by organizing them into a logical framework.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Writers:</strong> Use outlines to streamline your writing process and produce high-quality content efficiently.</li>
            <li><strong>Students:</strong> Organize your essays and research papers with structured outlines that ensure coherence and clarity.</li>
            <li><strong>Bloggers:</strong> Plan your blog posts and articles more effectively to engage your audience and drive traffic.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ArticleOutlinesPage;
