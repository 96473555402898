import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import GMBProductDescriptionInput from './assets/GMBProductDescriptionInput.png';
//import GMBProductDescriptionOutput from './assets/GMBProductDescriptionOutput.png';

const GMBProductDescriptionPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Google My Business - Product Description</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate compelling product descriptions for your Google My Business listing to attract more customers and enhance your local SEO.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Optimized:</strong> Ensure your product descriptions are optimized for local search visibility.</li>
            <li><strong>Engaging Content:</strong> Craft engaging and informative descriptions that highlight the benefits of your products.</li>
            <li><strong>Easy to Use:</strong> Simply input product details and generate professional descriptions in seconds.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Product Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Description</h3>

            <h3 className="text-xl text-indigo-800 mb-2">3. Use Description</h3>
            <p>Copy the generated product description and add it to your Google My Business listing.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Visibility:</strong> Improve your local SEO and attract more customers.</li>
            <li><strong>Enhanced Engagement:</strong> Create compelling descriptions that engage potential customers.</li>
            <li><strong>Professional Quality:</strong> Generate professional-quality descriptions effortlessly.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Local Businesses:</strong> Enhance your local SEO and attract more customers.</li>
            <li><strong>E-commerce Stores:</strong> Improve product visibility and engagement on Google My Business.</li>
            <li><strong>Service Providers:</strong> Highlight the benefits of your services to attract more clients.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default GMBProductDescriptionPage;
