import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import BlogPostLongFormInput from './assets/BlogPostLongFormInput.png';
import BlogPostLongFormOutput from './assets/BlogPostLongFormOutput.png';

const BlogPostLongFormPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Blog Post (Long form)</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft engaging and informative long-form blog posts in 7000-8000 words. This tool helps you create comprehensive content that delves deeply into your chosen topics, providing value and insights to your readers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>In-depth Content:</strong> Generate detailed and comprehensive blog posts.</li>
            <li><strong>SEO Optimization:</strong> Ensure your long-form content is optimized for search engines.</li>
            <li><strong>Customizable:</strong> Tailor the content to match your specific requirements and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={BlogPostLongFormInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Content</h3>
            <img src={BlogPostLongFormOutput} alt="Generated Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated content to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Comprehensive Coverage:</strong> Provide thorough and detailed coverage of your topics.</li>
            <li><strong>Engagement:</strong> Keep readers engaged with well-researched and in-depth content.</li>
            <li><strong>SEO Benefits:</strong> Improve search engine rankings with high-quality, long-form content.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Marketers:</strong> Create detailed blog posts to boost SEO and drive traffic.</li>
            <li><strong>Bloggers:</strong> Provide in-depth analysis and insights on various topics.</li>
            <li><strong>Educators:</strong> Develop comprehensive articles for educational purposes.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BlogPostLongFormPage;
