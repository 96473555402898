import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ProductNameGeneratorInput from  './assets/ProductNameGeneratorInput.png';
import ProductNameGeneratorOutput from  './assets/ProductNameGeneratorOutput.png';


const ProductNameGeneratorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Product Name Generator</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate catchy and meaningful names and slogans for your products or services with the Product Name Generator. Whether you're launching a new product, rebranding, or looking for inspiration, this tool can help you find the perfect name that resonates with your target audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Keyword Input:</strong> Provide keywords or phrases related to your product or industry.</li>
            <li><strong>Customization Options:</strong> Choose from different naming styles and formats to suit your brand.</li>
            <li><strong>Slogan Generation:</strong> Generate catchy slogans to complement your product name.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Keywords</h3>
            <p>Enter keywords or phrases related to your product or service.</p>
            <h3 className="text-xl text-indigo-800 mb-2">2. Input Description </h3>
            <p>Describe your product or service.</p>
            <img src={ProductNameGeneratorInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Generate Names</h3>
            <p>Click the generate button to receive a list of potential names and slogans.</p>
            <img src={ProductNameGeneratorOutput} alt="Input Topic" className="mb-4"/>

          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Brand Identity:</strong> Create a strong brand identity with a memorable and relevant product name.</li>
            <li><strong>Marketability:</strong> Increase the marketability of your product with a catchy and compelling name.</li>
            <li><strong>Competitive Advantage:</strong> Stand out from competitors with a unique and distinctive product name.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Launch:</strong> Name new products to attract attention and generate interest.</li>
            <li><strong>Rebranding:</strong> Rename existing products to align with updated brand messaging or target audience.</li>
            <li><strong>Marketing Campaigns:</strong> Generate catchy slogans for promotional materials and advertisements.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ProductNameGeneratorPage;
