import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import AmazonProductTitlesInput from './assets/AmazonProductTitlesInput.png';
//import AmazonProductTitlesOutput from './assets/AmazonProductTitlesOutput.png';

const AmazonProductTitlesPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Amazon Product Titles</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Product titles that will make your product stand out in a sea of competition.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Attention-Grabbing:</strong> Create titles that capture shopper attention.</li>
            <li><strong>Keyword Optimized:</strong> Use relevant keywords to improve search ranking.</li>
            <li><strong>Clear and Concise:</strong> Communicate product benefits effectively.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Product Information</h3>
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Product Titles</h3>
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Use</h3>
            <p>Review the generated titles and use them for your Amazon listings.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Visibility:</strong> Make your products more discoverable.</li>
            <li><strong>Higher Click-Through Rate:</strong> Increase the chances of shoppers clicking on your product.</li>
            <li><strong>Boosted Sales:</strong> Drive more sales with compelling product titles.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default AmazonProductTitlesPage;
