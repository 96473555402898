import React from 'react';
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import CTA from './components/CTA.js';
import TemplatesComponent from './components/Templates.js';

function Pricing() {
 return (
   <React.Fragment>
	<Header/>
	<TemplatesComponent/>
	<CTA/>
	<ElaborateFooter/>
   </React.Fragment>
)
}

export default Pricing;


