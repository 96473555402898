import React from 'react';
import { GlobeAltIcon, EnvelopeIcon, PencilSquareIcon, SpeakerWaveIcon, ShoppingCartIcon, ListBulletIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Product Descriptions',
    description: 'Create a perfect description for your products to engage your customers to click and buy.',
    icon: ShoppingCartIcon,
  },
  {
    name: 'Blog Post & Articles',
    description: 'Generate optimized blog post and articles to get organic traffic - making you visible to the world.',
    icon: PencilSquareIcon,
  },
  {
    name: 'Social Media Ads',
    description: 'Create ads copies for your social media - make an impact with your online marketing campaigns.',
    icon: SpeakerWaveIcon,
  },
  {
    name: 'Product Benefits',
    description: 'Create a bullet point list of your product benefits that appeal to your customers to purchase.',
    icon: ListBulletIcon,
  },
  { 
    name: 'Website Content',
    description: 'Write very attractive headlines, slogans or paragraph for your landing page of your website.',
    icon: GlobeAltIcon,
  },
  { 
    name: 'Sales & Marketing Emails',
    description: 'Write compelling copy for sales and marketing emails that will convert users into buyers.',
    icon: EnvelopeIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-white py-12 sm:py-12">
      <div className="mx-auto px-6 lg:px-8" style={{ marginLeft: '3rem', marginRight: '3rem' }}>
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">All-in-one Platform</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            AI Generated content in seconds
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Graphia solves for many everyday usecases of sales, marketing & e-commerce professionals.
          </p>
        </div>
        <div className="mx-auto mt-16 sm:mt-20 lg:mt-24">
          <dl className="grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative rounded-lg overflow-hidden shadow-md border border-gray-300">
                <div className="relative bg-gradient-to-r to-[#4665e5] from-[#7746e5] p-4 flex items-center justify-center">
                  <span className="sr-only">{feature.name}</span>
                  <div className="absolute inset-0 bg-no-repeat bg-contain bg-center" style={{ backgroundImage: `url(${feature.icon})`, opacity: '1' }} aria-hidden="true" />
                  <dt className="font-semibold leading-7 text-white text-2xl mt-4 text-center">
                    {feature.name}
                  </dt>
                </div>
                <dd className="mt-2 mb-2 text-base leading-7 text-gray-600 px-4 pb-4 text-center overflow-hidden" style={{ maxHeight: '4.8em', textOverflow: 'ellipsis' }}>
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

