import React, { useState } from 'react';
import { features } from './presets.js';

const groupedFeatures = features.reduce((acc, feature) => {
  if (!acc[feature.category]) {
    acc[feature.category] = [];
  }
  acc[feature.category].push(feature);
  return acc;
}, {});

for (const category in groupedFeatures) {
  groupedFeatures[category].sort((a, b) =>
    a.title.toLowerCase().localeCompare(b.title.toLowerCase())
  );
}

const sortedCategories = Object.keys(groupedFeatures).sort();

// Define colors for each category
const categoryColors = {
  'Ads': 'border-black-800',
  'Email': 'border-black-800',
  'Sales': 'border-black-800',
  'Social Media': 'border-black-800',
  'Website': 'border-black-800',
  'Writing Tools': 'border-black-800',
  'E-Commerce': 'border-black-800',
  // Add more categories and colors as needed
};

export default function Example() {
  const [activeTab, setActiveTab] = useState('All');

  const filteredFeatures = features.filter(
    (feature) => activeTab === 'All' || feature.category === activeTab
  );

  return (
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="text-lg font-semibold text-indigo-600">Templates</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Library of all of our templates
        </p>
        <p className="mt-1 text-lg text-gray-600">
          Explore a vast collection of templates to ignite your creativity and streamline your
          projects.
        </p>
        <div className="mt-8">
          <div className="flex flex-wrap justify-center sm:justify-center space-x-4 sm:space-x-6">
            <button
              key="All"
              className={`py-2 px-4 my-2 mx-4 rounded-lg font-semibold transition-all duration-300 ${
                activeTab === 'All'
                  ? 'bg-indigo-600 text-white shadow-lg transform scale-105'
                  : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
              onClick={() => setActiveTab('All')}
            >
              All
            </button>
            {sortedCategories.map((category) => (
              <button
                key={category}
                className={`py-2 px-4 my-2 mx-4 rounded-lg font-semibold transition-all duration-300 ${
                  activeTab === category
                    ? 'bg-indigo-600 text-white shadow-lg transform scale-105'
                    : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
                onClick={() => setActiveTab(category)}
              >
                <span className="inline-block px-3 py-1 rounded-full ">{category}</span>
              </button>
            ))}
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 relative">
          {filteredFeatures.map((feature) => (
            <div key={feature.title} className="pt-6 flex">
              <div className={`flex-grow flex flex-col px-6 py-4 rounded-lg relative border-double border-4 ${categoryColors[feature.category] || ''} hover:shadow-lg transition-transform transform hover:scale-105`}>
                {/* Render multiple icon elements in the background */}
                
                <div className="flex-grow flex flex-col justify-center">
                  <h3 className="mt-2 text-2xl font-semibold leading-8 tracking-tight text-gray-900">
                    {feature.title}
                  </h3>
                  <p className="mt-1 text-sm font-medium text-gray-500">{feature.category}</p>
                  <p className="mt-3 leading-6 text-gray-900">{feature.description}</p>
                  <a href={feature.href} className="mt-auto text-indigo-600 text-sm cursor-pointer text-right">Learn More</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
