import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import CallToActionInput from './assets/CallToActionInput.png';
//import CallToActionOutput from './assets/CallToActionOutput.png';

const CallToActionPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
      <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Call to Action</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Call to Action tool provides you with eye-catching calls to action that will encourage conversions and boost your sales. By generating compelling CTAs tailored to your specific needs, this tool helps you drive action and engagement from your audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customizable CTAs:</strong> Create CTAs that align with your brand and resonate with your audience.</li>
            <li><strong>Variety of Options:</strong> Generate a wide range of CTA options to suit different marketing objectives.</li>
            <li><strong>Visual Preview:</strong> See how your CTAs will appear in real-world scenarios before implementation.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Input Marketing Details</h3>
            <h3 className="text-xl text-indigo-500 mb-2">2. Generate Call to Action</h3>
            <h3 className="text-xl text-indigo-500 mb-2">3. Preview and Implement</h3>
            <p>Preview the CTAs and choose the most effective one for your marketing campaign. Implement it across your marketing materials.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Conversions:</strong> Compelling CTAs prompt users to take action, resulting in higher conversion rates.</li>
            <li><strong>Improved Engagement:</strong> Clear and persuasive CTAs encourage audience interaction and engagement.</li>
            <li><strong>Enhanced Sales:</strong> Well-designed CTAs drive sales by directing customers to specific actions and offers.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default CallToActionPage;
