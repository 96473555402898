import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const ProductPageTitleMetaDescriptionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
      <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Product Page - Title and Meta Descriptions</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Product Page - Title and Meta Descriptions tool is designed to help e-commerce businesses and website owners create SEO-optimized title tags and meta descriptions for their product pages. By leveraging this tool, you can improve the visibility of your product pages on search engine results pages (SERPs) and attract more organic traffic to your online store.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Optimization:</strong> Generate title tags and meta descriptions that are optimized for search engines.</li>
            <li><strong>Ranking Improvement:</strong> Improve the ranking of your product pages on Google and other search engines.</li>
            <li><strong>Increased Visibility:</strong> Attract more organic traffic by enhancing the visibility of your product pages in search results.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <p>The tool analyzes your product page content and generates title tags and meta descriptions that are relevant, descriptive, and optimized for search engines. You can then implement these suggestions on your website to improve its SEO performance.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>E-commerce Stores:</strong> Online retailers can use the tool to improve the SEO performance of their product pages and attract more customers.</li>
            <li><strong>Product Manufacturers:</strong> Manufacturers can optimize their product pages for relevant keywords and increase their visibility in search results.</li>
            <li><strong>Website Owners:</strong> Website owners can enhance their website's SEO performance by optimizing their product pages for search engines.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ProductPageTitleMetaDescriptionsPage;
