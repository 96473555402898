/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import ElaborateFooter from '../common/ElaborateFooter';
import Header from '../common/Header';
import I1 from './assets/1.png';
import I2 from './assets/2.png';
import I3 from './assets/3.png';
import I4 from './assets/4.png';
import I5 from './assets/5.png';
import I6 from './assets/6.png';
import I7 from './assets/7.png';

const navigation = [
  { name: 'Introduction', href: '#introduction', current: true },
  { name: 'AI Writer', href: '#ai-writer',  current: false },
  { name: 'AI Image Generator', href: '#ai-image-generator', current: false },
  { name: 'AI Voiceover', href: '#ai-voiceover', current: false },
  { name: 'Workspaces & Sharing', href: '#workspace-sharing', current: false },
  { name: 'Library', href: '#library',  current: false },
  { name: 'Templates', href: '#templates',  current: false },
  { name: 'Experience the Future of Creativity with Graphia', href: '#experience-the-future-of-creativity-with-graphia', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {

  return (
<>
	<Header/>
    <>
     <div className="lg:flex  lg:cols-2 lg:gap-2">
        <div className=" lg:w-80">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul  className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:w-full">

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
		<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
		Unveiling Graphia's Latest Features: A Game-Changer for Creatives
		</h1>
		<img src={I1}  className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
		<h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="introduction">Introduction</h2>
		<div className="gap-5 grid grid-rows-1">
		<p>Graphia is more than just a platform; it's your ultimate creative companion, meticulously crafted to fuel your imagination and facilitate your journey from inspiration to realization. Our mission is to empower you with the tools and resources necessary to transform your ideas into tangible masterpieces. With our latest innovations, we're not just pushing the boundaries of creativity; we're redefining them. We understand that creativity knows no bounds, which is why we're committed to revolutionizing the way you create, collaborate, and communicate. Whether you're a seasoned professional or an aspiring artist, Graphia is here to accompany you every step of the way, providing unparalleled support and guidance as you unleash your creative potential upon the world.</p>
		</div>
		<h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="ai-writer">AI Writer</h2>
		<div className="gap-5 grid">
			<p>Whether you're a seasoned writer or new to the field, the AI Writer enables you to produce engaging content across various formats with ease. With Graphia's AI Writer feature, your writing reaches new levels of sophistication. This cutting-edge technology ensures advanced fluency, accuracy, and creativity, making crafting compelling content effortless.</p>
			<ul className="list-disc gap-5 ml-10">
  				<li><span className="font-semibold">Enhanced Writing Capabilities</span>: Leverage the power of GPT3.5,3.5 Turbo, 4, 4.5 Turbo & Anthropic's Claude 3 (Opus, Haiku and Sonnet) APIs to craft compelling content effortlessly.</li>
				<li><span className="font-semibold">Advanced Fluency and Accuracy</span>: Benefit from enhanced fluency, accuracy, and creativity, enabling you to produce engaging content with ease.</li>
				<li><span className="font-semibold">Versatile Applications</span>: Write blog posts, articles, marketing copy, and more with confidence, regardless of your writing experience or expertise.</li>
  
			</ul>
			<img src={I2}  className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
		</div>
		 <h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="ai-image-generator">AI Image Generator</h2>
                <div className="gap-5 grid">
			<p>The AI Image Generator feature of Graphia revolutionizes visual creation, offering a seamless path to stunning imagery. With its diverse range of effects and filters, you can effortlessly transform your ideas into captivating visuals. Powered by advanced AI technologies such as DALLE2, DALLE3, Stable Diffusion, and Clipdrop, this tool unleashes your creativity like never before. Whether you're designing a website, crafting social media graphics, or illustrating a presentation, customization is effortless, allowing you to bring your vision to life with unparalleled precision and flair.</p>
                        <ul className="list-disc gap-5 ml-10">
                                <li><span className="font-semibold">Powered by Advanced AI</span>: Utilize state-of-the-art technologies like DALLE2, DALLE3, Stable Diffusion, and Clipdrop to unleash your creativity.</li>
                                <li><span className="font-semibold">Effortless Customization</span>: Customize your images with ease, whether you're designing a website, crafting social media graphics, or illustrating a presentation.</li>
                                <li><span className="font-semibold">Versatile Applications</span>: Write blog posts, articles, marketing copy, and more with confidence, regardless of your writing experience or expertise.</li>

                        </ul>
                        <img src={I3}  className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
                </div>
		 <h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="ai-voiceover">AI Voiceover</h2>
                <div className="gap-5 grid">
			<p>With Graphia's AI Voiceover feature, your projects come to life with natural-sounding narration that captivates your audience. Benefit from high-quality audio seamlessly integrated into your projects, thanks to advanced AI models from ElevenLabs and OpenAI. Instantly generate voiceovers in seconds, saving valuable time and effort in the production process. Elevate the professionalism of your projects, conveying your message with clarity and impact, and captivating your audience with every word.</p>
                        <ul className="list-disc gap-5 ml-10">
				<li><span className="font-semibold">High-Quality Audio</span>: Deliver high-quality audio that seamlessly integrates with your projects, thanks to advanced AI models developed by ElevenLabs and OpenAI.</li>
				<li><span className="font-semibold">Instant Generation</span>: Generate natural-sounding voiceovers in seconds, saving you time and effort in the production process.</li>
				<li><span className="font-semibold">Enhanced Professionalism</span>: Convey your message with clarity and professionalism, enhancing the impact of your projects and captivating your audience.</li>

                        </ul>
                        <img src={I4} className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
                </div>
		 <h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="workspace-sharing">Workspace & Sharing</h2>
                <div className="gap-5 grid">
			<p>The Workspace & Sharing feature of Graphia streamlines teamwork with customizable workspaces, accommodating any project size. Easily add unlimited members to foster collaboration, while sharing files within workspaces enhances communication and productivity. This comprehensive tool ensures seamless coordination, whether on small team projects or large-scale endeavors.</p>
                        <ul className="list-disc gap-5 ml-10">
				<li><span className="font-semibold">Create Workspaces</span>: Designate specific areas for your projects by creating customizable workspaces. Generate as many as you'd like to categorize and manage your tasks efficiently.</li>
				<li><span className="font-semibold">Add Members</span>: Foster teamwork and inclusivity by inviting as many collaborators as necessary to each workspace. Whether it's colleagues, clients, or partners, everyone can contribute effortlessly.</li>
				<li><span className="font-semibold">Share Files</span>: Facilitate communication and productivity by sharing files within workspaces. Choose to share files with workspace members for collaborative editing or keep them private for individual use. This flexibility ensures that your data remains secure while enabling efficient teamwork.</li>
                        </ul>
                        <img src={I6} className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
                </div>
		 <h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="library">Library</h2>
                <div className="gap-5 grid">
			<p>Experience the convenience of a centralized repository for all your generated files, ensuring easy access and organization for your creative assets. With the Library feature, you can store and manage speeches, images, documents, and more, all in one place.</p>
                        <ul className="list-disc gap-5 ml-10">
				<li><span className="font-semibold"> Speeches</span>: Keep track of all your recorded speeches and presentations, making it easy to revisit and refine your delivery whenever necessary</li>
				<li><span className="font-semibold"> Images</span>: Store and organize your generated images, ensuring they're readily available for use in various projects, from social media posts to presentations.</li>
				<li><span className="font-semibold"> Documents</span>: Access a comprehensive archive of all your written content, including articles, blog posts, and marketing materials, enabling seamless collaboration and editing across your team.</li>

                        </ul>
                        <img src={I7} className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
                </div>
		 <h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="templates">Templates</h2>
                <div className="gap-5 grid">
			<p>Unlock your creativity with our curated selection of templates, designed to kickstart your projects and inspire your imagination. Whether you're crafting compelling content with AI Writer or adding a professional touch with Voices, our templates provide a solid foundation for your creative endeavors.</p>
                        <ul className="list-disc gap-5 ml-10">
				<li><span className="font-semibold">AI Writer Templates</span>: Jumpstart your writing process with a variety of pre-designed templates for blog posts, articles, marketing copy, and more. From brainstorming outlines to structuring your content, these templates make crafting engaging narratives a breeze.</li>
				<li><span className="font-semibold"> Voices Templates</span>: Elevate your projects with natural-sounding voiceovers using our Voices templates. Whether you're creating podcasts, video presentations, or audio ads, these templates offer customizable scripts and narration styles to suit your needs.</li>

                        </ul>
                        <img src={I5} className="mx-auto sm:ml-10 md:mx-0 md:float-left lg:float-none lg:mx-auto mt-10 mb-10 sm:max-h-500 md:max-h-full" alt="Feature Launch"></img>
                </div>
	<h2 className="mt-5 mb-5 text-2xl font-semibold leading-7 text-indigo-600" id="experience-the-future-of-creativity-with-graphia">Experience the Future of Creativity with Graphia</h2>
                <div className="gap-5 grid grid-rows-3">
			<p>At Graphia, we're committed to pushing the boundaries of creativity and innovation. With our latest features, you can unleash your imagination, streamline your workflow, and bring your ideas to life like never before.</p>

			<p>Whether you're a seasoned professional or just starting your creative journey, Graphia provides you with the tools, resources, and support you need to succeed. Experience the future of creativity with Graphia and unlock your full potential today.</p>

			<p>Ready to take your creativity to the next level? Sign up for Graphia today and join the community of creators who are shaping the future of digital content. Together, we can redefine what's possible and inspire the world with our creativity.</p>		

		</div>
	   </div>
          </main>
        </div>
    </div>
    </>
	<ElaborateFooter/>
</>
  )
}

