import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import HeadlinesInput from './assets/HeadlinesInput.png';
//import HeadlinesOutput from './assets/HeadlinesOutput.png';

const HeadlinesPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Headlines</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Craft compelling and attention-grabbing headlines to enhance the impact of your social media posts. This tool provides you with creative headline ideas that resonate with your audience and drive engagement.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Attention-Grabbing:</strong> Create headlines that capture the audience's interest and encourage interaction.</li>
            <li><strong>Variety:</strong> Generate a wide range of headline options to suit different content types and platforms.</li>
            <li><strong>Optimization:</strong> Optimize headlines for social media platforms to maximize reach and engagement.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-400 mb-2">1. Input Content Details</h3>
            <h3 className="text-xl text-indigo-400 mb-2">2. Generate Headlines</h3>
            <h3 className="text-xl text-indigo-400 mb-2">3. Choose the Best Headline</h3>
            <p>Select the most compelling headline that aligns with your content and audience.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Drive more likes, shares, and comments with captivating headlines.</li>
            <li><strong>Brand Visibility:</strong> Enhance brand visibility and recognition through engaging content.</li>
            <li><strong>Content Effectiveness:</strong> Improve the effectiveness of your social media marketing efforts with compelling headlines.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Creators:</strong> Enhance the reach and impact of your blog posts, articles, and videos.</li>
            <li><strong>Marketing Professionals:</strong> Improve the performance of social media campaigns and advertisements.</li>
            <li><strong>Business Owners:</strong> Increase engagement and conversion rates on social media platforms.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default HeadlinesPage;
