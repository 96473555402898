import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ProductBrochureInput from './assets/ProductBrochureInput.png';
import ProductBrochureOutput from './assets/ProductBrochureOutput.png';
import ProductBrochureOutput2 from './assets/ProductBrochureOutput2.png';
import ProductBrochureOutput3 from './assets/ProductBrochureOutput3.png';


const ProductBrochurePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Product Brochure</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Create compelling brochures to showcase your products or services with the Product Brochure tool. Whether you're introducing new offerings, promoting existing products, or highlighting key features, a well-designed brochure can captivate your audience and drive engagement.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customizable Templates:</strong> Choose from a variety of brochure templates and customize them to fit your brand.</li>
            <li><strong>Visual Elements:</strong> Incorporate images, graphics, and design elements to enhance the brochure's appeal.</li>
            <li><strong>Content Sections:</strong> Organize content into sections to effectively communicate product information and benefits.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Give Product Information</h3>
            <p>Give Product Information that describes your product the best</p>
            <img src={ProductBrochureInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-500 mb-2">2. Select Tone & Language</h3>
            <p>Language & Tone in which you want your brochure</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Generate Brochure</h3>
            <p>Generate & Download the completed brochure in PDF format for printing or digital distribution.</p>
            <img src={ProductBrochureOutput} alt="Input Topic" className="mb-4"/>
            <img src={ProductBrochureOutput2} alt="Input Topic" className="mb-4"/>
            <img src={ProductBrochureOutput3} alt="Input Topic" className="mb-4"/>

          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Professional Presentation:</strong> Impress clients and prospects with professionally designed brochures.</li>
            <li><strong>Informational Tool:</strong> Provide comprehensive product information in an easy-to-read format.</li>
            <li><strong>Marketing Collateral:</strong> Use brochures as marketing collateral at trade shows, events, and meetings.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Launch:</strong> Create brochures to introduce new products to potential customers.</li>
            <li><strong>Sales Presentations:</strong> Use brochures as sales aids to showcase product features and benefits.</li>
            <li><strong>Informational Handouts:</strong> Distribute brochures at industry events or in-store to educate customers about your offerings.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ProductBrochurePage;

