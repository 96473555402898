import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import GMBOfferPostInput from './assets/GMBOfferPostInput.png';
//import GMBOfferPostOutput from './assets/GMBOfferPostOutput.png';

const GMBOfferPostPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Google My Business - Offer Post</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate offer details for your Google My Business offer posts.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Attractive Offers:</strong> Create compelling offer posts to attract more customers.</li>
            <li><strong>SEO Optimization:</strong> Improve your offers' visibility on Google search results.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Offer Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Offer Post</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Use Offer Post</h3>
            <p>Copy the generated offer post and publish it on your Google My Business profile.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Sales:</strong> Compelling offers attract more customers.</li>
            <li><strong>Improved Visibility:</strong> SEO-friendly posts improve your business's search ranking.</li>
            <li><strong>Professional Presentation:</strong> Well-structured posts enhance your business's image.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Retail Stores:</strong> Promote special offers and discounts to drive sales.</li>
            <li><strong>Restaurants:</strong> Announce special menu items or limited-time offers.</li>
            <li><strong>Service Providers:</strong> Offer discounts on services to attract new clients.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default GMBOfferPostPage;
