import React from 'react';

const features = [
  {
    name: 'Marketing',
    description:
	"Whether it's creating captivating social media posts, dynamic email campaigns, or personalized landing pages, our platform empowers marketers to deliver compelling content across multiple channels with ease.",
    href: '/usecases/graphia-marketing',
  },
  {
    name: 'Sales',
    description:
      'Empower your sales team with a content generation platform that revolutionizes outreach, personalization, and engagement. Drive revenue growth and foster lasting customer relationships with agile, personalized content solutions.',
    href: '/usecases/graphia-sales',
  },
  {
    name: 'Ecommerce',
    description:
	"From creating eye-catching product descriptions and engaging blog posts to crafting personalized email newsletters and dynamic website content, our platform empowers e-commerce businesses to captivate customers and drive sales.",
    href: '/usecases/graphia-ecommerce',
  },
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Usecases</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
		Many Needs, One Platform
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
		 Leverage a single platform to address varied usecases. Get single platform for all teams enhancing efficiency, integration, and adaptability.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base text-xl font-semibold leading-7 text-gray-900">
                  {feature.name}
                </dt>
		<div class="my-3 w-8 border-t border-purple-400"></div>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

