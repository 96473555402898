import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import LandingPageHeadlineWriterInput from './assets/LandingPageHeadlineWriterInput.png';
//import LandingPageHeadlineWriterOutput from './assets/LandingPageHeadlineWriterOutput.png';

const LandingPageHeadlineWriterPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
      <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Landing Page Headline Writer</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Landing Page Headline Writer tool generates unique and catchy headlines that are perfect for your product or service landing pages. With this tool, you can create attention-grabbing headlines that capture the interest of visitors and compel them to take action.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Creative Headlines:</strong> Generate creative and compelling headlines tailored to your product or service.</li>
            <li><strong>Keyword Optimization:</strong> Incorporate relevant keywords to improve search engine ranking and visibility.</li>
            <li><strong>Preview:</strong> View multiple headline options and select the one that best suits your landing page.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Product or Service Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Headline Options</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Select and Implement</h3>
            <p>Choose the most suitable headline option and implement it on your landing page for maximum impact.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Conversions:</strong> Attention-grabbing headlines compel visitors to take action, resulting in higher conversion rates.</li>
            <li><strong>Improved Engagement:</strong> Creative headlines captivate visitors and encourage them to explore further.</li>
            <li><strong>Enhanced Brand Visibility:</strong> Optimized headlines improve search engine ranking and increase brand visibility.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LandingPageHeadlineWriterPage;

