import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import TextExtenderInput from './assets/TextExtenderInput.png';
import TextExtenderOutput from './assets/TextExtenderOutput.png';

const TextExtenderPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Text Extender</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Extend short sentences into more descriptive and interesting ones. This tool helps you expand your content to provide more detail and depth, making it more engaging for readers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Enhanced Detail:</strong> Expand sentences to add more description and depth.</li>
            <li><strong>Improved Engagement:</strong> Make your content more interesting and engaging.</li>
            <li><strong>Customizable:</strong> Tailor the extended sentences to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Sentences</h3>
            <img src={TextExtenderInput} alt="Input Sentences" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Extended Text</h3>
            <img src={TextExtenderOutput} alt="Generated Extended Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the extended text to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Detail:</strong> Provide more in-depth information in your content.</li>
            <li><strong>Improved Readability:</strong> Make your content more engaging and interesting.</li>
            <li><strong>Time-Saving:</strong> Quickly extend your content without writing from scratch.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Add more detail to your blog posts.</li>
            <li><strong>Content Creators:</strong> Expand your content to make it more engaging.</li>
            <li><strong>Marketers:</strong> Create more detailed and interesting marketing content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default TextExtenderPage;
