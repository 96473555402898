import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import CaptionGeneratorInput from './assets/CaptionGeneratorInput.png';
//import CaptionGeneratorOutput from './assets/CaptionGeneratorOutput.png';

const CaptionGeneratorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Caption Generator</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Create captivating and interactive social media captions that resonate with your audience and encourage engagement. This tool helps you generate engaging captions tailored for various social media platforms.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Captivating Content:</strong> Generate captions that grab attention and encourage interaction.</li>
            <li><strong>Customization:</strong> Tailor captions to fit your brand voice and style.</li>
            <li><strong>Engagement:</strong> Encourage likes, comments, and shares with compelling captions.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-400 mb-2">1. Input Your Content</h3>
            <h3 className="text-xl text-indigo-400 mb-2">2. Generate Caption</h3>
            <h3 className="text-xl text-indigo-400 mb-2">3. Copy and Use</h3>
            <p>Copy the generated caption and use it in your social media posts.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Generate captions that prompt audience interaction.</li>
            <li><strong>Brand Consistency:</strong> Maintain a consistent brand voice across social media platforms.</li>
            <li><strong>Time-Saving:</strong> Quickly generate high-quality captions without spending hours brainstorming.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Businesses:</strong> Create captions for product launches, promotions, and announcements.</li>
            <li><strong>Influencers:</strong> Craft engaging captions to accompany your posts and stories.</li>
            <li><strong>Organizations:</strong> Generate captions for event promotions and community outreach.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default CaptionGeneratorPage;
