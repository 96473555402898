// ProductDescriptionsPage.js
import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const ProductDescriptionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Product Descriptions</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Create compelling product descriptions that drive interest with the Product Descriptions tool. Engage potential customers and highlight the unique features and benefits of your products to increase sales and conversions.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Showcase:</strong> Showcase your products effectively with captivating descriptions.</li>
            <li><strong>SEO Optimization:</strong> Optimize descriptions with relevant keywords to improve search engine rankings.</li>
            <li><strong>Engagement:</strong> Capture audience attention and drive interest in your products.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Input Product Details</h3>
            <p>Provide details about your products, including features, benefits, and specifications.</p>
            <h3 className="text-xl text-indigo-500 mb-2">2. Generate Descriptions</h3>
            <p>Click the generate button to create compelling descriptions tailored to your products.</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Review and Customize</h3>
            <p>Review the generated descriptions and customize them to fit your brand voice and style.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Sales Boost:</strong> Increase product sales with persuasive and engaging descriptions.</li>
            <li><strong>Brand Image:</strong> Enhance your brand image with professional and enticing product descriptions.</li>
            <li><strong>SEO Enhancement:</strong> Improve search engine visibility by optimizing descriptions with relevant keywords.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>E-commerce:</strong> Create compelling product descriptions for online store listings.</li>
            <li><strong>Marketing Campaigns:</strong> Use engaging descriptions in marketing materials to attract customers.</li>
            <li><strong>Product Launches:</strong> Generate buzz and interest around new product releases with captivating descriptions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ProductDescriptionsPage;
