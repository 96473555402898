import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const LinkedInTextAdsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <a href="/templates" className="text-indigo-600 mb-4 inline-block">&larr; Back to templates</a>
        <h1 className="text-3xl text-indigo-800">LinkedIn Text Ads</h1>
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Use Graphia with LinkedIn Text Ads to reach the right professional audience. This tool helps you create compelling ad copy that resonates with professionals and drives engagement on LinkedIn.</p>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Professional Ad Copy:</strong> Craft ad copy that appeals to LinkedIn's professional audience.</li>
            <li><strong>Targeted Messaging:</strong> Create messages that speak directly to your target demographic.</li>
            <li><strong>Performance Tips:</strong> Optimize your ads with tips for better performance.</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Define Your Audience</h3>
            <p>Select the professional audience you want to target.</p>
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Ad Copy</h3>
            <p>Generate multiple versions of ad copy to choose from.</p>
            <h3 className="text-xl text-indigo-700 mb-2">3. Optimize and Publish</h3>
            <p>Optimize your ads with provided tips and publish them on LinkedIn.</p>
          </div>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Professional Engagement:</strong> Engage with a professional audience effectively.</li>
            <li><strong>Increased Click-Through Rate:</strong> Enhance your ads' CTR with targeted messaging.</li>
            <li><strong>Higher Conversion Rate:</strong> Drive more conversions with optimized ads.</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Recruitment:</strong> Attract top talent by advertising job openings.</li>
            <li><strong>B2B Marketing:</strong> Promote your B2B products and services.</li>
            <li><strong>Professional Services:</strong> Advertise your professional services to a targeted audience.</li>
          </ul>
        </section>
        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LinkedInTextAdsPage;
