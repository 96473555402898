export default function Example() {
  return (
    <div className="bg-white">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Empower Your Creativity
	    <br />
	    Unlock Infinite Image Possibilities Now!
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
		Dive into a world of limitless creativity with our image generation tools. Transform ideas into stunning visuals effortlessly and unleash your imagination like never before.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://app.graphia.ai"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
	      target="_blank"
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

