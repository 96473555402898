import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import ControversialOpinionInput from './assets/ControversialOpinionInput.png';
//import ControversialOpinionOutput from './assets/ControversialOpinionOutput.png';

const ControversialOpinionTwitterThreadPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-black">Controversial Opinion Twitter Thread</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-black mb-4">Overview</h2>
          <p>Express a thought-provoking opinion on a particular topic and craft it into a Twitter thread to spark meaningful discussions. This tool helps you create engaging and controversial Twitter threads that generate buzz.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Content:</strong> Create threads that captivate and provoke thought.</li>
            <li><strong>Controversial Topics:</strong> Generate content on hot-button issues that spark discussions.</li>
            <li><strong>Discussion Starter:</strong> Encourage meaningful conversations and debates.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-black mb-2">1. Input Your Topic</h3>
            <h3 className="text-xl text-black mb-2">2. Generate Thread</h3>
            <h3 className="text-xl text-black mb-2">3. Post and Engage</h3>
            <p>Post the generated thread on Twitter and engage with your audience by responding to comments and messages.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Create threads that attract likes, retweets, and comments.</li>
            <li><strong>Thought Leadership:</strong> Position yourself as a thought leader on controversial topics.</li>
            <li><strong>Time-Saving:</strong> Quickly generate high-quality threads without spending hours crafting them.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-black mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Thought Leaders:</strong> Share controversial opinions to build your personal brand.</li>
            <li><strong>Businesses:</strong> Generate buzz around your brand by participating in trending topics.</li>
            <li><strong>Influencers:</strong> Engage with your followers by discussing hot-button issues.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-black mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-black" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ControversialOpinionTwitterThreadPage;
