import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import SongLyricsGeneratorInput from './assets/SongLyricsGeneratorInput.png';
import SongLyricsGeneratorOutput from './assets/SongLyricsGeneratorOutput.png';


const SongLyricsGeneratorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Song Lyrics Generator</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate unique song lyrics that will be perfect for your next hit song. Whether you're a musician, songwriter, or just looking for inspiration, the Song Lyrics Generator can help you create catchy and meaningful lyrics in no time.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customizable Themes:</strong> Choose from different themes and genres to tailor the lyrics to your style.</li>
            <li><strong>Lyric Suggestions:</strong> Get inspired with AI-generated lyric suggestions based on your input.</li>
            <li><strong>Export Options:</strong> Export your lyrics in various formats for use in your music production software or songwriting tools.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Choose Theme</h3>
            <p>Select a theme or genre for your song lyrics, such as pop, rock, country, or hip-hop.</p>
            <img src={SongLyricsGeneratorInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Input Keywords</h3>
            <p>Provide keywords or phrases related to the topic or mood you want to convey in your lyrics.</p>
            <h3 className="text-xl text-indigo-800 mb-2">3. Generate Lyrics</h3>
            <p>Click the generate button to generate unique song lyrics based on your input.</p>
            <img src={SongLyricsGeneratorOutput} alt="Input Topic" className="mb-4"/>

          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Inspiration:</strong> Overcome writer's block and find inspiration for your next song.</li>
            <li><strong>Creativity:</strong> Explore new themes and ideas to expand your lyrical repertoire.</li>
            <li><strong>Efficiency:</strong> Save time and effort by generating lyrics quickly and easily.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Songwriting:</strong> Create original lyrics for your own songs or collaborations.</li>
            <li><strong>Content Creation:</strong> Generate lyrics for videos, commercials, or other multimedia projects.</li>
            <li><strong>Education:</strong> Teach songwriting concepts and techniques using AI-generated examples.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default SongLyricsGeneratorPage;
