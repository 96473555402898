import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import LinkedInProspectResearchInput from './assets/LinkedInProspectResearchInput.png';
//import LinkedInProspectResearchOutput from './assets/LinkedInProspectResearchOutput.png';

const LinkedInProspectResearchPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Research Prospect From LinkedIn</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Gather insightful information about potential leads from their LinkedIn profiles to tailor your sales approach effectively.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>In-depth Research:</strong> Obtain detailed information from LinkedIn profiles.</li>
            <li><strong>Tailored Sales Approach:</strong> Customize your sales strategies based on prospect insights.</li>
            <li><strong>Increased Engagement:</strong> Enhance your outreach with personalized data.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input LinkedIn Profile URL</h3>
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Prospect Information</h3>
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Use</h3>
            <p>Review the gathered information and use it to enhance your sales approach.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Better Targeting:</strong> Focus on the right leads with accurate information.</li>
            <li><strong>Improved Sales Tactics:</strong> Utilize insights to make your sales pitch more effective.</li>
            <li><strong>Enhanced Relationships:</strong> Build stronger connections with prospects.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LinkedInProspectResearchPage;
