import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import PersonalizedColdEmailsInput from './assets/PersonalizedColdEmailsInput.png';
//import PersonalizedColdEmailsOutput from './assets/PersonalizedColdEmailsOutput.png';

const PersonalizedColdEmailsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Personalized Cold Emails</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Personalized Cold Emails is a tool designed to help businesses craft cold emails that stand out and generate responses. By leveraging recipient data and personalizing each email, this tool increases the likelihood of engagement and conversion, making it an essential asset for outbound sales and marketing campaigns.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Personalization:</strong> Dynamically insert recipient names, company details, and other personalized elements to create tailored emails.</li>
            <li><strong>A/B Testing:</strong> Experiment with different subject lines, content variations, and call-to-action strategies to optimize email performance.</li>
            <li><strong>Automated Follow-Ups:</strong> Schedule automated follow-up emails to nurture leads and increase response rates over time.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Define Recipient Criteria</h3>
<p>Identify the target audience based on criteria such as industry, job title, or company size.</p>
<h3 className="text-xl text-indigo-800 mb-2">2. Personalize Email Content</h3>
<p>Customize the email content to address the recipient's specific needs, challenges, or interests.</p>
<h3 className="text-xl text-indigo-800 mb-2">3. Send Emails</h3>
<p>Send personalized cold emails to the target audience and track responses and engagement metrics.</p>
</div>
</section>
<section className="mb-8">
      <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
      <ul className="list-disc list-inside">
        <li><strong>Higher Response Rates:</strong> Personalized emails resonate better with recipients, leading to increased response rates and engagement.</li>
        <li><strong>Improved Conversion Rates:</strong> Tailoring emails to individual preferences and pain points boosts conversion rates and sales opportunities.</li>
        <li><strong>Time Efficiency:</strong> Automating the personalization process streamlines email outreach efforts, allowing sales teams to focus on high-value activities.</li>
        <li><strong>Scalability:</strong> Scale personalized outreach campaigns to reach a larger audience without sacrificing quality or relevance.</li>
      </ul>
    </section>

    <section className="mb-8">
      <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
      <ul className="list-disc list-inside">
        <li><strong>Sales Prospecting:</strong> Identify and reach out to potential leads with personalized messages that address their specific pain points.</li>
        <li><strong>Outbound Marketing:</strong> Launch targeted email campaigns to promote products, services, or events to segmented audiences.</li>
        <li><strong>Relationship Building:</strong> Nurture relationships with prospects and customers through personalized communication that demonstrates understanding and empathy.</li>
      </ul>
    </section>

    <section>
      <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
      <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
    </section>
  </div>
  <ElaborateFooter />
</>
);
};

export default PersonalizedColdEmailsPage;
