// FacebookHeadlinesPage.js
import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const FacebookHeadlinesPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        {/* Add back button */}
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Facebook Headlines</h1>
        {/* Overview section */}
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate attention-grabbing headlines for your Facebook ads with the Facebook Headlines template. Craft compelling and concise headlines that capture audience attention and encourage clicks.</p>
        </section>
        {/* Key features section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Attention-Grabbing:</strong> Create headlines that immediately capture audience attention.</li>
            <li><strong>Concise Messaging:</strong> Deliver your message effectively in a limited space.</li>
            <li><strong>Encourage Clicks:</strong> Persuade users to click through to learn more about your offer.</li>
          </ul>
        </section>
        {/* How It Works section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Identify Key Points</h3>
            <p>Identify the most compelling aspects of your offer or promotion.</p>
            <h3 className="text-xl text-indigo-500 mb-2">2. Craft Headlines</h3>
            <p>Brainstorm various headline options that highlight these key points.</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Test and Refine</h3>
            <p>Test different headlines to determine which ones resonate best with your audience.</p>
          </div>
        </section>
        {/* Benefits section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Click-Through Rate:</strong> Increase clicks by using headlines that grab attention.</li>
            <li><strong>Enhanced Engagement:</strong> Encourage audience interaction with compelling messaging.</li>
            <li><strong>Optimized Ad Performance:</strong> Improve overall ad performance with effective headlines.</li>
          </ul>
        </section>
        {/* Use Cases section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Launch:</strong> Create excitement and anticipation for new product releases.</li>
            <li><strong>Promotional Offers:</strong> Highlight special offers or discounts to drive sales.</li>
            <li><strong>Event Promotion:</strong> Generate interest and attendance for upcoming events or webinars.</li>
          </ul>
        </section>
        {/* Use This Template section */}
        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default FacebookHeadlinesPage;
