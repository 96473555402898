import {
  UserIcon,
  PencilSquareIcon,
  SpeakerWaveIcon,
  ShoppingCartIcon,
  EnvelopeIcon,
  GlobeAltIcon,

} from '@heroicons/react/24/outline'
export const features = [

  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Article Generator",
  "description":"Generate well-researched and engaging articles effortlessly.",
  "color":"#4287f5",
  "icon":PencilSquareIcon,
  "href": "/templates/article-generator"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Article & Blog Ideas",
  "description":"Suggestions for Articles and Blog Topics to Drive Increased Traffic,Generate Leads,and Boost Sales for Your Business.",
  "color":"#F5D98B",
  "icon":PencilSquareIcon,
  "href": "/templates/article-blog-ideas"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Article Outlines",
  "description":"Detailed article outlines that assisting you in producing consistently improved content.",
  "color":"#FF5733",
  "icon":PencilSquareIcon,
  "href": "/templates/article-outlines"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Article Intros",
  "description":"Attractive article and blog introductions that engage your audience's interest.",
  "color":"#4F75E6",
  "icon" : PencilSquareIcon,
  "href": "/templates/article-intros"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Blog Outline",
  "description":"Develop structured and engaging blog outlines.",
  "color":"#e57373",
  "icon":PencilSquareIcon,
  "href": "/templates/blog-outline"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Blog Titles",
  "description":"Blog titles to boost traffic and engagement.",
  "color":"#FFA124",
  "icon" : PencilSquareIcon,
  "href": "/templates/blog-titles"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Blog Post (Long form)",
  "description":"Craft engaging and informative blog posts in 7000-8000 words.",
  "color":"#9c27b0",
  "icon":PencilSquareIcon,
  "href": "/templates/blog-post-long-form"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Blog Post (Short form)",
  "description":"Craft engaging and informative blog posts.",
  "color":"#9c27b0",
  "icon" : PencilSquareIcon,
  "href": "/templates/blog-post-short-form"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Blog Post Conclusion Paragraph",
  "description":"Wrap up your blog posts with a engaging closing paragraph.",
  "color":"#6B2ED5",
  "icon" : PencilSquareIcon,
  "href": "/templates/blog-post-conclusion-paragraph"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"One-Shot Blog Post",
  "description":"Generate a full blog post with intro,body,and conclusion.",
  "color":"#FF6C80",
  "icon" : PencilSquareIcon,
  "href": "/templates/one-shot-blog-post"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Paragraph Generator",
  "description":"Generate paragraphs that will captivate your readers.",
  "color":"#2E8E9C",
  "icon" : PencilSquareIcon,
  "href": "/templates/paragraph-generator"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Conclusion Writer",
  "description":"Powerful conclusion copy that will make a reader take action.",
  "color":"#FFB42E",
  "icon" : PencilSquareIcon,
  "href": "/templates/conclusion-writer"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Content Improver",
  "description":"Take a piece of content and rewrite it to make it more interesting,creative,and engaging.",
  "color":"#5D38A7",
  "icon" : PencilSquareIcon,
  "href": "/templates/content-improver"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Text Extender",
  "description":"Extend short sentences into more descriptive and interesting ones.",
  "color":"#FF8A5E",
  "icon" : PencilSquareIcon,
  "href": "/templates/text-extender"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Content Shortener",
  "description":"Short your content in a different voice and style to appeal to different readers.",
  "color":"#4C94F0",
  "icon" : PencilSquareIcon,
  "href": "/templates/content-shortener"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Text Summarizer",
  "description":"Get the key points from a piece of text.",
  "color":"#FF533A",
  "icon" : PencilSquareIcon,
  "href": "/templates/text-summarizer"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Story Writer",
  "description":"Engaging and persuasive stories that will attract your reader's attention and interest.",
  "color":"#3177A0",
  "icon" : PencilSquareIcon,
  "href": "/templates/story-writer"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Personal Bio Writer",
  "description":"Perfect personal bio copy that shows your expertise and brings you more customers.",
  "color":"#FFA654",
  "icon":PencilSquareIcon,
  "href": "/templates/personal-bio-writer"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Translator",
  "description":"Translate your content into any language you want.",
  "color":"#795BF2",
  "icon" : PencilSquareIcon,
  "href": "/templates/translator"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Bullet Point Answers",
  "description":"Precise and informative answers that provide quick and valuable points to your customers' questions.",
  "color":"#FF8765",
  "icon" : PencilSquareIcon,
  "href": "/templates/bullet-point-answers"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Passive to Active Voice",
  "description":"Easy and quick solution to converting your passive voice sentences into active voice sentences.",
  "color":"#409DD8",
  "icon" : PencilSquareIcon,
  "href": "/templates/passive-to-active-voice"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Definition",
  "description":"A definition for a word,phrase,or acronym that's used often by your target buyers.",
  "color":"#FF6E4D",
  "icon" : PencilSquareIcon,
  "href": "/templates/definition"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Answers",
  "description":"Quality answers to any questions or concerns that your audience might have.",
  "color":"#478CBF",
  "icon" : PencilSquareIcon,
  "href": "/templates/answers"},
  
  {"type":"completion",
  "_status":"1",
  "category":"Writing Tools",
  "title":"Pros and Cons",
  "description":"List of the main benefits versus the most common problems and concerns.",
"color":"#FF4E27",
"icon" : PencilSquareIcon,
"href": "/templates/pros-and-cons"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Questions",
"description":"A tool to create engaging questions and polls that increase audience participation and engagement.",
"color":"#3564B8",
"icon" : PencilSquareIcon,
"href": "/templates/questions"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Rewrite with Keywords",
"description":"Rewrite your existing content to include more keywords and boost your search engine rankings.",
"color":"#FFAF51",
"icon" : PencilSquareIcon,
"href": "/templates/rewrite-with-keywords"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Explain to Child",
"description":"Rephrase text to make it easier to read and understand.",
"color":"#AA54E6",
"icon" : PencilSquareIcon,
"href": "/templates/explain-to-child"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Listicle",
"description":"Generate a numbered list based on a topic. Perfect for filling in detail of a blog post.",
"color":"#FF5F46",
"icon" : PencilSquareIcon,
"href": "/templates/listicle"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Tone Changer",
"description":"Change the tone of your writing to match your audience and copy.",
"color":"#5890D0",
"icon" : PencilSquareIcon,
"href": "/templates/tone-changer"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Song Lyrics Generator",
"description":"Unique song lyrics that will be perfect for your next hit song.",
"color":"#FF9B56",
"icon" : PencilSquareIcon,
"href": "/templates/song-lyrics-generator"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Review Responder",
"description":"Automatically create personalized replies for reviews.",
"color":"#6E49F4",
"icon" : PencilSquareIcon,
"href": "/templates/review-responder"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Product Name Generator",
"description":"Catchy and meaningful names and slogans that fit your product or service.",
"color":"#FF7C62",
"icon" : PencilSquareIcon,
"href": "/templates/product-name-generator"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Analogy Maker",
"description":"Unique analogies that make your sales pitch more memorable and engaging.",
"color":"#478DBD",
"icon" : PencilSquareIcon,
"href": "/templates/analogy-maker"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Keywords Extractor",
"description":"Keywords extracted from content that you can use for your optimization,SEO,or content creation purposes.",
"color":"#FF582E",
"icon" : PencilSquareIcon,
"href": "/templates/keywords-extractor"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Product Brochure",
"description":"Craft compelling brochures to showcase your product.",
"color":"#9e9e9e",
"icon" : PencilSquareIcon,
"href": "/templates/product-brochure"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Product Descriptions",
"description":"Create compelling product descriptions that drive interest.",
"color":"#673ab7",
"icon" : PencilSquareIcon,
"href": "/templates/product-descriptions"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Content Rewriter",
"description":"Enhance content quality through revision.",
"color":"#2196f3",
"icon" : PencilSquareIcon,
"href": "/templates/content-rewriter"},

{"type":"completion",
"_status":"1",
"category":"Writing Tools",
"title":"Short Summary",
"description":"Craft concise and impactful summaries.",
"color":"#607d8b",
"icon" : PencilSquareIcon,
"href": "/templates/short-summary"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"Facebook Ad",
"description":"Create compelling ad copy for effective Facebook campaigns.",
"color":"#2196f3",
"icon" : SpeakerWaveIcon,
"href": "/templates/facebook-ad"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"Facebook Ad (PAS)",
"description":"Create compelling Facebook ads using PAS framework.",
"color":"#f44336",
"icon" : SpeakerWaveIcon,
"href": "/templates/facebook-ad-pas"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"Facebook Headlines",
"description":"Generate attention-grabbing Facebook ad headlines.",
"color":"#ff5722",
"icon" : SpeakerWaveIcon,
"href": "/templates/facebook-headlines"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"Facebook Video Script",
"description":"Craft effective Facebook ad video scripts.",
"color":"#e91e63",
"icon" : SpeakerWaveIcon,
"href": "/templates/facebook-video-script"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"Google Ads",
"description":"Craft compelling Google ads for effective campaigns.",
"color":"#4caf50",
"icon" : SpeakerWaveIcon,
"href": "/templates/google-ads"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"LinkedIn Text Ads",
"description":"Use Graphia with LinkedIn Text Ads to reach the right professional audience",
"color":"#0e76a8",
"icon" : SpeakerWaveIcon,
"href": "/templates/linkedin-text-ads"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"LinkedIn Single Image Ads",
"description":"Use Graphia with LinkedIn Single Image Ads to reach the right professional audience",
"color":"#0e76a8",
"icon" : SpeakerWaveIcon,
"href": "/templates/linkedin-single-image-ads"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"LinkedIn Ads",
"description":"Eye-catching ads that will not only attract the right audience but also drive them to your lead magnet.",
"color":"#0e76a8",
"icon" : SpeakerWaveIcon,
"href": "/templates/linkedin-ads"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"LinkedIn Ad Headline",
"description":"Attention-grabbing,click-inducing,and high-converting ad headlines for Linkedin.",
"color":"#0e76a8",
"icon" : SpeakerWaveIcon,
"href": "/templates/linkedin-ad-headline"},

{"type":"completion",
"_status":"1",
"category":"Ads",
"title":"App and SMS Notifications",
"description":"Notification messages for your apps,websites,and mobile devices that keep users coming back for more.",
"color":"#1E6CB5",
"icon" : SpeakerWaveIcon,
"href": "/templates/app-sms-notifications"},

{"type":"completion",
"_status":"1",
"category":"Sales",
"title":"3 Step Outreach Sequence",
"description":"Create effective 3 step outreach sequences.",
"color":"#8bc34a",
"icon":ShoppingCartIcon,
"href": "/templates/outreach-sequence"},

{"type":"completion",
"_status":"1",
"category":"E-Commerce",
"title":"Analyze Industry Trends",
"description":"Analyze industry trends and present findings.",
"color":"#673ab7",
"icon" : ShoppingCartIcon,
"href": "/templates/industry-trends-analysis"},

{"type":"completion",
"_status":"1",
"category":"E-Commerce",
"title":"Brainstorm Pain Points",
"description":"Identify pain points and language for target personas.",
"color":"#ff5722",
"icon" : ShoppingCartIcon,
"href": "/templates/pain-points-brainstorming"},

{"type":"completion",
"_status":"1",
"category":"E-Commerce",
"title":"Competitive Analysis",
"description":"Conduct a comprehensive analysis of your competitors. Identify the competitive advantages and disadvantages of their product.",
"color":"#3498DB",
"icon" : ShoppingCartIcon,
"href": "/templates/competitive-analysis"},

{"type":"completion",
"_status":"1",
"category":"Sales",
"title":"Linkedin Boolean Search",
"description":"Conduct more targeted searches on LinkedIn Sales Navigator using boolean search techniques. Boolean searches allow you to combine keywords and operators to find specific profiles that match your criteria.",
"color":"#4287f5",
"icon":UserIcon,
"href": "/templates/linkedin-boolean-search"},

{"type":"completion",
"_status":"1",
"category":"Sales",
"title":"Personalized Cold Email From LinkedIn Profile",
"description":"Craft effective personalized cold emails using LinkedIn profile information to engage potential clients.",
"color":"#FF5733",
"icon" : GlobeAltIcon,
"href": "/templates/personalized-cold-email"},

{"type":"completion",
"_status":"1",
"category":"Sales",
"title":"Research Prospect From Linkedin",
"description":"Gather insightful information about potential leads from their LinkedIn profiles to tailor your sales approach effectively.",
"color":"#59A14F",
"icon" : GlobeAltIcon,
"href": "/templates/linkedin-prospect-research"},

{"type":"completion",
"_status":"1",
"category":"E-Commerce",
"title":"Amazon Product Features",
"description":"Advantages and features of your products that will make them irresistible to shoppers.",
"color":"#ff9900",
"icon" : ShoppingCartIcon,
"href": "/templates/amazon-product-features"},

{"type":"completion",
"_status":"1",
"category":"E-Commerce",
"title":"Amazon Product Titles",
"description":"Product titles that will make your product stand out in a sea of competition.",
"color":"#ff9900",
"icon" : ShoppingCartIcon,
"href": "/templates/amazon-product-titles"},

{"type":"completion",
"_status":"1",
"category":"E-Commerce",
"title":"Amazon Product Descriptions",
"description":"Descriptions for Amazon products that rank on the first page of the search results.",
"color":"#ff9900",
"icon" : ShoppingCartIcon,
"href": "/templates/amazon-product-descriptions"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Email Subject Generator",
"description":"Craft compelling email subject lines.",
"color":"#673ab7",
"icon" : EnvelopeIcon,
"href": "/templates/email-subject-generator"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Features-Advantages-Benefits",
"description":"Create persuasive email marketing campaigns.",
"color":"#e91e63",
"icon" : EnvelopeIcon,
"href": "/templates/features-advantages-benefits"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Newsletter From Recent News",
"description":"Craft engaging newsletters from recent news.",
"color":"#009688",
"icon" : EnvelopeIcon,
"href": "/templates/newsletter-recent-news"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Newsletter Inspiration",
"description":"Gather insights for impactful newsletters.",
"color":"#03a9f4",
"icon" : EnvelopeIcon,
"href": "/templates/newsletter-inspiration"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Pain-Agitate-Solution",
"description":"Create impactful email marketing campaigns using PAS framework.",
"color":"#ffeb3b",
"icon" : EnvelopeIcon,
"href": "/templates/pain-agitate-solution"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Backlink Outreach Email",
"description":"Reach out to other websites for link exchange opportunities.",
"color":"#6ac259",
"icon" : EnvelopeIcon,
"href": "/templates/backlink-outreach-email"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Email Writer",
"description":"Professional-looking emails that help you engage leads and customers.",
"color":"#FF823B",
"icon" : EnvelopeIcon,
"href": "/templates/email-writer"},

{"type":"completion",
"_status":"1",
"category":"Email",
"title":"Personalized Cold Emails",
"description":"Write cold emails that actually work and get responses.",
"color":"#3473A4",
"icon" : EnvelopeIcon,
"href": "/templates/personalized-cold-emails"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Caption Generator",
"description":"Create captivating and interactive social media captions that resonate with your audience and encourage engagement.",
"color":"#FFB366",
"icon" : GlobeAltIcon,
"href": "/templates/caption-generator"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Generate Content Calendar",
"description":"Create a well-structured content calendar to effectively plan and manage your social media content.",
"color":"#6E8CD5",
"icon" : GlobeAltIcon,
"href": "/templates/content-calendar"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Headlines",
"description":"Craft compelling and attention-grabbing headlines to enhance the impact of your social media posts.",
"color":"#F28888",
"icon" : GlobeAltIcon,
"href": "/templates/headlines"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Instagram Captions",
"description":"Craft engaging and relatable Instagram captions that highlight your product's uniqueness and connect with your target audience.",
"color":"#E973A8",
"icon" : GlobeAltIcon,
"href": "/templates/instagram-captions"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"LinkedIn Post",
"description":"Craft an engaging LinkedIn post that captures attention and tells an immersive story about your chosen topic.",
"color":"#0077B5",
"icon" : GlobeAltIcon,
"href": "/templates/linkedin-post"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"TikTok Script",
"description":"Develop an attention-grabbing TikTok video script that captivates viewers and encourages them to keep watching.",
"color":"#69C9D0",
"icon" : GlobeAltIcon,
"href": "/templates/tiktok-script"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Give a Controversial Opinion on [topic], Then Turn It into a Twitter Thread",
"description":"Express a thought-provoking opinion on a particular topic and craft it into a Twitter thread to spark meaningful discussions.",
"color":"#000000",
"icon" : GlobeAltIcon,
"href": "/templates/controversial-opinion-twitter-thread"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"YouTube Video Description",
"description":"Craft a concise and compelling YouTube video description that encourages your target audience to watch the video on your chosen topic.",
"color":"#FF0000",
"icon" : GlobeAltIcon,
"href": "/templates/youtube-video-description"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Google My Business - Event Post",
"description":"Generate event details for your Google My Business event posts",
"color":"#4285F4",
"icon" : GlobeAltIcon,
"href": "/templates/google-my-business-event-post"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Google My Business - Offer Post",
"description":"Generate offer details for your Google My Business offer posts",
"color":"#4285F4",
"icon" : GlobeAltIcon,
"href": "/templates/google-my-business-offer-post"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Google My Business - Product Description",
"description":"Generate product descriptions for your Google My Business",
"color":"#4285F4",
"icon" : GlobeAltIcon,
"href": "/templates/google-my-business-product-description"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Tweet Machine",
"description":"Generate viral tweets for X.",
"color":"#000000",
"icon" : GlobeAltIcon,
"href": "/templates/tweet-machine"},

{"type":"completion",
"_status":"1",
"category":"Social Media",
"title":"Pinterest Pin Title & Description",
"description":"Create great Pinterest pin titles and descriptions that drive engagement,traffic,and reach.",
"color":"#E60023",
"icon" : GlobeAltIcon,
"href": "/templates/pinterest-pin-title-description"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Customer Case Study",
"description":"Create compelling customer success stories.",
"color":"#00bcd4",
"icon":UserIcon,
"href": "/templates/customer-case-study"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"FAQ Generator",
"description":"Generate comprehensive and SEO-friendly FAQs.",
"color":"#ff9800",
"icon" : GlobeAltIcon,
"href": "/templates/faq-generator"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Headline Generator",
"description":"Craft captivating and effective article headlines.",
"color":"#03a9f4",
"icon" : GlobeAltIcon,
"href": "/templates/headline-generator"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Landing Page Copy",
"description":"Craft compelling copy for landing pages.",
"color":"#4caf50",
"icon" : GlobeAltIcon,
"href": "/templates/landing-page-copy"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Unique Value Propositions",
"description":"Create a clear statement that describes the benefit of your offer in a powerful way.",
"color":"#FFA437",
"icon" : GlobeAltIcon,
"href": "/templates/unique-value-propositions"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Homepage - Title and Meta Descriptions",
"description":"Write SEO optimized title tags and meta descriptions for homepages that will rank well on Google.",
"color":"#7A61F5",
"icon" : GlobeAltIcon,
"href": "/templates/homepage-title-meta-descriptions"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Services Page - Title and Meta Descriptions",
"description":"Write SEO optimized title tags and meta descriptions for services page that will rank well on Google.",
"color":"#FF885B",
"icon" : GlobeAltIcon,
"href": "/templates/services-page-title-meta-descriptions"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Blog Post - Title and Meta Descriptions",
"description":"Write SEO optimized title tags and meta descriptions for blog posts that will rank well on Google.",
"color":"#437CC7",
"icon" : GlobeAltIcon,
"href": "/templates/blog-post-title-meta-descriptions"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Product Page - Title and Meta Descriptions",
"description":"Write SEO optimized title tags and meta descriptions that will rank well on Google for product pages.",
"color":"#FF6530",
"icon" : GlobeAltIcon,
"href": "/templates/product-page-title-meta-descriptions"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Website Sub-headline",
"description":"Create delightfully informative sub-headlines (H2) for your websites and landing pages.",
"color":"#4678C2",
"icon":GlobeAltIcon,
"href": "/templates/website-sub-headline"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Landing Page Headline Writer",
"description":"Unique and catchy headlines that are perfect for your product or service.",
"color":"#FFA14B",
"icon":GlobeAltIcon,
"href": "/templates/landing-page-headline-writer"},

{"type":"completion",
"_status":"1",
"category":"Website",
"title":"Call to Action",
"description":"Eye-catching calls to action that will encourage conversions and boost your sales.",
"color":"#8247F2",
"icon":GlobeAltIcon,
"href": "/templates/call-to-action"}
];

  