import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ProsAndConsInput from './assets/ProsAndConsInput.png';
import ProsAndConsOutput from './assets/ProsAndConsOutput.png';

const ProsAndConsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Pros and Cons</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>List of the main benefits versus the most common problems and concerns. This tool helps you provide a balanced view to your audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Balanced Perspective:</strong> Present both pros and cons for a comprehensive view.</li>
            <li><strong>Informative:</strong> Provide valuable insights to help decision-making.</li>
            <li><strong>Visual Representation:</strong> Create visually appealing lists for easy understanding.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Topic</h3>
            <img src={ProsAndConsInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Pros and Cons</h3>
            <img src={ProsAndConsOutput} alt="Generated Pros and Cons" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Add or edit pros and cons to better suit your needs or specific situation.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Informed Decision-Making:</strong> Help your audience weigh the pros and cons of a topic.</li>
            <li><strong>Transparency:</strong> Demonstrate honesty and transparency by acknowledging both sides.</li>
            <li><strong>Engagement:</strong> Encourage discussion and engagement by presenting different viewpoints.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Reviews:</strong> Provide comprehensive evaluations of products or services.</li>
<li><strong>Comparative Analysis:</strong> Compare different options to help consumers make informed choices.</li>
<li><strong>Debate Topics:</strong> Present arguments for and against specific issues to foster debate and discussion.</li>
</ul>
</section>

    <section>
      <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
      <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
    </section>
  </div>
  <ElaborateFooter />
</>
);
}
export default ProsAndConsPage;

