import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import PersonalizedColdEmailInput from './assets/PersonalizedColdEmailInput.png';
//import PersonalizedColdEmailOutput from './assets/PersonalizedColdEmailOutput.png';

const PersonalizedColdEmailPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Personalized Cold Email From LinkedIn Profile</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Craft effective personalized cold emails using Linked           In profile information to engage potential clients.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Personalization:</strong> Customize emails using LinkedIn profile data.</li>
            <li><strong>Engagement:</strong> Increase response rates with targeted messaging.</li>
            <li><strong>Efficiency:</strong> Save time crafting individual emails.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input LinkedIn Profile URL</h3>
            {/*<img src={PersonalizedColdEmailInput} alt="Input LinkedIn Profile URL" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Cold Email</h3>
            {/*<img src={PersonalizedColdEmailOutput} alt="Generated Cold Email" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Send</h3>
            <p>Review the personalized email and send it to your prospect.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Higher Response Rates:</strong> Personalized emails increase engagement.</li>
            <li><strong>Better Connections:</strong> Build stronger relationships with prospects.</li>
            <li><strong>Streamlined Outreach:</strong> Simplify your cold email process.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default PersonalizedColdEmailPage;

