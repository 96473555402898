import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import IndustryTrendsAnalysisInput from './assets/IndustryTrendsAnalysisInput.png';
//import IndustryTrendsAnalysisOutput from './assets/IndustryTrendsAnalysisOutput.png';

const IndustryTrendsAnalysisPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Analyze Industry Trends</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Analyze industry trends and present findings effectively.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>In-Depth Analysis:</strong> Detailed insights into industry trends.</li>
            <li><strong>Customizable Reports:</strong> Tailor reports to your specific needs.</li>
            <li><strong>Data Visualization:</strong> Use charts and graphs to present data clearly.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Industry Details</h3>
            {/*<img src={IndustryTrendsAnalysisInput} alt="Input Industry Details" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Analysis Report</h3>
            {/*<img src={IndustryTrendsAnalysisOutput} alt="Generated Analysis Report" className="mb-4" /> */}
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Present</h3>
            <p>Review your analysis and present findings to stakeholders.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Data-Driven Decisions:</strong> Make informed decisions based on industry trends.</li>
            <li><strong>Improved Strategy:</strong> Develop better strategies with industry insights.</li>
            <li><strong>Competitive Advantage:</strong> Stay ahead of the competition by understanding trends.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default IndustryTrendsAnalysisPage;
