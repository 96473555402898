import Graphia from '../assets/GraphiaAI-white.svg';

const navigation = {
  solutions: [
    { name: 'Marketing', href: '/usecases/graphia-marketing', blank: false },
    { name: 'Sales', href: '/usecases/graphia-sales' , blank: false},
    { name: 'Ecommerce', href: '/usecases/graphia-ecommerce' , blank: false},
  ],
  products: [
	{name: 'AI Writer', href: '/products/ai-writer', blank: false},
	{name: 'AI Image Generator', href: '/products/ai-images', blank: false},
	{name: 'AI Voices', href: '/products/ai-voices', blank: false},
        {name: 'AI Chats', href: '/products/ai-chats', blank: false},
  ],
  support: [
    { name: 'Pricing', href: '/pricing' , blank: false},
  ],
  company: [
    { name: 'Blog', href: '/blogs' , blank: false},
    { name: 'Templates', href: '/templates', blank: false},
    { name: 'LoveAdvisor AI', href: 'https://www.loveadvisor.ai', blank: true}, 
    { name: 'Reppin', href: 'https://www.getreppin.com', blank: true},
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy-policy' , blank: false},
    { name: 'Terms of Service', href: '/terms-of-service' , blank: false},
    { name: 'Refund Policy', href: '/refund-policy' , blank: false},
    { name: 'Affiliates Program Policy', href: '/affiliates-policy' , blank: false},
  ],
  social: [
    {
      name: 'X',
      href: 'https://twitter.com/graphiaai',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
	
    {
    	name: 'Linkedin',
	href: 'https://www.linkedin.com/company/graphiaai/',
    	icon: (props) => (
		<svg fill="currentColor" viewBox="0 0 16 16" {...props}>
  		<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
		</svg>
    	),
    },

   {
        name: 'YouTube',
        href: 'https://www.youtube.com/@GraphiaAI-yt9mw',
        icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 32 32" {...props}>
			<path xmlns="http://www.w3.org/2000/svg" d="M12.932 20.459v-8.917l7.839 4.459zM30.368 8.735c-0.354-1.301-1.354-2.307-2.625-2.663l-0.027-0.006c-3.193-0.406-6.886-0.638-10.634-0.638-0.381 0-0.761 0.002-1.14 0.007l0.058-0.001c-0.322-0.004-0.701-0.007-1.082-0.007-3.748 0-7.443 0.232-11.070 0.681l0.434-0.044c-1.297 0.363-2.297 1.368-2.644 2.643l-0.006 0.026c-0.4 2.109-0.628 4.536-0.628 7.016 0 0.088 0 0.176 0.001 0.263l-0-0.014c-0 0.074-0.001 0.162-0.001 0.25 0 2.48 0.229 4.906 0.666 7.259l-0.038-0.244c0.354 1.301 1.354 2.307 2.625 2.663l0.027 0.006c3.193 0.406 6.886 0.638 10.634 0.638 0.38 0 0.76-0.002 1.14-0.007l-0.058 0.001c0.322 0.004 0.702 0.007 1.082 0.007 3.749 0 7.443-0.232 11.070-0.681l-0.434 0.044c1.298-0.362 2.298-1.368 2.646-2.643l0.006-0.026c0.399-2.109 0.627-4.536 0.627-7.015 0-0.088-0-0.176-0.001-0.263l0 0.013c0-0.074 0.001-0.162 0.001-0.25 0-2.48-0.229-4.906-0.666-7.259l0.038 0.244z"/>
		</svg>
        ),
    },


	
  ],
}

export default function Example() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <img
              className="h-7"
              alt="Graphia"
	      src={Graphia}
	      style={{height:'23px'}}
            />
            <p className="text-sm leading-6 text-gray-300">
              AI content generation platform for new age, high performing teams.
            </p>
	    <p className="text-sm leading-6 text-gray-300  border-t border-white/10 py-4">
		Graphia, Inc.
		<br/>1111B S Governors Ave STE 3674
		<br/>Dover, DE
		<br/> 19904	
            </p>
	
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8 grid grid-cols-2 gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Solutions</h3>
                <ul  className="mt-6 space-y-2">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
			<a
                                href={item.href}
                                className="text-sm leading-6 text-gray-300 hover:text-white"
                                target={item.blank ? "_blank" : undefined}
                                rel={item.blank ? "noopener noreferrer" : undefined}
                        >
                                {item.name}
                        </a>
                    </li>
                  ))}
                </ul>
              </div>
		<div>
                <h3 className="text-sm font-semibold leading-6 text-white">Products</h3>
                <ul  className="mt-6 space-y-2">
                  {navigation.products.map((item) => (
                    <li key={item.name}>
			<a
                                href={item.href}
                                className="text-sm leading-6 text-gray-300 hover:text-white"
                                target={item.blank ? "_blank" : undefined}
                                rel={item.blank ? "noopener noreferrer" : undefined}
                        >
                                {item.name}
                        </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-3 md:gap-8 grid grid-cols-1 gap-5">
		<div className="md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Support</h3>
                <ul  className="mt-6 space-y-2">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
			<a
                                href={item.href}
                                className="text-sm leading-6 text-gray-300 hover:text-white"
                                target={item.blank ? "_blank" : undefined}
                                rel={item.blank ? "noopener noreferrer" : undefined}
                        >
                                {item.name}
                        </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold leading-6 text-white">Company</h3>
                <ul className="mt-6 space-y-2">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
			<a 
  				href={item.href} 
  				className="text-sm leading-6 text-gray-300 hover:text-white"
  				target={item.blank ? "_blank" : undefined} 
  				rel={item.blank ? "noopener noreferrer" : undefined}
			>
  				{item.name}
			</a>

                    </li>
                  ))}
                </ul>
              </div>
              <div className="md:mt-0">
                <h3 className="text-sm font-semibold leading-6 text-white">Legal</h3>
                <ul  className="mt-6 space-y-2">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
	</div>
	<div className="mx-auto max-w-7xl px-6 py-6 md:flex md:items-center md:justify-between lg:px-8 border-t border-white/10">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500" target="_blank" rel="noreferrer">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024-present Graphia, Inc. All rights reserved.
          </p>
        </div>
      </div>

    </footer>
  )
}

