import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ContentImproverInput from './assets/ContentImproverInput.png';
import ContentImproverOutput from './assets/ContentImproverOutput.png';

const ContentImproverPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Content Improver</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Take a piece of content and rewrite it to make it more interesting, creative, and engaging. This tool helps you enhance your content by adding creativity and improving its overall appeal.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Enhanced Creativity:</strong> Rewrite content to make it more interesting and engaging.</li>
            <li><strong>Improved Readability:</strong> Make your content more readable and appealing.</li>
            <li><strong>Customizable:</strong> Tailor the rewritten content to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Content</h3>
            <img src={ContentImproverInput} alt="Input Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Improved Content</h3>
            <img src={ContentImproverOutput} alt="Generated Improved Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the improved content to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Make your content more appealing to readers.</li>
            <li><strong>Improved Quality:</strong> Enhance the overall quality of your content.</li>
            <li><strong>Time-Saving:</strong> Quickly improve your content without starting from scratch.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Improve the appeal of your blog posts.</li>
            <li><strong>Content Creators:</strong> Enhance the creativity and engagement of your content.</li>
            <li><strong>Marketers:</strong> Create more engaging and effective marketing content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ContentImproverPage;
