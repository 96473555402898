
import Header from '../common/Header.js';
import HeroSection from './components/HeroSection.js';
import Features from './components/Features.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import HowItWorks from './components/HowItWorks.js';
import CTA from './components/CTA.js';
import React from 'react';

function LandingPage() {
 return (
   <React.Fragment>
      <Header/>
      <HeroSection/>
      <Features/>
      <HowItWorks/>
      <CTA/>
     {/*<Cookies/>*/}
      <ElaborateFooter/>
  </React.Fragment>
)
}

export default LandingPage;
