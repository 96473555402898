import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import AnalogyMakerInput from './assets/AnalogyMakerInput.png';
import AnalogyMakerOutput from './assets/AnalogyMakerOutput.png';

const AnalogyMakerPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Analogy Maker</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate unique analogies that make your sales pitch more memorable and engaging with the Analogy Maker. Analogies can simplify complex concepts, create visual imagery, and help your audience understand the value of your product or service in relatable terms.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customizable Analogies:</strong> Tailor analogies to fit your specific product or industry.</li>
            <li><strong>Engagement Boost:</strong> Enhance your sales pitch with memorable and relatable analogies.</li>
            <li><strong>Visual Imagery:</strong> Paint a vivid picture in your audience's mind to illustrate key points.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Context</h3>
            <p>Provide context or information about your product or service that you want to create analogies for.</p>
            <img src={AnalogyMakerInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Analogies</h3>
            <img src={AnalogyMakerOutput} alt="Input Topic" className="mb-4"/>
            <p>Click the generate button to receive unique analogies based on your input.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Memorable Pitch:</strong> Stand out from competitors with compelling analogies that leave a lasting impression.</li>
            <li><strong>Clarity:</strong> Simplify complex concepts and make them easier for your audience to understand.</li>
            <li><strong>Persuasion:</strong> Persuade and motivate your audience by appealing to their emotions and imagination.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Sales Presentations:</strong> Use analogies to illustrate the benefits of your product or service to potential customers.</li>
            <li><strong>Marketing Materials:</strong> Incorporate analogies into your marketing campaigns to make your messaging more impactful.</li>
            <li><strong>Content Creation:</strong> Generate analogies to enrich your blog posts, articles, and social media content.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default AnalogyMakerPage;
