import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const LandingPageCopyPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Landing Page Copy</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Landing Page Copy template is designed to help you craft compelling copy for your landing pages. Effective landing page copy can significantly impact conversions and user engagement, making it a crucial aspect of your marketing strategy.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Understand Your Audience</h3>
            <p>Identify your target audience and their pain points. Tailor your copy to address their needs and motivations.</p>
            <h3 className="text-xl text-indigo-800 mb-2">2. Highlight Benefits</h3>
            <p>Focus on the benefits of your product or service rather than just features. Clearly communicate how it solves your audience's problems.</p>
            <h3 className="text-xl text-indigo-800 mb-2">3. Use Persuasive Language</h3>
            <p>Employ persuasive language and calls-to-action to encourage visitors to take the desired action, whether it's making a purchase, signing up for a newsletter, or requesting more information.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increase Conversions:</strong> Compelling landing page copy can lead to higher conversion rates and improved ROI.</li>
            <li><strong>Enhance User Engagement:</strong> Engaging copy keeps visitors on your page longer and encourages them to explore further.</li>
            <li><strong>Build Trust:</strong> Clear and concise copy builds trust with your audience and positions your brand as credible and trustworthy.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4">Craft persuasive landing page copy that converts <a className="text-md text-indigo-600" href="/templates/landing-page-copy">here</a>.</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LandingPageCopyPage;
