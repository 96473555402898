import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import PersonalBioWriterInput from './assets/PersonalBioWriterInput.png';
import PersonalBioWriterOutput from './assets/PersonalBioWriterOutput.png';

const PersonalBioWriterPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Personal Bio Writer</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Create the perfect personal bio that showcases your expertise and brings you more customers. This tool helps you craft a professional and engaging bio to highlight your skills and achievements.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Professional Presentation:</strong> Create a bio that highlights your expertise and achievements.</li>
            <li><strong>Engaging Content:</strong> Craft a bio that captures the reader's interest.</li>
            <li><strong>Customizable:</strong> Tailor your bio to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-400 mb-2">1. Input Your Information</h3>
            <img src={PersonalBioWriterInput} alt="Input Information" className="mb-4"/>
            <h3 className="text-xl text-indigo-400 mb-2">2. Generate Your Bio</h3>
            <img src={PersonalBioWriterOutput} alt="Generated Bio" className="mb-4"/>
            <h3 className="text-xl text-indigo-400 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated bio to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Professionalism:</strong> Present yourself in a professional manner.</li>
            <li><strong>Improved Engagement:</strong> Capture the reader's interest with an engaging bio.</li>
            <li><strong>Time-Saving:</strong> Quickly create a professional bio without starting from scratch.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Freelancers:</strong> Create a professional bio to attract more clients.</li>
            <li><strong>Job Seekers:</strong> Craft an engaging bio to enhance your resume.</li>
            <li><strong>Professionals:</strong> Develop a bio to showcase your expertise and achievements.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default PersonalBioWriterPage;
