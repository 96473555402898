import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import AnswersInput from './assets/AnswersInput.png';
import AnswersOutput from './assets/AnswersOutput.png';

const AnswersPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Answers</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Quality answers to any questions or concerns that your audience might have. This tool helps you provide informative and helpful responses to improve engagement.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Informative Responses:</strong> Provide detailed answers to address audience questions or concerns.</li>
            <li><strong>Engagement Boost:</strong> Enhance audience engagement by addressing their inquiries promptly.</li>
            <li><strong>Customizable:</strong> Tailor responses to fit different topics and audience preferences.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Question</h3>
            <img src={AnswersInput} alt="Input Question" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Answer</h3>
            <img src={AnswersOutput} alt="Generated Answer" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated answer to provide additional information or clarify points.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Engagement:</strong> Foster better interaction with your audience by addressing their inquiries.</li>
            <li><strong>Trust Building:</strong> Establish credibility and trust by providing helpful and informative responses.</li>
            <li><strong>Time-Saving:</strong> Quickly generate answers without extensive research or drafting.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customer Support:</strong> Provide timely and helpful responses to customer inquiries.</li>
            <li><strong>Content Creation:</strong> Generate informative content by addressing common questions in your niche.</li>
            <li><strong>Community Engagement:</strong> Foster engagement in online communities by answering member questions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default AnswersPage;

