import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ToneChangerInput from './assets/ToneChangerInput.png';
import ToneChangerOutput from './assets/ToneChangerOutput.png';

const ToneChangerPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Tone Changer</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Change the tone of your writing to match your audience and copy. This tool allows you to adjust the tone of your content to better resonate with your target audience, whether it's formal, casual, professional, or friendly.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Tone Adjustment:</strong> Modify the tone of your content to match your intended audience.</li>
            <li><strong>Flexible Options:</strong> Choose from various tone presets or customize the tone to suit your needs.</li>
            <li><strong>Enhanced Communication:</strong> Improve communication effectiveness by aligning the tone with your audience's preferences.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Content</h3>
            <img src={ToneChangerInput} alt="Input Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Select Tone</h3>
            <img src={ToneChangerOutput} alt="Modified Tone Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Review and Adjust</h3>
            <p>Review the modified tone content and make any necessary adjustments to ensure clarity and effectiveness.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Communication:</strong> Tailor your message to resonate with your audience.</li>
            <li><strong>Enhanced Engagement:</strong> Increase reader engagement by speaking their language.</li>
            <li><strong>Flexible Options:</strong> Adjust the tone to fit various contexts and communication goals.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Marketing Copy:</strong> Adapt marketing materials to appeal to different demographics.</li>
            <li><strong>Customer Communications:</strong> Personalize communication with customers based on their preferences.</li>
            <li><strong>Social Media Posts:</strong> Adjust the tone of social media content to match the platform and audience.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ToneChangerPage;

