import Header from '../../../common/Header';
import ElaborateFooter from '../../../common/ElaborateFooter';

const NewsletterInspirationPage = () => {
    return (
      <>
        <Header />
        <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
          <h1 className="text-3xl text-indigo-600">Newsletter Inspiration</h1>
          
          <section className="mb-8 mt-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
            <p>This tool provides you with inspiration and insights for creating impactful newsletters. Whether you're looking for content ideas, design inspiration, or strategies to enhance reader engagement, this resource has you covered.</p>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
            <ul className="list-disc list-inside">
              <li><strong>Content Ideas:</strong> Discover unique and engaging content topics for your newsletters.</li>
              <li><strong>Design Inspiration:</strong> Explore visually appealing newsletter layouts and designs.</li>
            </ul>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
            <div>
              <h3 className="text-xl text-indigo-800 mb-2">1. Explore Resources</h3>
              <h3 className="text-xl text-indigo-800 mb-2">2. Gather Ideas</h3>
              <p>Browse through curated content and design examples to spark creativity.</p>
            </div>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
            <ul className="list-disc list-inside">
              <li><strong>Inspiration:</strong> Find inspiration to create engaging and visually appealing newsletters.</li>
              <li><strong>Efficiency:</strong> Save time and effort by accessing ready-made templates and resources.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
            <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
          </section>
  
        </div>
        <ElaborateFooter />
      </>
    );
  };

  export default NewsletterInspirationPage;