import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import BlogPostConclusionInput from './assets/BlogPostConclusionInput.png';
import BlogPostConclusionOutput from './assets/BlogPostConclusionOutput.png';

const BlogPostConclusionParagraphPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Blog Post Conclusion Paragraph</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Wrap up your blog posts with an engaging closing paragraph. This tool helps you craft effective conclusions that leave a lasting impression on your readers and encourage them to take action.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Conclusions:</strong> Generate closing paragraphs that captivate your audience.</li>
            <li><strong>Call-to-Action:</strong> Encourage readers to engage further with compelling CTAs.</li>
            <li><strong>Customizable:</strong> Tailor the conclusion to fit your blog post's tone and style.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Blog Content</h3>
            <img src={BlogPostConclusionInput} alt="Input Blog Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Conclusion</h3>
            <img src={BlogPostConclusionOutput} alt="Generated Conclusion" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated conclusion to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Reader Engagement:</strong> Leave a lasting impression with effective conclusions.</li>
            <li><strong>Action-Oriented:</strong> Prompt readers to take desired actions with compelling CTAs.</li>
            <li><strong>Time-Saving:</strong> Quickly create impactful closing paragraphs for your blog posts.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Wrap up blog posts with effective conclusions.</li>
            <li><strong>Content Creators:</strong> Enhance the impact of your content with engaging closing paragraphs.</li>
            <li><strong>Marketers:</strong> Drive reader engagement and actions with powerful conclusions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BlogPostConclusionParagraphPage;
