import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import PassiveToActiveVoiceInput from './assets/PassiveToActiveVoiceInput.png';
import PassiveToActiveVoiceOutput from './assets/PassiveToActiveVoiceOutput.png';

const PassiveToActiveVoicePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Passive to Active Voice</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Easy and quick solution to converting your passive voice sentences into active voice sentences. This tool helps improve the clarity and impact of your writing.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Quick Conversion:</strong> Instantly convert passive voice sentences to active voice.</li>
            <li><strong>Improved Clarity:</strong> Make your writing clearer and more direct.</li>
            <li><strong>Easy to Use:</strong> User-friendly interface for quick edits.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-400 mb-2">1. Input Your Text</h3>
            <img src={PassiveToActiveVoiceInput} alt="Input Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-400 mb-2">2. Convert to Active Voice</h3>
            <img src={PassiveToActiveVoiceOutput} alt="Converted Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-400 mb-2">3. Customize (Optional)</h3>
            <p>Modify the converted sentences to better fit your writing style.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Enhanced Readability:</strong> Make your sentences more engaging and easier to read.</li>
            <li><strong>Professional Tone:</strong> Improve the professionalism of your writing.</li>
            <li><strong>Time-Saving:</strong> Quickly convert sentences without manual rephrasing.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Writers:</strong> Improve the clarity of your articles and stories.</li>
            <li><strong>Students:</strong> Enhance your academic writing by avoiding passive voice.</li>
            <li><strong>Professionals:</strong> Create more impactful business documents and reports.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default PassiveToActiveVoicePage;
