import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import AmazonProductFeaturesInput from './assets/AmazonProductFeaturesInput.png';
//import AmazonProductFeaturesOutput from './assets/AmazonProductFeaturesOutput.png';

const AmazonProductFeaturesPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Amazon Product Features</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Advantages and features of your products that will make them irresistible to shoppers.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Highlight Benefits:</strong> Showcase the unique benefits of your product.</li>
            <li><strong>Increase Sales:</strong> Attract more customers with compelling features.</li>
            <li><strong>Competitive Edge:</strong> Stand out from competitors with clear advantages.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Product Details</h3>
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Product Features</h3>
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Publish</h3>
            <p>Review the generated features and publish them on your Amazon product page.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Visibility:</strong> Make your product more appealing to shoppers.</li>
            <li><strong>Higher Conversion:</strong> Turn more visitors into buyers with compelling features.</li>
            <li><strong>Enhanced Trust:</strong> Build trust with detailed and clear product benefits.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default AmazonProductFeaturesPage;
