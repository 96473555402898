import AIchat from './assets/AIchat.png';

const posts = [
   {
    id: 8,
    title: "Introducing Affiliates Program for Graphia : Earn Commissions on every purchase",
    href: "/blogs/2024-10-15/introducing-affiliate-program-for-graphia-earn-commissions",
    description: "This blog post explains affiliates program for Graphia AI",
    imageUrl:
		"https://plus.unsplash.com/premium_photo-1661764256397-af154e87b1b3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    date: 'Oct 15, 2024',
    datetime: '2024-10-15',
    category: { title: 'Referral', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1690653781483-108a31195d43?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  },   

   {
    id: 7,
    title: "How to Use AI for Competitive Analysis: A Step-by-Step Guide",
    href: "/blogs/2024-09-04/how-to-use-ai-for-competitive-analysis-a-step-by-step-guide",
    description: "This blog post is a step by step guide to use AI for competitive analysis.",
    imageUrl: 
	'https://images.unsplash.com/photo-1690653781483-108a31195d43?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    date: 'Sep 4, 2024',
    datetime: '2024-09-04',
    category: { title: 'Guides', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
	'https://images.unsplash.com/photo-1690653781483-108a31195d43?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
  },
  
  {
    id: 6,
    title: "Exploring Graphia's Cutting-Edge AI Chat Features",
    href: "/blogs/2024-05-21/exploring-graphias-cutting-edge-ai-chat-features",
    description: "This blog post will delve into the standout features of Graphia’s AI chat, showcasing its potential to enhance productivity.",
    imageUrl: AIchat,
    date: 'May 21, 2024',
    datetime: '2024-05-21',
    category: { title: 'New Features', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },

     {
    id: 5,
    title: "Mastering AI Voiceover: Tips for Natural-Sounding Narration on Graphia AI",
    href: "/blogs/2024-05-15/mastering-ai-voiceover-tips-for-natural-sounding-narration-on-graphia-ai",
    description:"The exponential growth in artificial intelligence (AI) has revolutionized various aspects of our lives, and the realm of audio narration is no exception. With the advent of AI-driven voice generators, including the likes of Graphia AI, generating natural-sounding voiceovers is more achievable and more accessible than ever before.", 
    imageUrl:
		"https://images.unsplash.com/photo-1516485392461-3961cc21f1e7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    date: 'May 15, 2024',
    datetime: '2024-05-15',
    category: { title: 'Guides', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  
    { 
    id: 4, 
    title: "Unveiling Graphia's latest features: a game changer for creatives",
    href: '/blogs/2024-05-06/unveiling-graphias-latest-features-a-game-changer-for-creatives',
    description: "Exciting news from Graphia! We're thrilled to introduce groundbreaking additions: AI Image Generator for stunning visuals, AI Voiceover for natural-sounding narration, GPT4.5 Turbo & Claude 3 in AI Writer for compelling content, and Workspaces & Sharing for seamless collaboration. Get ready to supercharge your creativity with Graphia's latest innovations!", 
    imageUrl:
	'https://images.unsplash.com/photo-1457364887197-9150188c107b?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    date: 'May 6, 2024',
    datetime: '2024-05-06',
    category: { title: 'New Features', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 1,
    title: 'Decoding the Anatomy of a Good Prompt for ChatGPT: Crafting Conversations with AI',
    href: '/blogs/2024-04-27/decoding-the-anatomy-of-a-good-prompt-for-chatgpt-crafting-conversations-with-ai',
    description: "Crafting effective prompts for ChatGPT is essential for meaningful and engaging interactions with the AI. This blog explores the key elements of a good prompt, including clarity, context, open-endedness, politeness, engagement, neutrality, creativity, specificity, empathy, and a commitment to iterative improvement. Each element is illustrated with examples to demonstrate its application in practice. By mastering the art of crafting well-defined prompts, users can unlock the full potential of AI-driven conversations, fostering enlightening exchanges and enriching experiences. Additionally, the blog highlights the importance of SEO compatibility, outlining parameters to optimize the content for search engine visibility.",
    imageUrl:
        'https://plus.unsplash.com/premium_photo-1661443598082-afd740d6d07c?q=80&w=2789&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    date: 'Apr 27, 2024',
    datetime: '2024-04-27',
    category: { title: 'Effective Prompts', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
   {
    id: 2,
    title: 'A Comprehensive Guide to Crafting Effective Prompts for Conversational AI',
    href: '/blogs/2024-04-22/mastering-chatgpt-comprehensive-guide-to-crafting-effective-prompts-for-conversational-ai',
    description: "This comprehensive guide explores the intricacies of crafting effective prompts for ChatGPT, OpenAI's versatile conversational AI system. It emphasizes the importance of clarity, context, natural language, personalization, timing, and ethical considerations in prompt design. By understanding ChatGPT's capabilities and adhering to best practices, users can create prompts that guide conversations, elicit meaningful responses, and enhance user experiences. Continuous iteration and refinement are crucial for optimizing prompt effectiveness over time. Ultimately, mastering the art of prompt design empowers users to unlock the full potential of ChatGPT and revolutionize interactions with AI technology.",
    imageUrl:
        'https://images.unsplash.com/photo-1675557009483-e6cf3867976b?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    date: 'Apr 22, 2024',
    datetime: '2024-04-22',
    category: { title: 'Effective Prompts', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }
},

  {
    id: 3,
    title: 'Revolutionizing Content Creation: Unlocking the Power of AI',
    href: '/blogs/2024-04-15/revolutionizing-content-creation-Unlocking-power-of-ai',
    description: 'Discover how Graphia.ai is transforming content creation for sales, marketing, and ecommerce professionals. Through the power of AI, Graphia.ai streamlines the process of generating personalized sales pitches, dynamic marketing content, and compelling ecommerce product descriptions. ',
    imageUrl:
      'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Apr 15, 2024',
    datetime: '2024-04-15',
    category: { title: 'Content Creation', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
]

export default function Example() {
  return (
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
		<h2 className="text-lg font-semibold text-indigo-600">Blog</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        	Graphia AI Blog
	</p>
        <p className="mt-1 text-lg text-gray-600">
		Latest updates, announcements, community, prompt guides etc.
        </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <span
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </span>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                <div className="relative mt-8 flex items-center gap-x-4">
                  <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                      <a href={post.author.href} target="_blank">
                        <span className="absolute inset-0" />
                        {post.author.name}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}

