import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import LinkedInAdHeadlineInput from './assets/LinkedInAdHeadlineInput.png';
//import LinkedInAdHeadlineOutput from './assets/LinkedInAdHeadlineOutput.png';

const LinkedInAdHeadlinePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">LinkedIn Ad Headline</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Create attention-grabbing, click-inducing, and high-converting ad headlines for LinkedIn.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Headlines:</strong> Generate headlines that grab attention.</li>
            <li><strong>Customizable Templates:</strong> Tailor headline templates to fit your brand’s voice.</li>
            <li><strong>Performance Analytics:</strong> Monitor and improve headline performance.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Headline Details</h3>
            {/*<img src={LinkedInAdHeadlineInput} alt="Input Headline Details" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Headline</h3>
            {/*<img src={LinkedInAdHeadlineOutput} alt="Generated Headline" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">3. Optimize and Publish</h3>
            <p>Optimize your headlines with provided tips and publish them on LinkedIn.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Drive more engagement with your headlines.</li>
            <li><strong>Higher Click-Through Rates:</strong> Convert more viewers into clicks.</li>
            <li><strong>Better ROI:</strong> Maximize your return on investment with effective headlines.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LinkedInAdHeadlinePage;
