import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import WebsiteSubHeadlineInput from './assets/WebsiteSubHeadlineInput.png';
//import WebsiteSubHeadlineOutput from './assets/WebsiteSubHeadlineOutput.png';

const WebsiteSubHeadlinePage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
      <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Website Sub-headline</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Website Sub-headline tool allows you to create delightfully informative sub-headlines (H2) for your websites and landing pages. With this tool, you can generate engaging and descriptive sub-headlines that capture the essence of your content and entice visitors to explore further.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customization:</strong> Tailor sub-headlines to match the tone and style of your website or landing page.</li>
            <li><strong>Keyword Integration:</strong> Incorporate relevant keywords to improve search engine optimization (SEO) and attract organic traffic.</li>
            <li><strong>Preview:</strong> View how the sub-headline will appear on your website or landing page before implementation.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Website Content</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Sub-headline</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Preview and Implement</h3>
            <p>Preview how the sub-headline will appear on your website or landing page and implement it for enhanced engagement.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Enhanced Engagement:</strong> Engaging sub-headlines captivate visitors and encourage them to explore further.</li>
            <li><strong>Improved SEO:</strong> Incorporating relevant keywords in sub-headlines boosts search engine visibility and drives organic traffic.</li>
            <li><strong>Clear Communication:</strong> Informative sub-headlines effectively communicate the essence of your content and guide visitors.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default WebsiteSubHeadlinePage;
