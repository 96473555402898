// src/components/WhyJoin.js
import React from 'react';
import { FaDollarSign, FaRegSmile, FaChartLine, FaCreditCard } from 'react-icons/fa'; // Add icons for visual interest

const WhyJoin = () => {
  return (
    <section className="mt-16 bg-white px-6 py-12 sm:py-12 lg:px-8">
      <h2 className="text-3xl font-bold text-center text-gray-800">Why Join Our Affiliate Program?</h2>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
        <div className="flex flex-col items-center">
          <div className="text-green-500 text-6xl">
            <FaDollarSign />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">Unlimited Earnings</h3>
          <p className="mt-2 text-center text-gray-600">
            The more you refer, the more you earn. No limits, just opportunities!
          </p>
        </div>

        <div className="flex flex-col items-center">
          <div className="text-blue-500 text-6xl">
            <FaRegSmile />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">Easy to Use</h3>
          <p className="mt-2 text-center text-gray-600">
            Effortlessly share your referral link and start earning commissions.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <div className="text-purple-500 text-6xl">
            <FaChartLine />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">No Cap on Commissions</h3>
          <p className="mt-2 text-center text-gray-600">
            Earn a percentage on all purchases, no matter how high the total.
          </p>
        </div>

        <div className="flex flex-col items-center">
          <div className="text-yellow-500 text-6xl">
            <FaCreditCard />
          </div>
          <h3 className="mt-4 text-xl font-semibold text-gray-800">Flexible Payouts</h3>
          <p className="mt-2 text-center text-gray-600">
            Get paid easily and quickly once you reach the payout threshold.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyJoin;

