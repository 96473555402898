import React from 'react'

const steps = [
  { id: 'Step 1', name: 'Select writing template', description: 'Simply choose a template from available list to write content for blog posts, landing page, website content etc.', status: 'complete' },
  { id: 'Step 2', name: 'Describe your topic', description: 'Provide our AI content writer with few sentences on what you want to write, and it will start writing for you.', status: 'complete' },
  { id: 'Step 3', name: 'Generate quality content', description: 'Our powerful AI tools will generate content in few second, then you can export it to wherever you need.', status: 'current' },
]

export default function Example() {
  return (
    <div className="bg-gray-900 bg-gradient-to-r to-[#4665e5] from-[#7746e5] px-6 py-24 sm:py-24 lg:px-8">
      <div className="mx-auto max-w-2xl text-center py-12 sm:py-12">
        <h2 className="text-base font-semibold leading-7 text-gray-300">How it works</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-3xl">Instruct our AI and generate copy
</p>
        <p className="mt-6 text-lg leading-8 text-gray-300 mb-10">
		Give our AI a few descriptions and we will automatically create blog articles, product descriptions and more for you within just a few seconds.
        </p>
      </div>
	<nav aria-label="Progress" className="py-6 sm:py-6">
      <ol className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.status === 'complete' ? (
              <div
                className="group flex flex-col border-l-4 border-white py-2 pl-4  md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 gap-4"
              >
                <span className="text-sm font-medium text-gray-300">{step.id}</span>
                <span className="text-2xl font-semibold text-white">{step.name}</span>
		<span className="text-md font-medium text-gray-300">{step.description}</span>
              </div>
            ) : step.status === 'current' ? (
              <div
                className="flex flex-col border-l-4 border-white py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 gap-4"
                aria-current="step"
              >
                <span className="text-sm font-medium text-gray-300">{step.id}</span>
                <span className="text-2xl font-semibold text-white">{step.name}</span>
		<span className="text-md font-medium text-gray-300">{step.description}</span>
		
              </div>
            ) : (
              <div
                className="group flex flex-col border-l-4 border-white py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 gap-4"
              >
                <span className="text-sm font-medium text-gray-300">{step.id}</span>
                <span className="text-2xl font-semibold text-white">{step.name}</span>
		<span className="text-md font-medium text-gray-300">{step.description}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>

    </div>
  )
}

