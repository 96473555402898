
import LandingPage from '../landingpage/index.js'
import Writer from '../writer/index.js';
import Voices from '../voices/index.js';
import Images from '../imagine/index.js';
import Chats from '../chats/index.js';
import Affiliates from '../affiliates/index.js';
import PrivacyPolicy from '../policies/Privacy.js'
import RefundPolicy from '../policies/RefundPolicy.js'
import AffiliatesPolicy from '../policies/Affiliates.js'
import TermsAndConditions from '../policies/TermsAndConditions.js'
import Marketing from '../usecases/Marketing.js'
import Sales from '../usecases/Sales.js'
import Ecommerce from '../usecases/Ecommerce.js'
import Pricing from '../pricing/index.js';
import Blog from '../Blog.js';
import Blog1 from '../blogs/Blog1.js';
import Blog2 from '../blogs/Blog2.js';
import Blog3 from '../blogs/Blog3.js';
import Blog4 from '../blogs/Blog4.js';
import Blog5 from '../blogs/Blog5.js';
import Blog6 from '../blogs/Blog6.js';
import Blog7 from '../blogs/Blog7.js';
import Blog8 from '../blogs/Blog8.js';
import Templates from '../templates/index.js';

import ReviewResponderPage from '../templates/components/detailpages/ReviewResponder.js';
import ArticleBlogIdeasPage from '../templates/components/detailpages/ArticlesAndBlogIdeas.js';
import ArticleOutlinesPage from '../templates/components/detailpages/ArticleOutlines.js';
import ArticleGeneratorPage from '../templates/components/detailpages/ArticleGenerator.js';
import ArticleIntrosPage from '../templates/components/detailpages/ArticleIntros.js';
import BlogOutlinePage from '../templates/components/detailpages/BlogOutline.js';
import BlogTitlesPage from '../templates/components/detailpages/BlogTitles.js';
import BlogPostLongFormPage from '../templates/components/detailpages/BlogPostLongFormPage.js';
import BlogPostShortFormPage from '../templates/components/detailpages/BlogPostShortFormPage.js';
import BlogPostConclusionParagraphPage from '../templates/components/detailpages/BlogPostConclusionParagraphPage.js';
import OneShotBlogPostPage from '../templates/components/detailpages/OneShotBlogPostPage.js';
import ParagraphGeneratorPage from '../templates/components/detailpages/ParagraphGeneratorPage.js';
import ConclusionWriterPage from '../templates/components/detailpages/ConclusionWriterPage.js';
import ContentImproverPage from '../templates/components/detailpages/ContentImproverPage.js';
import TextExtenderPage from '../templates/components/detailpages/TextExtenderPage.js';
import ContentShortenerPage from '../templates/components/detailpages/ContentShortenerPage.js';
import TextSummarizerPage from '../templates/components/detailpages/TextSummarizerPage.js';
import StoryWriterPage from '../templates/components/detailpages/StoryWriterPage.js';
import PersonalBioWriterPage from '../templates/components/detailpages/PersonalBioWriterPage.js';
import TranslatorPage from '../templates/components/detailpages/TranslatorPage.js';
import BulletPointAnswersPage from '../templates/components/detailpages/BulletPointAnswersPage.js';
import PassiveToActiveVoicePage from '../templates/components/detailpages/PassiveToActiveVoicePage.js';
import DefinitionPage from '../templates/components/detailpages/DefinitionPage.js';
import AnswersPage from '../templates/components/detailpages/AnswersPage.js';
import ProsAndConsPage from '../templates/components/detailpages/ProsAndConsPage.js';
import QuestionsPage from '../templates/components/detailpages/QuestionsPage.js';
import ListiclePage from '../templates/components/detailpages/ListiclePage';
import ToneChangerPage from '../templates/components/detailpages/ToneChangerPage';
import RewriteWithKeywordsPage from '../templates/components/detailpages/RewriteWithKeywordsPage';
import ExplainToChildPage from '../templates/components/detailpages/ExplainToChildPage';
import SongLyricsGeneratorPage from '../templates/components/detailpages/SongLyricsGeneratorPage';
import ProductNameGeneratorPage from '../templates/components/detailpages/ProductNameGeneratorPage';
import AnalogyMakerPage from '../templates/components/detailpages/AnalogyMakerPage';
import KeywordsExtractorPage from '../templates/components/detailpages/KeywordsExtractorPage';
import ProductBrochurePage from '../templates/components/detailpages/ProductBrochurePage';
import ProductDescriptionsPage from '../templates/components/detailpages/ProductDescriptionsPage';
import ContentRewriterPage from '../templates/components/detailpages/ContentRewriterPage';
import ShortSummaryPage from '../templates/components/detailpages/ShortSummaryPage';
import FacebookAdPage from '../templates/components/detailpages/FacebookAdPage';
import FacebookAdPASPage from '../templates/components/detailpages/FacebookAdPASPage';
import FacebookHeadlinesPage from '../templates/components/detailpages/FacebookHeadlinesPage';
import FacebookVideoScriptPage from '../templates/components/detailpages/FacebookVideoScriptPage';
import GoogleAdsPage from '../templates/components/detailpages/GoogleAdsPage.js';
import LinkedInTextAdsPage from '../templates/components/detailpages/LinkedInTextAdsPage.js';
import LinkedInSingleImageAdsPage from '../templates/components/detailpages/LinkedInSingleImageAdsPage.js';
import LinkedInAdsPage from '../templates/components/detailpages/LinkedInAdsPage.js';
import LinkedInAdHeadlinePage from '../templates/components/detailpages/LinkedInAdHeadlinePage.js';
import AppSMSNotificationsPage from '../templates/components/detailpages/AppSMSNotificationsPage.js';
import OutreachSequencePage from '../templates/components/detailpages/OutreachSequencePage.js';
import IndustryTrendsAnalysisPage from '../templates/components/detailpages/IndustryTrendsAnalysisPage.js';
import PainPointsBrainstormingPage from '../templates/components/detailpages/PainPointsBrainstormingPage.js';
import CompetitiveAnalysisPage from '../templates/components/detailpages/CompetitiveAnalysisPage.js';
import LinkedInBooleanSearchPage from '../templates/components/detailpages/LinkedInBooleanSearchPage.js';
import PersonalizedColdEmailPage from '../templates/components/detailpages/PersonalizedColdEmailPage.js';
import LinkedInProspectResearchPage from '../templates/components/detailpages/LinkedInProspectResearchPage.js';
import AmazonProductFeaturesPage from '../templates/components/detailpages/AmazonProductFeaturesPage.js';
import AmazonProductDescriptionsPage from '../templates/components/detailpages/AmazonProductDescriptionsPage.js';
import AmazonProductTitlesPage from '../templates/components/detailpages/AmazonProductTitlesPage.js';
import EmailSubjectGeneratorPage from '../templates/components/detailpages/EmailSubjectGeneratorPage.js';
import FeaturesAdvantagesBenefitsPage from '../templates/components/detailpages/FeaturesAdvantagesBenefitsPage.js';
import NewsletterRecentNewsPage from '../templates/components/detailpages/NewsletterRecentNewsPage.js';
import NewsletterInspirationPage from '../templates/components/detailpages/NewsletterInspirationPage.js';
import PainAgitateSolutionPage from '../templates/components/detailpages/PainAgitateSolutionPage.js';
import BacklinkOutreachEmailPage from '../templates/components/detailpages/BacklinkOutreachEmailPage.js';
import EmailWriterPage from '../templates/components/detailpages/EmailWriterPage.js';
import PersonalizedColdEmailsPage  from '../templates/components/detailpages/PersonalizedColdEmailsPage.js';
import WebsiteSubHeadlinePage from '../templates/components/detailpages/WebsiteSubHeadlinePage';
import LandingPageHeadlineWriterPage from '../templates/components/detailpages/LandingPageHeadlineWriterPage';
import CallToActionPage from '../templates/components/detailpages/CallToActionPage';
import ServicesPageTitleMetaDescriptionsPage from '../templates/components/detailpages/ServicesPageTitleMetaDescriptionsPage.js';
import BlogPostTitleMetaDescriptionsPage from '../templates/components/detailpages/BlogPostTitleMetaDescriptionsPage.js';
import ProductPageTitleMetaDescriptionsPage from '../templates/components/detailpages/ProductPageTitleMetaDescriptionsPage.js';
import HomepageTitleMetaDescriptionsPage from '../templates/components/detailpages/HomepageTitleMetaDescriptionsPage.js';
import UniqueValuePropositionsPage from '../templates/components/detailpages/UniqueValuePropositionsPage.js';
import LandingPageCopyPage from '../templates/components/detailpages/LandingPageCopyPage.js';
import HeadlineGeneratorPage from '../templates/components/detailpages/HeadlineGeneratorPage.js'; // Import the Headline Generator page component
import CustomerCaseStudyPage from '../templates/components/detailpages/CustomerCaseStudyPage.js'; // Import Customer Case Study page component
import FAQGeneratorPage from '../templates/components/detailpages/FAQGeneratorPage.js'; // Import FAQ Generator page component
// Import Statements
import GMBProductDescriptionPage from '../templates/components/detailpages/GMBProductDescriptionPage.js';
import TweetMachinePage from '../templates/components/detailpages/TweetMachinePage.js';
import PinterestPinTitleDescriptionPage from '../templates/components/detailpages/PinterestPinTitleDescriptionPage.js';
import YouTubeVideoDescriptionPage from '../templates/components/detailpages/YouTubeVideoDescriptionPage.js';
import GMBEventPostPage from '../templates/components/detailpages/GMBEventPostPage.js';
import GMBOfferPostPage from '../templates/components/detailpages/GMBOfferPostPage.js';
import LinkedInPostPage from '../templates/components/detailpages/LinkedInPostPage.js';
import TikTokScriptPage from '../templates/components/detailpages/TikTokScriptPage.js';
import ControversialOpinionTwitterThreadPage from '../templates/components/detailpages/ControversialOpinionTwitterThreadPage.js';
import InstagramCaptionsPage from '../templates/components/detailpages/InstagramCaptionsPage.js';
import CaptionGeneratorPage from '../templates/components/detailpages/CaptionGeneratorPage.js';
import ContentCalendarPage from '../templates/components/detailpages/GenerateContentCalendarPage.js';
import HeadlinesPage from '../templates/components/detailpages/HeadlinesPage.js';




const publicRoutes = [
  { path : "/", component: LandingPage},
  { path : "/products/ai-writer", component: Writer},
  { path : "/products/ai-voices", component: Voices},
  { path : "/products/ai-images", component: Images},
  { path : "/products/ai-chats", component: Chats},
  { path : "/affiliates", component: Affiliates},
  { path: "/privacy-policy", component: PrivacyPolicy},
  { path: "/refund-policy", component: RefundPolicy},
  { path: "/affiliates-policy", component: AffiliatesPolicy},
  { path: "/terms-of-service", component: TermsAndConditions },
  { path: "/usecases/graphia-marketing" , component: Marketing},
  { path: "/usecases/graphia-sales" , component: Sales},
  { path: "/usecases/graphia-ecommerce" , component: Ecommerce},
  { path: "/pricing", component: Pricing},
  
  { path: "/templates", component: Templates},

  // blogs
  { path: "/blogs", component: Blog },
  { path: "/blogs/2024-04-15/revolutionizing-content-creation-Unlocking-power-of-ai", component: Blog1},
  { path: "/blogs/2024-04-22/mastering-chatgpt-comprehensive-guide-to-crafting-effective-prompts-for-conversational-ai", component: Blog2},
  { path: "/blogs/2024-04-27/decoding-the-anatomy-of-a-good-prompt-for-chatgpt-crafting-conversations-with-ai", component: Blog3},
  { path: "/blogs/2024-05-06/unveiling-graphias-latest-features-a-game-changer-for-creatives", component: Blog4},
  { path: "/blogs/2024-05-15/mastering-ai-voiceover-tips-for-natural-sounding-narration-on-graphia-ai", component: Blog5},
  { path: "/blogs/2024-05-21/exploring-graphias-cutting-edge-ai-chat-features", component: Blog6},
  {path: "/blogs/2024-09-04/how-to-use-ai-for-competitive-analysis-a-step-by-step-guide", component: Blog7},
  {path: "/blogs/2024-10-15/introducing-affiliate-program-for-graphia-earn-commissions", component: Blog8},

  //templates
  { path: "/templates/review-responder", component: ReviewResponderPage},
  { path: "/templates/article-blog-ideas", component: ArticleBlogIdeasPage},
  { path: "/templates/article-outlines", component: ArticleOutlinesPage},
  { path: "/templates/article-generator", component: ArticleGeneratorPage},
  { path: "/templates/article-intros", component: ArticleIntrosPage},
  { path: "/templates/blog-outline", component: BlogOutlinePage},
  { path: "/templates/blog-titles", component: BlogTitlesPage},
  { path: "/templates/blog-post-long-form", component: BlogPostLongFormPage},
  { path: "/templates/blog-post-short-form", component: BlogPostShortFormPage},
  { path: "/templates/blog-post-conclusion-paragraph", component: BlogPostConclusionParagraphPage},
  { path: "/templates/one-shot-blog-post", component: OneShotBlogPostPage},
  { path: "/templates/paragraph-generator", component: ParagraphGeneratorPage},
  { path: "/templates/conclusion-writer", component: ConclusionWriterPage},
  { path: "/templates/content-improver", component: ContentImproverPage},
  { path: "/templates/text-extender", component: TextExtenderPage},
  { path: "/templates/content-shortener", component: ContentShortenerPage},
  { path: "/templates/text-summarizer", component: TextSummarizerPage},
  { path: "/templates/story-writer", component: StoryWriterPage},
  { path: "/templates/personal-bio-writer", component: PersonalBioWriterPage},
  { path: "/templates/translator", component: TranslatorPage},
  { path: "/templates/bullet-point-answers", component: BulletPointAnswersPage},
  { path: "/templates/passive-to-active-voice", component: PassiveToActiveVoicePage},
  { path: "/templates/definition", component: DefinitionPage},
  { path: "/templates/answers", component: AnswersPage},
  { path: "/templates/pros-and-cons", component: ProsAndConsPage},
  { path: "/templates/questions", component: QuestionsPage},
  { path: "/templates/listicle", component: ListiclePage },
  { path: "/templates/tone-changer", component: ToneChangerPage },
  { path: "/templates/rewrite-with-keywords", component: RewriteWithKeywordsPage },
  { path: "/templates/explain-to-child", component: ExplainToChildPage },
  { path: "/templates/song-lyrics-generator", component: SongLyricsGeneratorPage },
  { path: "/templates/product-name-generator", component: ProductNameGeneratorPage },
  { path: "/templates/analogy-maker", component: AnalogyMakerPage },
  { path: "/templates/keywords-extractor", component: KeywordsExtractorPage },
  { path: "/templates/product-brochure", component: ProductBrochurePage },
  { path: "/templates/product-descriptions", component: ProductDescriptionsPage },
  { path: "/templates/content-rewriter", component: ContentRewriterPage },
  { path: "/templates/short-summary", component: ShortSummaryPage },
  { path: "/templates/facebook-ad", component: FacebookAdPage },
  { path: "/templates/facebook-ad-pas", component: FacebookAdPASPage },
  { path: "/templates/facebook-headlines", component: FacebookHeadlinesPage },
  { path: "/templates/facebook-video-script", component: FacebookVideoScriptPage },
  { path: "/templates/google-ads", component: GoogleAdsPage },
  { path: "/templates/linkedin-text-ads", component: LinkedInTextAdsPage },
  { path: "/templates/linkedin-single-image-ads", component: LinkedInSingleImageAdsPage },
  { path: "/templates/linkedin-ads", component: LinkedInAdsPage },
  { path: "/templates/linkedin-ad-headline", component: LinkedInAdHeadlinePage },
  { path: "/templates/app-sms-notifications", component: AppSMSNotificationsPage },
  { path: "/templates/outreach-sequence", component: OutreachSequencePage },
  { path: "/templates/industry-trends-analysis", component: IndustryTrendsAnalysisPage },
  { path: "/templates/pain-points-brainstorming", component: PainPointsBrainstormingPage },
  { path: "/templates/competitive-analysis", component: CompetitiveAnalysisPage },
  { path: "/templates/linkedin-boolean-search", component: LinkedInBooleanSearchPage },
  { path: "/templates/personalized-cold-email", component: PersonalizedColdEmailPage },
  { path: "/templates/linkedin-prospect-research", component: LinkedInProspectResearchPage },
  { path: "/templates/amazon-product-features", component: AmazonProductFeaturesPage },
  { path: "/templates/amazon-product-titles", component: AmazonProductTitlesPage },
  { path: "/templates/email-subject-generator", component: EmailSubjectGeneratorPage },
  { path: "/templates/features-advantages-benefits", component: FeaturesAdvantagesBenefitsPage },
  { path: "/templates/newsletter-recent-news", component: NewsletterRecentNewsPage },
  { path: "/templates/newsletter-inspiration", component: NewsletterInspirationPage },
  { path: "/templates/pain-agitate-solution", component: PainAgitateSolutionPage },
  { path: "/templates/backlink-outreach-email", component: BacklinkOutreachEmailPage },
  { path: "/templates/email-writer", component: EmailWriterPage },
  { path: "/templates/personalized-cold-emails", component: PersonalizedColdEmailsPage },
  { path: "/templates/website-sub-headline", component: WebsiteSubHeadlinePage },
  { path: "/templates/landing-page-headline-writer", component: LandingPageHeadlineWriterPage },
  { path: "/templates/call-to-action", component: CallToActionPage },
  { path: "/templates/services-page-title-meta-descriptions", component: ServicesPageTitleMetaDescriptionsPage },
  { path: "/templates/blog-post-title-meta-descriptions", component: BlogPostTitleMetaDescriptionsPage },
  { path: "/templates/product-page-title-meta-descriptions", component: ProductPageTitleMetaDescriptionsPage },
  { path: "/templates/homepage-title-meta-descriptions", component: HomepageTitleMetaDescriptionsPage },
  { path: "/templates/unique-value-propositions", component: UniqueValuePropositionsPage },
  { path: "/templates/landing-page-copy", component: LandingPageCopyPage },
  { path: "/templates/customer-case-study", component: CustomerCaseStudyPage },
  { path: "/templates/faq-generator", component: FAQGeneratorPage },
  { path: "/templates/headline-generator", component: HeadlineGeneratorPage },
  // Route Paths
  { path: "/templates/google-my-business-product-description", component: GMBProductDescriptionPage },
  { path: "/templates/tweet-machine", component: TweetMachinePage },
  { path: "/templates/pinterest-pin-title-description", component: PinterestPinTitleDescriptionPage },
  { path:"/templates/youtube-video-description", component:YouTubeVideoDescriptionPage},
  { path:"/templates/google-my-business-event-post", component:GMBEventPostPage}, 
  { path:"/templates/google-my-business-offer-post", component:GMBOfferPostPage}, 
  { path: "/templates/linkedin-post", component:LinkedInPostPage},
  { path:"/templates/tiktok-script", component:TikTokScriptPage},
  { path:"/templates/controversial-opinion-twitter-thread", component:ControversialOpinionTwitterThreadPage},
  { path: "/templates/headlines", component: HeadlinesPage },
  { path: "/templates/content-calendar", component: ContentCalendarPage },
  { path: "/templates/caption-generator", component: CaptionGeneratorPage },
  { path: "/templates/instagram-captions", component: InstagramCaptionsPage },
  { path:"/templates/amazon-product-descriptions", component:AmazonProductDescriptionsPage},

]

export default publicRoutes
