import OpenAI from '../assets/OpenAI_Logo.svg';
import Stability from '../assets/stability-ai-seeklogo.svg';
import Clipdrop from '../assets/Clipdrop.svg';
import ElevenLabs from '../assets/llElevenLabs.svg';
import Claude from '../assets/anthropic-seeklogo.svg';

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="mx-auto w-full max-w-xl lg:mx-0">
            <h2 className="text-6xl font-bold tracking-tight text-gray-900">All models in one place</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
		We bring to you all AI models under one umbrella. One tool helps you get all the desired content.
            </p>
            <div className="mt-8 flex items-center gap-x-6">
              <a
                href="https://app.graphia.ai"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
	        target="_blank"
              >
                Create account
              </a>
            </div>
          </div>
          <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
            <img
              className="max-h-12 object-contain object-left"
 	      src={OpenAI}
              alt="OpenAI"
              width={150}
              height={75}
            />
            <img
              className="max-h-12  object-contain object-left"
	      src={Stability}
              alt="Stability AI"
              width={150}
              height={75}
            />
            <img
              className="max-h-12  object-contain object-left"
	      src={ElevenLabs}
              alt="ElevenLabs"
              width={150}
              height={75}
            />
            <img
              className="max-h-12 object-contain object-left"
	      src={Clipdrop}
              alt="Clipdrop"
	      width={200}
              height={75}
            />
	    <img
              className="max-h-12 object-contain object-left"
              src={Claude}
              alt="Claude"
              width={150}
              height={75}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

