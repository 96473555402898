import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import EmailSubjectGeneratorInput from './assets/EmailSubjectGeneratorInput.png';
//import EmailSubjectGeneratorOutput from './assets/EmailSubjectGeneratorOutput.png';

const EmailSubjectGeneratorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Email Subject Generator</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Craft compelling email subject lines.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Attention-Grabbing:</strong> Create subject lines that compel recipients to open.</li>
            <li><strong>Personalized:</strong> Tailor subject lines to specific audiences or segments. </li>
            <li><strong>Variety:</strong> Generate a variety of subject lines for A/B testing.</li>
        </ul>
       </section>

       <section className="mb-8">
      <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
      <div>
        <h3 className="text-xl text-indigo-700 mb-2">1. Input Email Content</h3>
        <h3 className="text-xl text-indigo-700 mb-2">2. Generate Subject Lines</h3>
        <h3 className="text-xl text-indigo-700 mb-2">3. Choose and Use</h3>
        <p>Select the most suitable subject line for your email campaign.</p>
      </div>
    </section>

    <section className="mb-8">
      <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
      <ul className="list-disc list-inside">
        <li><strong>Higher Open Rates:</strong> Increase email open rates with compelling subject lines.</li>
        <li><strong>Improved Engagement:</strong> Encourage recipients to interact with your email.</li>
        <li><strong>Enhanced Brand Perception:</strong> Present your brand as professional and engaging.</li>
      </ul>
    </section>

    <section>
      <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
      <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
    </section>
  </div>
  <ElaborateFooter />
</>
);
};

export default EmailSubjectGeneratorPage;
