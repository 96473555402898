import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import AppSMSNotificationsInput from './assets/AppSMSNotificationsInput.png';
//import AppSMSNotificationsOutput from './assets/AppSMSNotificationsOutput.png';

const AppSMSNotificationsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">App and SMS Notifications</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Create notification messages for your apps, websites, and mobile devices that keep users coming back for more.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Notifications:</strong> Write compelling notifications that grab attention.</li>
            <li><strong>Customizable Templates:</strong> Tailor notification templates to fit your brand’s voice.</li>
            <li><strong>Performance Analytics:</strong> Monitor and improve notification performance.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Notification Details</h3>
            {/*<img src={AppSMSNotificationsInput} alt="Input Notification Details" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Notification</h3>
            {/*<img src={AppSMSNotificationsOutput} alt="Generated Notification" className="mb-4" /> */}
            <h3 className="text-xl text-indigo-700 mb-2">3. Optimize and Publish</h3>
            <p>Optimize your notifications with provided tips and publish them on your platform.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased User Engagement:</strong> Drive more engagement with your notifications.</li>
            <li><strong>Higher Retention Rates:</strong> Retain more users with effective notifications.</li>
            <li><strong>Better User Experience:</strong> Enhance user experience with timely notifications.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default AppSMSNotificationsPage;
