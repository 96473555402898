// src/components/HeroSection.js
import React from 'react';
import affiliates from "../assets/affiliates.png";

const HeroSection = () => {
  return (
    <header className="text-center py-12 bg-gray-900 bg-gradient-to-r to-[#4665e5] from-[#7746e5] text-white  shadow-md">
      <h1 className="text-5xl font-bold">Earn 15% Commission on Every Purchase</h1>
      <p className="mt-4 text-xl">Join our affiliate program and start earning money by inviting your friends!</p>
	 <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://app.graphia.ai"
                  className="rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-indigo-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                  target="_blank"
                >
                  Join the Affiliate Program
                </a>
              </div>

      <div className="mt-8">
        <img
          src={affiliates}
          alt="Affiliate dashboard example"
          className="w-3/4 mx-auto rounded-lg shadow-lg"
        />
      </div>
    </header>
  );
};

export default HeroSection;

