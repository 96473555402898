import Header from '../../../common/Header';
import ElaborateFooter from '../../../common/ElaborateFooter';

const NewsletterRecentNewsPage = () => {
    return (
      <>
        <Header />
        <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
          <h1 className="text-3xl text-indigo-600">Newsletter from Recent News</h1>
          
          <section className="mb-8 mt-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
            <p>This tool enables you to craft engaging newsletters by leveraging recent news topics and events. By incorporating timely and relevant content, you can capture the interest of your audience and enhance engagement with your brand.</p>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
            <ul className="list-disc list-inside">
              <li><strong>News Integration:</strong> Access the latest news articles and topics to include in your newsletter.</li>
              <li><strong>Customization:</strong> Tailor the newsletter content to match your brand's tone and style.</li>
            </ul>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
            <div>
              <h3 className="text-xl text-indigo-800 mb-2">1. Select News Topics</h3>
              <h3 className="text-xl text-indigo-800 mb-2">2. Customize Newsletter Content</h3>
              <p>Choose relevant news articles and craft engaging content for your newsletter.</p>
            </div>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
            <ul className="list-disc list-inside">
              <li><strong>Timeliness:</strong> Keep your audience informed about current events and trends.</li>
              <li><strong>Engagement:</strong> Increase reader engagement by providing valuable and up-to-date information.</li>
            </ul>
          </section>
  
          <section>
            <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
            <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
          </section>
  
        </div>
        <ElaborateFooter />
      </>
    );
  };
  
export default NewsletterRecentNewsPage;