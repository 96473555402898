// FacebookVideoScriptPage.js
import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const FacebookVideoScriptPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        {/* Add back button */}
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Facebook Video Script</h1>
        {/* Overview section */}
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft effective scripts for your Facebook video ads with the Facebook Video Script template. Create engaging and compelling video content that captures audience attention and drives conversions.</p>
        </section>
        {/* Key features section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Storytelling:</strong> Tell a captivating story that resonates with your audience.</li>
            <li><strong>Clear Call to Action:</strong> Prompt viewers to take action with a compelling call to action.</li>
            <li><strong>Visual Appeal:</strong> Create visually appealing content that keeps viewers engaged.</li>
          </ul>
        </section>
        {/* How It Works section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Define Your Message</h3>
            <p>Determine the key message or offer you want to convey in your video ad.</p>
            <h3 className="text-xl text-indigo-500 mb-2">2. Storyboard Your Video</h3>
            <p>Create a storyboard outlining the scenes and visuals for your video.</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Write the Script</h3>
            <p>Write a compelling script that complements your visuals and effectively communicates your message.</p>
          </div>
        </section>
        {/* Benefits section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Higher Engagement:</strong> Capture audience attention with engaging video content.</li>
            <li><strong>Increased Conversions:</strong> Prompt viewers to take action with clear call-to-action messages.</li>
            <li><strong>Enhanced Brand Awareness:</strong> Build brand recognition and loyalty through compelling storytelling.</li>
          </ul>
        </section>
        {/* Use Cases section */}
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Demonstrations:</strong> Showcase product features and benefits through visual demonstrations.</li>
            <li><strong>Customer Testimonials:</strong> Share customer success stories and testimonials to build trust.</li>
            <li><strong>Brand Storytelling:</strong> Tell your brand story in a compelling and memorable way.</li>
          </ul>
        </section>
        {/* Use This Template section */}
        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default FacebookVideoScriptPage;

