import React, { useState } from 'react';
import {CheckIcon} from '@heroicons/react/24/outline';

// Define data for pricing cards
const pricingCardsData = [
  /*{
    name: 'Free',
    description: 'Perfect for hobbyists',
    popular: false,
    price: {
      monthly: 0,
      yearly: 0, // 10% discount for yearly
    },
    creditsPerMonth: 150,
    features: [
      'AI Writer',
      'AI Chat',
      'Unlimited Workspace Members',
      'No Support',
    ],
  },*/
  {
    name: 'Basic',
    description: 'Perfect for Freelancers',
    popular: false,
    price: {
      monthly: 15,
      yearly: 150,
    },
    creditsPerMonth: 50000,
    features: [
      'AI Writer',
      'AI Image Generator',
      'AI Chat',
      'Unlimited Workspace Members',
      'Email Support',
    ],
  },
  {
    name: 'Advanced',
    description: 'Perfect for Small Businesses',
    popular: true,
    price: {
      monthly: 25,
      yearly: 250,
    },
    creditsPerMonth: 100000,
    features: [
      'AI Writer',
      'AI Image Generator',
      'AI Voiceover',
      'AI Chat',
      'Unlimited Workspace Members',
      'Email Support',
    ],
  },
  {
    name: 'Elite',
    description: 'Perfect for Growing Teams',
    popular: false,
    price: {
      monthly: 45,
      yearly: 450,
    },
    creditsPerMonth: 300000,
    features: [
	      'AI Writer',
        'AI Image Generator',
        'AI Voiceover',
        'AI Chat',
        'Unlimited Workspace Members',
 	      'Email & Chat Support',
    ],
  },
];

// Define data for comparison table
const comparisonTableData = [
 /* {
    name: 'Free',
    features: [
      { group: 'Credits', name: 'per month', value: '150' },
      { group: 'Workspace', name: 'members', value: 'Unlimited' },
      { group: 'Workspace', name: 'sharing & collaboration', value: true },
      { group: 'AI Chat', name: 'supported', value: true },
      { group: 'AI Chat', name: 'model(s)', value: 'GPT3.5 Turbo, GPT4o, GPT4, GPT4 Turbo' },
      { group: 'AI Writer', name: 'supported', value: true },
      { group: 'AI Writer', name: 'model(s)', value: 'GPT 4o' },
      { group: 'AI Image Generator', name: 'supported', value: false },
      { group: 'AI Image Generator', name: 'model(s)', value: '-' },
      { group: 'AI Voiceover', name: 'supported', value: false },
      { group: 'AI Voiceover', name: 'model(s)', value: '-' },
      { group: 'Support', name: 'included', value: false },
      { group: 'Support', name: 'medium', value: '-' },
      { group: 'Support', name: 'response time', value: '-' },
    ],
  },*/
  {
    name: 'Basic',
       features: [
      { group: 'Free Trial', name: 'Days', value: '1' },
      { group: 'Credits', name: 'per month', value: '15000' },
      { group: 'Workspace', name: 'members', value: 'Unlimited' },
      { group: 'Workspace', name: 'sharing & collaboration', value: true },
      { group: 'AI Writer', name: 'supported', value: true },
      { group: 'AI Writer', name: 'model(s)', value: 'GPT4o' },
      { group: 'AI Chat', name: 'supported', value: true },
      { group: 'AI Chat', name: 'model(s)', value: 'GPT3.5 Turbo, GPT4o, GPT4, GPT4 Turbo'},
      { group: 'AI Image Generator', name: 'supported', value: true },
      { group: 'AI Image Generator', name: 'model(s)', value: 'DALLE-2, DALLE-3' },
      { group: 'AI Voiceover', name: 'supported', value: false },
      { group: 'AI Voiceover', name: 'model(s)', value: '-' },
      { group: 'Support', name: 'included', value: false },
      { group: 'Support', name: 'medium', value: 'Email' },
      { group: 'Support', name: 'response time', value: '5-7 business days' },
    ],


  },
  {
    name: 'Advanced',
      features: [
      { group: 'Free Trial', name: 'Days', value: '1' },
      { group: 'Credits', name: 'per month', value: '70000' },
      { group: 'Workspace', name: 'members', value: 'Unlimited' },
      { group: 'Workspace', name: 'sharing & collaboration', value: true },
      { group: 'AI Writer', name: 'supported', value: true },
      { group: 'AI Writer', name: 'model(s)', value: 'GPT4o' },
      { group: 'AI Chat', name: 'supported', value: true },
      { group: 'AI Chat', name: 'model(s)', value: 'GPT3.5 Turbo, GPT4o, GPT4, GPT4 Turbo'},
      { group: 'AI Image Generator', name: 'supported', value: true },
      { group: 'AI Image Generator', name: 'model(s)', value: 'DALLE-2, DALLE-3' },
      { group: 'AI Voiceover', name: 'supported', value: true },
      { group: 'AI Voiceover', name: 'model(s)', value: 'OpenAI, ElevenLabs' },
      { group: 'Support', name: 'included', value: false },
      { group: 'Support', name: 'medium', value: 'Email' },
      { group: 'Support', name: 'response time', value: '2-3 business days' },
    ],


  },
  {
    name: 'Elite',
     features: [
      { group: 'Free Trial', name: 'Days', value: '1' },
      { group: 'Credits', name: 'per month', value: '300000' },
      { group: 'Workspace', name: 'members', value: 'Unlimited' },
      { group: 'Workspace', name: 'sharing & collaboration', value: true },
      { group: 'AI Writer', name: 'supported', value: true },
      { group: 'AI Writer', name: 'model(s)', value: 'GPT4o' },
      { group: 'AI Chat', name: 'supported', value: true },
      { group: 'AI Chat', name: 'model(s)', value: 'GPT3.5 Turbo, GPT4o, GPT4, GPT4 Turbo'},
      { group: 'AI Image Generator', name: 'supported', value: true },
      { group: 'AI Image Generator', name: 'model(s)', value: 'DALLE-2, DALLE-3,Stable Diffusion SDXL, Stable Diffusion SD1.6, Clipdrop' },
      { group: 'AI Voiceover', name: 'supported', value: true },
      { group: 'AI Voiceover', name: 'model(s)', value: 'OpenAI, ElevenLabs' },
      { group: 'Support', name: 'included', value: false },
      { group: 'Support', name: 'medium', value: 'Email & Chat' },
      { group: 'Support', name: 'response time', value: '1 business day' },
    ],


  },
];

const PricingPage = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const togglePricing = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <div className="container mx-auto py-12 px-4">
      {/* Toggle Button for Yearly and Monthly */}
      <div className="flex justify-center items-center space-x-4 mb-8">
        <span className={`text-gray-700 ${isMonthly ? 'font-semibold' : ''}`}>Monthly</span>
        <div className="relative inline-block w-14 h-8 mr-2 align-middle select-none transition duration-200 ease-in">
          <input type="checkbox" id="toggle" name="toggle" className={`toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer ${!isMonthly ? 'right-0' : ''}`} checked={isMonthly} onChange={togglePricing} />
          <label htmlFor="toggle" className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${isMonthly ? 'bg-gray-300' : 'bg-blue-500 bg-gradient-to-r  to-[#4665e5] from-[#7746e5]'}`}></label>
        </div>
        <span className={`text-gray-700 ${isMonthly ? '' : 'font-semibold'}`}>Yearly</span>
      </div>

      {/* Pricing Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        {pricingCardsData.map((plan, index) => (
          <div key={index} className={`bg-white rounded-xl shadow-lg overflow-hidden hover:border-blue-500 border border-transparent transition duration-300`}>
            <div className="px-6 py-4">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">{plan.name}</h2>
              <p className="text-sm text-gray-600 mb-2">{plan.description}</p>
	    <h3 className="text-xl text-gray-800 font-bold">
  {isMonthly ? `$${plan.price.monthly}/month` : `$${plan.price.yearly}/year`} 
  {isMonthly ? null : plan.price.monthly !== 0 && plan.price.yearly !== 0 && plan.price.yearly !== plan.price.monthly ? (
    <span className="text-sm text-[#4665e5] ml-1">   {(12 * plan.price.monthly - plan.price.yearly) * 100 / (plan.price.yearly !== 0 ? plan.price.yearly : 1)}% off
    </span>
  ) : null}
</h3>



              <hr className="my-4" />
              <p className="text-sm text-gray-600 mb-2">Upto  <span className="font-bold">{plan.creditsPerMonth}</span> credits per month</p>
              <ul className="text-gray-700">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center">
		    <span className="mr-2 text-purple-600">&#10003;</span>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="px-6 py-4">
              <a href={`https://app.graphia.ai/?type=plans&plan=${plan.name}`} className={`py-2 px-4 rounded ${plan.popular === true ? 'bg-blue-500 bg-gradient-to-r  to-[#4665e5] from-[#7746e5] text-white font-bold' : 'border border-gray-300 text-gray-700 hover:text-gray-900 hover:bg-gray-100'}`} target="_blank">Choose Plan</a>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center py-8">
     	<h2 className="text-2xl font-bold text-gray-800 mb-2">Plan Comparison</h2>
     </div>

      {/* Comparison Table */}
      <div className="overflow-x-auto">
        <table className="w-full table-auto mb-8">
          <thead>
            <tr>
              <th className="px-4 py-2 font-semibold text-gray-800 border-r">Features</th>
              {comparisonTableData.map((plan, index) => (
                <th key={index} className="px-4 py-2 font-semibold text-gray-800 border-r">{plan.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(comparisonTableData[0].features.reduce((groups, feature) => ({...groups, [feature.group]: true}), {})).map(group => (
              <React.Fragment key={group}>
                <tr>
                  <td colSpan={comparisonTableData.length + 1} className="px-4 py-2 font-semibold text-gray-800">{group}</td>
                </tr>
		{comparisonTableData[0].features.filter(f => f.group === group).map((feature, featureIndex) => (
  <tr key={featureIndex} className="text-center">
    <td className="px-4 py-2 border-r">{feature.name}</td>
    {comparisonTableData.map((plan, planIndex) => (
      <td key={planIndex} className="px-4 py-2 border-r text-center">
        {typeof feature.value === 'boolean' ? (
          <div className={`h-6 w-6 flex items-center justify-center mx-auto`}>
            {plan.features.find(f => f.name === feature.name && f.group === group).value === true ? (<span className="mr-2 text-purple-600">&#10003;</span>) : (<span>-</span>)
            }
          </div>
        ) : (
	          <span>{plan.features.find(f => f.name === feature.name && f.group === group).value.includes(',') ? plan.features.find(f => f.name === feature.name && f.group === group).value.split(',').map((item, index) => <div key={index}>{item}</div>) : plan.features.find(f => f.name === feature.name && f.group === group).value}</span>

        )}
      </td>
    ))}
  </tr>
))}

              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PricingPage;

