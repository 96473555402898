import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import CompetitiveAnalysisInput from './assets/CompetitiveAnalysisInput.png';
//import CompetitiveAnalysisOutput from './assets/CompetitiveAnalysisOutput.png';

const CompetitiveAnalysisPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Competitive Analysis</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Conduct a comprehensive analysis of your competitors. Identify the competitive advantages and disadvantages of their product.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Thorough Analysis:</strong> Evaluate competitors’ strengths and weaknesses.</li>
            <li><strong>Market Positioning:</strong> Understand your position in the market.</li>
            <li><strong>Actionable Insights:</strong> Gain insights to improve your strategy.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Competitor Details</h3>
            {/*<img src={CompetitiveAnalysisInput} alt="Input Competitor Details" className="mb-4" />*/}
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Analysis Report</h3>
            {/*<img src={CompetitiveAnalysisOutput} alt="Generated Analysis Report" className="mb-4" /> */}
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Strategize</h3>
            <p>Review the analysis and develop strategies to enhance your competitive edge.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Informed Decisions:</strong> Make data-driven decisions.</li>
            <li><strong>Market Insight:</strong> Gain a deeper understanding of the market landscape.</li>
            <li><strong>Competitive Advantage:</strong> Identify opportunities to outperform competitors.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default CompetitiveAnalysisPage;
