import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ParagraphGeneratorInput from './assets/ParagraphGeneratorInput.png';
import ParagraphGeneratorOutput from './assets/ParagraphGeneratorOutput.png';

const ParagraphGeneratorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Paragraph Generator</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate paragraphs that will captivate your readers. This tool helps you create well-structured and engaging paragraphs to enhance your content's readability and impact.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Content:</strong> Generate paragraphs that capture and retain readers' attention.</li>
            <li><strong>SEO Optimization:</strong> Ensure your paragraphs are optimized for search engines.</li>
            <li><strong>Customizable:</strong> Tailor the paragraphs to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={ParagraphGeneratorInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Paragraphs</h3>
            <img src={ParagraphGeneratorOutput} alt="Generated Paragraphs" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated paragraphs to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Improved Readability:</strong> Create clear and engaging paragraphs for your content.</li>
            <li><strong>Time-Saving:</strong> Quickly generate well-structured paragraphs.</li>
            <li><strong>SEO Benefits:</strong> Enhance your content's search engine visibility with optimized paragraphs.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Create engaging paragraphs for your blog posts.</li>
            <li><strong>Content Creators:</strong> Enhance the readability of your content with well-structured paragraphs.</li>
            <li><strong>Marketers:</strong> Develop captivating paragraphs to improve audience engagement.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ParagraphGeneratorPage;
