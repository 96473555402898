
import Header from '../common/Header.js';
import HeroSection from './components/HeroSection.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import CTA from './components/CTA.js';
import Cookies from '../common/Cookies.js';
import React from 'react';
import Testimonials from './components/Testimonials.js';
import Feature1 from './components/Feature1.js';
import Feature2 from './components/Feature2.js';
import Feature3 from './components/Feature3.js';
import FeatureHeadline from './components/FeatureHeadline.js';
import Stats from './components/Stats.js';
import Caraousel from './components/Caraousel.js';

function LandingPage() {
 return (
   <React.Fragment>
      <Header/>
      <HeroSection/>	
	<Stats/>
	<Caraousel/>
	<Feature3/>
      <Testimonials/>
      <CTA/>
      <Cookies/>
      <ElaborateFooter/>
  </React.Fragment>
)
}

export default LandingPage;
