import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import PinterestPinInput from './assets/PinterestPinInput.png';
//import PinterestPinOutput from './assets/PinterestPinOutput.png';

const PinterestPinTitleDescriptionPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Pinterest Pin Title & Description</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Create captivating Pinterest pin titles and descriptions that drive engagement, traffic, and reach.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO Optimized:</strong> Ensure your pin titles and descriptions are optimized for search visibility.</li>
            <li><strong>Engaging Content:</strong> Craft engaging and informative titles and descriptions that attract clicks.</li>
            <li><strong>Easy to Use:</strong> Simply input your pin details and generate professional titles and descriptions.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Pin Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Titles & Descriptions</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Use Titles & Descriptions</h3>
            <p>Copy the generated titles and descriptions and add them to your Pinterest pins.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Visibility:</strong> Improve your pin's visibility and attract more clicks.</li>
            <li><strong>Enhanced Engagement:</strong> Create compelling titles and descriptions that engage potential viewers.</li>
            <li><strong>Professional Quality:</strong> Generate professional-quality titles and descriptions effortlessly.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Creators:</strong> Enhance your Pinterest pins with engaging titles and descriptions.</li>
            <li><strong>Businesses:</strong> Improve your product visibility and engagement on Pinterest.</li>
            <li><strong>Marketers:</strong> Optimize your Pinterest strategy with SEO-friendly titles and descriptions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default PinterestPinTitleDescriptionPage;
