import React from 'react';

const BlogPost = () => {
  return (
    <div className="max-w-3xl mx-auto p-5">
      <h1 className="text-3xl font-bold mb-4">Introducing the Graphia AI Affiliate Program</h1>
      <p className="text-lg mb-6">
        We’re excited to announce the launch of the Graphia AI Affiliate Program! Now you can earn a 15% commission on every purchase made by your referrals. Whether you’re a blogger, influencer, or just someone who loves sharing great products, this program is designed for you. Join us in spreading the word about Graphia AI and start earning money by inviting your friends!
      </p>

      <h2 className="text-2xl font-bold mb-4">How It Works</h2>
      <p className="mb-4">
        Participating in our affiliate program is straightforward and rewarding. Here’s how it works:
      </p>
      <ol className="list-decimal list-inside mb-6">
        <li className="mb-2">
          <strong>Step 1:</strong> Invite Your Friends - Share your personalized referral link with friends, family, or your audience through social media, email, or your blog.
        </li>
        <li className="mb-2">
          <strong>Step 2:</strong> They Sign Up - When your friends sign up using your link, they’ll be connected to your account, allowing us to track their purchases accurately.
        </li>
        <li className="mb-2">
          <strong>Step 3:</strong> You Earn Commission - For every purchase your referrals make, you’ll receive a 15% commission, and this continues indefinitely for all their future purchases!
        </li>
      </ol>

      <h2 className="text-2xl font-bold mb-4">Why Join Our Affiliate Program?</h2>
      <p className="mb-4">
        Our affiliate program is designed to be beneficial for everyone involved. Here are some compelling reasons to join:
      </p>
      <ul className="list-disc list-inside mb-6">
        <li className="mb-2">
          <strong>Unlimited Earnings:</strong> The more you refer, the more you earn! There’s no cap on how much you can make; the earning potential is entirely in your hands.
        </li>
        <li className="mb-2">
          <strong>Easy to Use:</strong> Our platform makes it simple to share your referral link. You can copy your link from your affiliate dashboard and distribute it however you choose.
        </li>
        <li className="mb-2">
          <strong>No Cap on Commissions:</strong> Earn a percentage on all purchases, regardless of the total amount. This means the more your referrals spend, the more you earn!
        </li>
        <li className="mb-2">
          <strong>Flexible Payouts:</strong> You’ll receive your earnings quickly and easily once you reach the payout threshold. We ensure that getting paid is hassle-free.
        </li>
      </ul>

      <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
      <h3 className="font-semibold mb-2">How do I share my referral link?</h3>
      <p className="mb-4">
        Sharing your referral link is easy! Simply log into your affiliate dashboard, copy your unique link, and share it across your social media platforms, email, or personal website. The more you share, the more potential referrals you can attract!
      </p>

      <h3 className="font-semibold mb-2">When will I receive payouts?</h3>
      <p className="mb-4">
        Payouts are processed on a monthly basis. Once you reach the specified payout threshold, your earnings will be transferred directly to your designated account. We aim to make this process as seamless as possible so you can focus on sharing and earning.
      </p>

      <h3 className="font-semibold mb-2">Is there a limit to how much I can earn?</h3>
      <p className="mb-4">
        No, there is absolutely no limit to your earnings! The program is structured to reward your efforts; the more referrals you bring in, the more you earn. There’s a world of opportunity waiting for you.
      </p>

      <h3 className="font-semibold mb-2">Can I track my referrals and earnings?</h3>
      <p className="mb-4">
        Yes! You can easily monitor your performance through the affiliate dashboard. Here, you’ll have access to real-time data showing clicks, signups, and earnings. This transparency helps you optimize your referral strategy.
      </p>

      <h2 className="text-2xl font-bold mb-4">Start Earning Today!</h2>
      <p className="mb-4">
        We invite you to join the Graphia AI Affiliate Program and take advantage of this exciting opportunity to earn commissions while sharing a product you believe in. Whether you have a large audience or just a few friends, your efforts can lead to significant rewards.
      </p>
      <p>
        Get your referral link now and start earning 15% on every purchase your friends make! We look forward to welcoming you to our affiliate family!
      </p>
    </div>
  );
};

export default BlogPost;

