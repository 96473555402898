
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import React from 'react';
import HeroSection from './components/HeroSection';
import HowItWorks from './components/HowItWorks';
import WhyJoin from './components/WhyJoin';
import FAQs from './components/FAQs';
import CallToAction from './components/CTA';

const AffiliateLandingPage = () => {
  return (
    <>
      <Header/>
      <HeroSection />
      <HowItWorks />
      <WhyJoin />
      <FAQs />
      <CallToAction />
      <ElaborateFooter/>
    </>
  );
};

export default AffiliateLandingPage;


