import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import EmailWriterInput from './assets/EmailWriterInput.png';
//import EmailWriterOutput from './assets/EmailWriterOutput.png';

const EmailWriterPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Email Writer</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Email Writer tool provides users with professionally crafted email templates designed to engage audiences and drive results. Whether you need to reach out to leads, nurture prospects, or communicate with existing customers, this tool offers a range of customizable templates to suit various email marketing needs.</p>
</section>
<section className="mb-8">
      <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
      <ul className="list-disc list-inside">
        <li><strong>Template Variety:</strong> Choose from a selection of email templates tailored to different purposes, such as lead generation, product announcements, or event invitations.</li>
        <li><strong>Customization:</strong> Personalize templates with your brand's logo, colors, and messaging to maintain consistency across all communications.</li>
        <li><strong>Responsive Design:</strong> Ensure that emails are optimized for various devices and screen sizes, enhancing readability and user experience.</li>
      </ul>
    </section>

    <section className="mb-8">
      <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
      <div>
        <h3 className="text-xl text-indigo-800 mb-2">1. Choose Template</h3>
        <p>Select a template that aligns with your email marketing goals and target audience.</p>
        <h3 className="text-xl text-indigo-800 mb-2">2. Customize Content</h3>
        <p>Personalize the content of the template to reflect your brand voice and convey your message effectively.</p>
        <h3 className="text-xl text-indigo-800 mb-2">3. Send or Schedule</h3>
        <p>Send the email immediately or schedule it to be sent at a later time for optimal delivery.</p>
      </div>
    </section>

    <section className="mb-8">
      <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
      <ul className="list-disc list-inside">
        <li><strong>Time-Saving:</strong> Eliminate the need to create emails from scratch with ready-to-use templates.</li>
        <li><strong>Consistency:</strong> Maintain a consistent brand identity across all email communications with professionally designed templates.</li>
        <li><strong>Engagement:</strong> Increase engagement rates and drive conversions with compelling email content.</li>
        <li><strong>Scalability:</strong> Scale your email marketing efforts efficiently with pre-designed templates that cater to different campaigns and audiences.</li>
      </ul>
    </section>

    <section>
      <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
      <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
    </section>
  </div>
  <ElaborateFooter />
</>
);
};

export default EmailWriterPage;
