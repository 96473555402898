import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import InstagramCaptionsInput from './assets/InstagramCaptionsInput.png';
//import InstagramCaptionsOutput from './assets/InstagramCaptionsOutput.png';

const InstagramCaptionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Instagram Captions</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft engaging and relatable Instagram captions that highlight your product's uniqueness and connect with your target audience. With this tool, you can create captions that resonate with your followers and drive engagement on your Instagram posts.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engagement:</strong> Create captions that encourage interaction and conversation with your audience.</li>
            <li><strong>Authenticity:</strong> Craft captions that reflect your brand's personality and values to build authentic connections.</li>
            <li><strong>Emotion:</strong> Evoke emotions and inspire action through captivating and relatable captions.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Content Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Captions</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Choose the Best Caption</h3>
            <p>Select the most engaging caption that resonates with your audience and enhances your Instagram post.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Drive more likes, comments, and shares with captivating captions.</li>
            <li><strong>Brand Identity:</strong> Strengthen your brand identity and voice with consistent and relatable captions.</li>
            <li><strong>Community Building:</strong> Foster a sense of community and connection with your audience through meaningful captions.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>E-commerce Brands:</strong> Showcase products and promotions with engaging captions that drive sales.</li>
            <li><strong>Influencers:</strong> Connect with followers and grow your audience with relatable and authentic captions.</li>
            <li><strong>Small Businesses:</strong> Build brand awareness and loyalty through creative and engaging Instagram captions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default InstagramCaptionsPage;
