import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import OneShotBlogPostInput from './assets/OneShotBlogPostInput.png';
import OneShotBlogPostOutput from './assets/OneShotBlogPostOutput.png';

const OneShotBlogPostPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">One-Shot Blog Post</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Generate a full blog post with an introduction, body, and conclusion. This tool helps you create comprehensive blog posts effortlessly, saving you time and ensuring high-quality content.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Complete Blog Posts:</strong> Generate an entire blog post in one go.</li>
            <li><strong>SEO Optimization:</strong> Ensure your blog posts are optimized for search engines.</li>
            <li><strong>Customizable:</strong> Tailor the content to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={OneShotBlogPostInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Content</h3>
            <img src={OneShotBlogPostOutput} alt="Generated Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated content to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Time-Saving:</strong> Create complete blog posts quickly and efficiently.</li>
            <li><strong>High-Quality Content:</strong> Ensure your blog posts are well-structured and engaging.</li>
            <li><strong>SEO Benefits:</strong> Improve search engine rankings with optimized content.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Generate full blog posts effortlessly.</li>
            <li><strong>Content Creators:</strong> Create comprehensive blog posts for various topics.</li>
            <li><strong>Marketers:</strong> Develop engaging blog posts to drive traffic and engagement.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default OneShotBlogPostPage;
