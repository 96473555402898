import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ExplainToChildInput from './assets/ExplainToChildInput.png';
import ExplainToChildOutput from './assets/ExplainToChildOutput.png';

const ExplainToChildPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Explain to Child</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Rephrase text to make it easier to read and understand. This tool simplifies complex language and concepts, making content more accessible to a wider audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Simplified Language:</strong> Transform complex language into easy-to-understand terms.</li>
            <li><strong>Clarity:</strong> Enhance readability and comprehension for all audiences.</li>
            <li><strong>Accessibility:</strong> Make content accessible to a broader range of readers, including children and those with limited language proficiency.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Text</h3>
            <img src={ExplainToChildInput} alt="Input Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Simplified Version</h3>
            <img src={ExplainToChildOutput} alt="Generated Simplified Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Review and Edit</h3>
            <p>Review the simplified text and make any necessary adjustments to ensure clarity and accuracy.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Accessibility:</strong> Reach a wider audience by making content understandable to all readers.</li>
            <li><strong>Clarity:</strong> Improve communication and comprehension by simplifying complex language.</li>
            <li><strong>Engagement:</strong> Increase reader engagement and retention with clear and accessible content.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Educational Materials:</strong> Adapt educational content for younger audiences or those with learning disabilities.</li>
            <li><strong>Technical Documents:</strong> Translate technical jargon into layman's terms for broader comprehension.</li>
            <li><strong>Legal and Policy Documents:</strong> Simplify legal language to ensure understanding by the general public.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ExplainToChildPage;

