import React from 'react';
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import CTA from './components/CTA.js';
import WebContent from './assets/website-content.svg';
import Blogs from './assets/blogs.svg';
import Ads from './assets/ads.svg';
import { CheckBadgeIcon } from '@heroicons/react/20/solid'

const ads = [
  {
    name: 'Ad Copies',
    description: 'Templates for headlines, CTAs, descriptions & more',
    icon: CheckBadgeIcon,
  },
  {
    name: 'App & SMS Notifications',
    description: 'Engaging content that keep users coming back for more',
    icon: CheckBadgeIcon,
  },
  {
    name: 'All Platforms',
    description: 'Supports LinkedIn, Facebook, Instagram, Google, Tiktok etc',
    icon: CheckBadgeIcon,
  },
]

const blogs = [
  {
    name: 'All Blog Content',
    description: 'Blog outline, titles, summaries, conclusions - we have everything in our arsenal',
    icon: CheckBadgeIcon,
  },
  {
    name: 'SEO Compatible Content',
    description: 'We make sure that all the content is SEO compatible.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Long & Short Forms',
    description: 'Long form or short form, take your pick',
    icon: CheckBadgeIcon,
  },
]

const websites = [
	{ 
    name: 'Catchy Headlines',
    description: 'Generate compelling headlines, subhealines & CTAs',
    icon: CheckBadgeIcon,
  },
  { 
    name: 'Sexy middle',
    description: 'You can also generate all the middle parts of your website',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Strong Conclusions',
    description: '... and give your websites strong conclusions',
    icon: CheckBadgeIcon,
  },
]


export default function Marketing() {
  return (
  <React.Fragment>
    <Header/>
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-indigo-600">Marketing</p>
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Boost marketing ROI <br/> with AI content.</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">Discover unmatched marketing prowess! Compose, advertise, and promote with unmatched rapidity.
        </p>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Ads</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Engaging Ads. <br/>Increased Conversions</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Craft ads easily! Reach wider audiences. Stand out and drive conversions.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {ads.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
	    src={Ads}
            alt="Ads"
            className="w-[36rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem] md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Blogs & SEO</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">One Platform. <br/> All Kinds of Blogs</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Pick from diverse templates to create articles, blogs, social posts, and more – hassle-free
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {blogs.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={Blogs}
              alt="Blogs"
              className="w-[36rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem]"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Website Content</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Copy that converts. <br/>Ready in minutes.</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		CTAs, headlines, landing page copy, headlines, customer case studies, etc. You name it, we have it.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {websites.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={WebContent}
            alt="Website Content"
            className="w-[36rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem] md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
    </div>
   <CTA/>
    <ElaborateFooter/>
 </React.Fragment>
  )
}
 
