import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import ContentCalendarInput from './assets/ContentCalendarInput.png';
//import ContentCalendarOutput from './assets/ContentCalendarOutput.png';

const GenerateContentCalendarPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button           onClick={() => window.location.href = '/templates'}
            className="text-indigo-800 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-800">Generate Content Calendar</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Create a well-structured content calendar to effectively plan and manage your social media content. This tool helps you organize your content ideas, schedule posts, and maintain consistency across platforms.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Structured Planning:</strong> Organize content ideas and schedule posts in a calendar format.</li>
            <li><strong>Consistency:</strong> Ensure regular posting and maintain a cohesive content strategy.</li>
            <li><strong>Collaboration:</strong> Share the content calendar with team members for seamless collaboration.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-400 mb-2">1. Plan Your Content</h3>
            <h3 className="text-xl text-indigo-400 mb-2">2. Schedule Posts</h3>
            <h3 className="text-xl text-indigo-400 mb-2">3. Collaborate</h3>
            <p>Share the content calendar with team members and coordinate posting schedules.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Efficiency:</strong> Streamline your content planning process and save time.</li>
            <li><strong>Consistency:</strong> Maintain a consistent posting schedule for better audience engagement.</li>
            <li><strong>Organization:</strong> Keep track of content ideas, publication dates, and posting platforms.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Marketing Teams:</strong> Plan and coordinate social media campaigns and promotions.</li>
            <li><strong>Content Creators:</strong> Schedule blog posts, videos, and other content for optimal reach.</li>
            <li><strong>Businesses:</strong> Maintain a consistent brand presence across social media platforms.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default GenerateContentCalendarPage;

           
