import React, { useState, useRef, useEffect } from 'react';
import { PhotoIcon, MicrophoneIcon, PencilIcon } from '@heroicons/react/24/outline';
import Feature1 from '../assets/AIWriter.mp4';
import Feature2 from '../assets/AIImageGenerator.mp4';
import Feature3 from '../assets/AIVoice.mp4';
import Feature4 from '../assets/AIChat.mp4';

const Carousel = () => {
    const [activeTab, setActiveTab] = useState('tab1');
    const [videoHeight, setVideoHeight] = useState('auto');
    const videoRefs = {
        tab1: useRef(null),
        tab2: useRef(null),
        tab3: useRef(null),
        tab4: useRef(null)
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setVideoHeight('600px');
            } else {
                setVideoHeight('auto');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial call to set height based on window width
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openTab = (tabName) => {
        setActiveTab(tabName);
        // Reset video to start when tab is clicked
        if (videoRefs[tabName].current) {
            videoRefs[tabName].current.currentTime = 0;
            videoRefs[tabName].current.play();
        }
    };

    return (
        <div className="flex flex-col items-center min-h-screen bg-white px-4">
            <div className="w-full mt-10 max-w-7xl text-center text-gray-800">
                {/* Header */}
                <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-4">All in one platform</h1>
                
                {/* Subheader */}
                <p className="mt-0 mb-8 text-lg md:text-xl leading-8 md:leading-10">Enjoy creating text, image and voice content, all in a single platform. Explore multiple models.</p>
                
                {/* Tab buttons */}
                <div className="flex flex-wrap justify-center mb-10">
                    <button className={`m-2 relative flex flex-col items-center py-2 px-4 sm:py-4 sm:px-6 rounded-lg border ${activeTab === 'tab1' ? 'text-white bg-gradient-to-br from-[#5564ef] to-[#7c4df0]' : 'text-gray-800 bg-white'}`} onClick={() => openTab('tab1')}>
                        <span className={`${activeTab === 'tab1' ? 'font-bold' : ''}`}>AI Chat</span>
                    </button>
                    <button className={`m-2 relative flex flex-col items-center py-2 px-4 sm:py-4 sm:px-6 rounded-lg border ${activeTab === 'tab4' ? 'text-white bg-gradient-to-br from-[#5564ef] to-[#7c4df0]' : 'text-gray-800 bg-white'}`} onClick={() => openTab('tab4')}>
                        <span className={`${activeTab === 'tab4' ? 'font-bold' : ''}`}>AI Image Generator</span>
                    </button>
                    <button className={`m-2 relative flex flex-col items-center py-2 px-4 sm:py-4 sm:px-6 rounded-lg border ${activeTab === 'tab2' ? 'text-white bg-gradient-to-br from-[#5564ef] to-[#7c4df0]' : 'text-gray-800 bg-white'}`} onClick={() => openTab('tab2')}>
                        <span className={`${activeTab === 'tab2' ? 'font-bold' : ''}`}>AI Voice Generator</span>
                    </button>
                    <button className={`m-2 relative flex flex-col items-center py-2 px-4 sm:py-4 sm:px-6 rounded-lg border ${activeTab === 'tab3' ? 'text-white bg-gradient-to-br from-[#5564ef] to-[#7c4df0]' : 'text-gray-800 bg-white'}`} onClick={() => openTab('tab3')}>
                        <span className={`${activeTab === 'tab3' ? 'font-bold' : ''}`}>AI Writing Tools</span>
                    </button>
                </div>

                {/* Tab content */}
                <div className="flex justify-center">
                    <div className={`transition-opacity duration-500 ${activeTab === 'tab1' ? 'opacity-100' : 'opacity-0 hidden'}`}>
                        <video ref={videoRefs.tab1} className="w-full rounded-xl" style={{ height: videoHeight, filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))' }} autoPlay muted loop>
                            <source src={Feature4} type="video/mp4" />
                        </video>
                    </div>
                    <div className={`transition-opacity duration-500 ${activeTab === 'tab4' ? 'opacity-100' : 'opacity-0 hidden'}`}>
                        <video ref={videoRefs.tab4} className="w-full rounded-xl" style={{ height: videoHeight, filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))' }} autoPlay muted loop>
                            <source src={Feature2} type="video/mp4" />
                        </video>
                    </div>
                    <div className={`transition-opacity duration-500 ${activeTab === 'tab2' ? 'opacity-100' : 'opacity-0 hidden'}`}>
                        <video ref={videoRefs.tab2} className="w-full rounded-xl" style={{ height: videoHeight, filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))' }} autoPlay muted loop>
                            <source src={Feature3} type="video/mp4" />
                        </video>
                    </div>
                    <div className={`transition-opacity duration-500 ${activeTab === 'tab3' ? 'opacity-100' : 'opacity-0 hidden'}`}>
                        <video ref={videoRefs.tab3} className="w-full rounded-xl" style={{ height: videoHeight, filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.5))' }} autoPlay muted loop>
                            <source src={Feature1} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carousel;

