import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import AmazonProductDescriptionsInput from './assets/AmazonProductDescriptionsInput.png';
//import AmazonProductDescriptionsOutput from './assets/AmazonProductDescriptionsOutput.png';

const AmazonProductDescriptionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button className="text-indigo-800 mb-4" onClick={() => window.history.back()}>&larr; Back to templates</button>
        <h1 className="text-3xl text-indigo-800">Amazon Product Descriptions</h1>

        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Descriptions for Amazon products that rank on the first page of the search results.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>SEO-Friendly:</strong> Optimize descriptions for better search visibility.</li>
            <li><strong>Engaging Content:</strong> Write compelling descriptions to attract customers.</li>
            <li><strong>Clear and Informative:</strong> Communicate product details effectively.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Input Product Details</h3>
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Product Descriptions</h3>
            <h3 className="text-xl text-indigo-700 mb-2">3. Review and Use</h3>
            <p>Review the generated descriptions and optimize them for your Amazon listings.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Higher Rankings:</strong> Improve search ranking with optimized descriptions.</li>
            <li><strong>Increased Sales:</strong> Convert more visitors into buyers with compelling content.</li>
            <li><strong>Enhanced Product Visibility:</strong> Attract more customers with engaging descriptions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default AmazonProductDescriptionsPage;
