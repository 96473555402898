import Header from '../../../common/Header';
import ElaborateFooter from '../../../common/ElaborateFooter';

const PainAgitateSolutionPage = () => {
    return (
      <>
        <Header />
        <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
          <h1 className="text-3xl text-indigo-600">Pain-Agitate-Solution</h1>
          
          <section className="mb-8 mt-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
            <p>The Pain-Agitate-Solution framework is a powerful tool for crafting persuasive email marketing campaigns. By identifying pain points, agitating them, and providing a solution, you can effectively address your audience's needs and drive action.</p>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
            <ul className="list-disc list-inside">
              <li><strong>Persuasive Messaging:</strong> Create compelling email content that resonates with your audience.</li>
              <li><strong>Problem-Solving:</strong> Address common pain points and offer solutions to alleviate them.</li>
            </ul>
          </section>
  
          <section className="mb-8">
            <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
            <div>
              <h3 className="text-xl text-indigo-800 mb-2">1. Identify Pain Points</h3>
              <h3 className="text-xl text-indigo-800 mb-2">2. Agitate the Problem</h3>
            <p>Highlight the consequences and challenges associated with the identified pain points.</p>
            <h3 className="text-xl text-indigo-800 mb-2">3. Offer Solutions</h3>
            <p>Present your product or service as the solution to your audience's problems.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Effectiveness:</strong> Persuasive messaging increases the likelihood of audience engagement and conversion.</li>
            <li><strong>Relevance:</strong> Addressing pain points demonstrates an understanding of your audience's needs and concerns.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>

      </div>
      <ElaborateFooter />
    </>
  );
};

export default PainAgitateSolutionPage;
  