import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const LinkedInSingleImageAdsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <a href="/templates" className="text-indigo-600 mb-4 inline-block"> &larr; Back to templates</a>
        <h1 className="text-3xl text-indigo-800">LinkedIn Single Image Ads</h1>
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Overview</h2>
          <p>Use Graphia with LinkedIn Single Image Ads to reach the right professional audience. This tool helps you create visually appealing and engaging ad copy that drives interaction and conversions on LinkedIn.</p>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Visual Ad Creation:</strong> Create visually appealing ads that catch attention.</li>
            <li><strong>Professional Messaging:</strong> Craft ad copy that resonates with LinkedIn's professional audience.</li>
            <li><strong>Performance Tips:</strong> Optimize your ads with tips for better performance.</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-700 mb-2">1. Select Your Image</h3>
            <p>Choose an image that aligns with your ad message.</p>
            <h3 className="text-xl text-indigo-700 mb-2">2. Generate Ad Copy</h3>
            <p>Generate multiple versions of ad copy to complement your image.</p>
            <h3 className="text-xl text-indigo-700 mb-2">3. Optimize and Publish</h3>
            <p>Optimize your ads with provided tips and publish them on LinkedIn.</p>
          </div>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Enhanced Engagement:</strong> Boost engagement with visually appealing ads.</li>
            <li><strong>Increased Click-Through Rate:</strong> Enhance your ads' CTR with compelling visuals and copy.</li>
            <li><strong>Higher Conversion Rate:</strong> Drive more conversions with optimized ads.</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-800 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Product Promotions:</strong> Promote your products with engaging visuals.</li>
            <li><strong>Event Advertising:</strong> Advertise events and attract attendees.</li>
            <li><strong>Content Marketing:</strong> Promote your content effectively on LinkedIn.</li>
          </ul>
        </section>
        <section>
          <h2 className="text-2xl text-indigo-800 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-800" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default LinkedInSingleImageAdsPage;
