import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import QuestionsInput from './assets/QuestionsInput.png';
import QuestionsOutput from './assets/QuestionsOutput.png';

const QuestionsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Questions</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>A tool to create engaging questions and polls that increase audience participation and engagement. This tool helps you spark discussions and gather valuable feedback.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Engaging Questions:</strong> Create thought-provoking questions to stimulate discussion.</li>
            <li><strong>Interactive Polls:</strong> Conduct polls to gather feedback and insights from your audience.</li>
            <li><strong>Customizable:</strong> Tailor questions and polls to fit your content and audience.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Topic</h3>
            <img src={QuestionsInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Questions</h3>
            <img src={QuestionsOutput} alt="Generated Questions" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Edit or add questions to better engage your audience or gather specific insights.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Engagement:</strong> Encourage audience participation and interaction.</li>
            <li><strong>Feedback Collection:</strong> Gather valuable feedback and insights from your audience.</li>
            <li><strong>Content Ideas:</strong> Use audience responses to generate new content ideas.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Social Media:</strong> Spark discussions and interactions on social media platforms.</li>
            <li><strong>Content Planning:</strong> Gather feedback to inform your content strategy and topics.</li>
            <li><strong>Market Research:</strong> Conduct polls to understand customer preferences and opinions.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default QuestionsPage;
