import React from "react"
export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 bg-gradient-to-r  to-[#4665e5] from-[#7746e5] px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
	  	Begin crafting compelling content now.
	  </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
		Envision a crew of experts producing remarkable content for you with a simple click.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="https://app.graphia.ai/"
              className="rounded-md bg-white px-3.5 py-2.5 text-md font-semibold text-indigo-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
             target="_blank"
            >
              Get started
            </a>
            {/*<a href="#" className="text-sm font-semibold leading-6 text-white">
              Learn more <span aria-hidden="true">→</span>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

