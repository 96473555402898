import React from 'react';
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';
import CTA from '../common/CTA.js';
import Emails from './assets/emails.svg';
import SalesPitches from './assets/sales.svg';
import Outreach from './assets/outreach.svg';
import { CheckBadgeIcon } from '@heroicons/react/20/solid'

const emails = [
  {
    name: 'Email Templates',
    description: 'for subject lines, body content, CTAs & more',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Automated Follow-ups',
    description: 'Personalized messages that nurture leads and close deals',
    icon: CheckBadgeIcon,
  },
]

const pitches = [
  {
    name: 'Pitch Templates ',
    description: 'for opening statements, value propositions, closing remarks & more',
    icon: CheckBadgeIcon,
  },
  {
    name: 'Customized Presentations',
    description: 'Tailored messages that address specific pain points and showcase unique solutions',
    icon: CheckBadgeIcon,
  },
]

const outreach = [
	{ 
    name: 'Sequence Templates',
    description: 'for initial contact, follow-up messages, and closing interactions',
    icon: CheckBadgeIcon,
  },
  { 
    name: 'Personalized Touchpoints ',
    description: "Tailored outreach that resonates with each prospect's needs and preferences",
    icon: CheckBadgeIcon,
  },
]


export default function Sales() {
  return (
  <React.Fragment>
    <Header/>
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-indigo-600">Sales</p>
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Boost Sales <br/> with AI-Optimized Strategies</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">Unleash the power of AI to amplify sales performance and drive revenue growth.
        </p>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Emails</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Cold Emails.<br/>Hot Leads.</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Craft emails easily! Reach wider audiences. Stand out and drive conversions.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {emails.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
	    src={Emails}
            alt="Emails"
            className="w-[36rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem] md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Sales Pitches</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">Persuasive Pitches.<br/>Increased Sales. </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Increase Sales with Persuasive Pitches. Elevate Your Pitching Game for Enhanced Revenue Generation.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {pitches.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={SalesPitches}
              alt="Sales"
              className="w-[36rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem]"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Outreach Sequences</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl">1-2-3. <br/>Done. Done. Done.</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
		Design Strategies to Cultivate Lasting Connections, Nourish Leads, and Propel Sales Success
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {outreach.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={Outreach}
            alt="Outreach"
            className="w-[36rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[36rem] md:-ml-4 lg:-ml-0"
          />
        </div>
      </div>
    </div>
   <CTA/>
    <ElaborateFooter/>
 </React.Fragment>
  )
}
 
