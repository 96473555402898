import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import BlogPostShortFormInput from './assets/BlogPostShortFormInput.png';
import BlogPostShortFormOutput from './assets/BlogPostShortFormOutput.png';

const BlogPostShortFormPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Blog Post (Short form)</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft engaging and informative short-form blog posts. This tool helps you create concise and impactful content that effectively conveys your message and engages your audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Concise Content:</strong> Generate succinct and engaging blog posts.</li>
            <li><strong>SEO Optimization:</strong> Ensure your short-form content is optimized for search engines.</li>
            <li><strong>Customizable:</strong> Tailor the content to match your specific requirements and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Topic</h3>
            <img src={BlogPostShortFormInput} alt="Input Topic" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Content</h3>
            <img src={BlogPostShortFormOutput} alt="Generated Content" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the generated content to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Efficiency:</strong> Quickly create impactful content that resonates with your audience.</li>
            <li><strong>Engagement:</strong> Keep readers engaged with concise and relevant information.</li>
            <li><strong>SEO Benefits:</strong> Enhance your content's search engine visibility with optimized short-form posts.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Creators:</strong> Produce engaging blog posts quickly and efficiently.</li>
            <li><strong>Marketers:</strong> Generate short-form content for marketing purposes.</li>
            <li><strong>Bloggers:</strong> Create concise posts that effectively communicate your message.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BlogPostShortFormPage;
