import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const GoogleAdsPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <button
          onClick={() => window.history.back()}
          className="text-sm text-indigo-600 mb-4"
        >
          &larr; Back to templates
        </button>
        <h1 className="text-3xl text-indigo-600">Google Ads</h1>
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>
            Craft compelling Google ads for effective campaigns. Our Google Ads tool helps you create ads that stand out and attract clicks, driving traffic and conversions for your business.
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Ad Copy Creation:</strong> Generate engaging ad copy tailored to your target audience.</li>
            <li><strong>Keyword Integration:</strong> Incorporate relevant keywords to improve ad visibility.</li>
            <li><strong>Performance Optimization:</strong> Get suggestions to enhance ad performance.</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Enter Your Keywords</h3>
            <p>Provide the keywords you want to target in your Google Ads.</p>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Ad Copy</h3>
            <p>Receive multiple ad copy variations based on your keywords.</p>
            <h3 className="text-xl text-indigo-800 mb-2">3. Optimize Your Ads</h3>
            <p>Get performance suggestions to make your ads more effective.</p>
          </div>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Higher Click-Through Rates:</strong> Attract more clicks with compelling ad copy.</li>
            <li><strong>Better Ad Relevance:</strong> Improve ad relevance with targeted keywords.</li>
            <li><strong>Increased Conversions:</strong> Drive more conversions with optimized ads.</li>
          </ul>
        </section>
        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>E-commerce:</strong> Promote your products and drive sales with targeted ads.</li>
            <li><strong>Local Businesses:</strong> Attract local customers by promoting your services.</li>
            <li><strong>Online Services:</strong> Increase sign-ups and conversions for online services.</li>
          </ul>
        </section>
        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default GoogleAdsPage;
