// ShortSummaryPage.js
import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';

const ShortSummaryPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Short Summary</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Craft concise and impactful summaries with the Short Summary tool. Summarize lengthy content into brief yet informative snippets, perfect for providing key insights and capturing audience attention.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Concise Summaries:</strong> Generate short summaries that capture the essence of longer content.</li>
            <li><strong>Key Insights:</strong> Highlight key points and main ideas for quick understanding.</li>
            <li><strong>Engagement:</strong> Keep readers engaged with succinct and impactful summaries.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-500 mb-2">1. Input Content</h3>
            <p>Paste or type the content you want to summarize into the input field.</p>
            <h3 className="text-xl text-indigo-500 mb-2">2. Generate Summary</h3>
            <p>Click the generate button to create a concise summary of the input content.</p>
            <h3 className="text-xl text-indigo-500 mb-2">3. Review and Edit</h3>
            <p>Review the generated summary and make any necessary adjustments.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Time-Saving:</strong> Quickly extract key insights from lengthy content.</li>
            <li><strong>Clarity:</strong> Provide clear and concise summaries for easy understanding.</li>
            <li><strong>Engagement:</strong> Keep readers engaged with brief yet informative summaries.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Content Curation:</strong> Summarize articles, reports, and research papers for reference.</li>
            <li><strong>Study Notes:</strong> Create concise summaries of textbooks and study materials.</li>
            <li><strong>Presentations:</strong> Use short summaries to convey key points in presentations.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ShortSummaryPage;
