import React from 'react'; 

const featuredTestimonial = {
  body:"Graphia has transformed the way I approach content creation for my business. Its AI-powered platform not only saves me time but also consistently produces content that resonates with my audience. With Graphia, I can confidently create captivating blogs, articles, and product descriptions that drive results. It's an invaluable tool in my marketing arsenal!",
  author: {
    name: 'Raghu Bharath',
    handle: 'Raghu Bharath',
    imageUrl:
	'https://media.licdn.com/dms/image/C4D03AQF7YPNgD0nIZQ/profile-displayphoto-shrink_400_400/0/1617213490779?e=1718841600&v=beta&t=JBdozfwIjEBUQbBgnTUw5xKq5J-VY5KhftkRSdXXCkA',
    logoUrl: 
	"https://media.licdn.com/dms/image/C4D0BAQF1mnuFPaDhhw/company-logo_200_200/0/1672633284686/crewscale_logo?e=1721260800&v=beta&t=BdQ5JbVaaF2trGToZMbEDQi0ii0fd6cmEBSy74EOA2A",
  },
}
const testimonials = [
  [
    [
      {
        body: "Graphia has become my secret weapon for content marketing. Whether I need blog posts, articles, or catchy product descriptions, it consistently delivers high-quality content that resonates with my audience. It's a total game-changer!",
        author: {
          name: 'Aayush Aggarwal',
          handle: 'AayushAggarwal',
          imageUrl:
		'https://media.licdn.com/dms/image/D5603AQGDOzOfdLu7wg/profile-displayphoto-shrink_400_400/0/1713003149418?e=1718841600&v=beta&t=Bsq7n4zufLTYPmQMxvEeNYNo7TolIA5SV9Rd0QUZWEM',
        },
      },
      { 
        body: "I was skeptical about using AI for content creation, but Graphia has exceeded my expectations. The quality of the articles and blogs it produces is outstanding, and it has saved me countless hours of writing. I'm a believer!",
        author: {
          name: 'Vaidehi Bharadwaj',
          handle: 'VaidehiBharadwaj',
          imageUrl:
		'https://media.licdn.com/dms/image/D5603AQGlMgqwreSwxw/profile-displayphoto-shrink_400_400/0/1706856300839?e=1718841600&v=beta&t=ewbtBzSFcVrmbJjp4uC-YbCITEXMdQR8VtGUsM_i7Ks'
        },
      },

      // More testimonials...
    ],
    [
      {
        body: "I've tried numerous content generation tools, but none compare to Graphia. Its advanced AI algorithms generate content that is not only well-written but also tailored to my specific needs and audience. It's like having a team of expert writers at my disposal 24/7.",
        author: {
          name: 'Sandeep Gupta',
          handle: 'SandeepGupta',
          imageUrl:
		'https://media.licdn.com/dms/image/C4E03AQHGhn6sXaDtzA/profile-displayphoto-shrink_800_800/0/1550745318375?e=1718841600&v=beta&t=BlzVPr0d6ZHz2JapsUHOV-ih-k83Y5LIhHl4jVIQTzM',
        },
      },
      /*{ 
        body: 'Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.', 
        author: {
          name: 'Chinmay Relkar',
          handle: 'ChinmayRelkar',
          imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },*/
 
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: "Graphia is a must-have for anyone in digital marketing. Its ability to generate compelling landing page copy and benefit-driven titles has significantly boosted my conversion rates. I couldn't be happier with the results!",
        author: {
          name: 'Lavish Saluja',
          handle: 'LavishSaluja',
          imageUrl:
		'https://media.licdn.com/dms/image/D5603AQE-0G7PR8OZoQ/profile-displayphoto-shrink_400_400/0/1683043034215?e=1718841600&v=beta&t=IB44CdGko0J_pFmNjo5DdRiPEc051iqZ8dNUxyVZGu0'
        },
      },
      // More testimonials...
    ],
    [
      {
        body: "Graphia has simplified my content creation workflow and significantly reduced my workload. Whether I'm writing blog posts or crafting product descriptions, its intuitive interface and powerful AI make the entire process seamless. I highly recommend it to anyone looking to streamline their content creation process!",
        author: {
          name: 'Saurav Sahu',
          handle: 'SauravSahu',
          imageUrl:
		'https://media.licdn.com/dms/image/D5603AQGiC4OGkVvETA/profile-displayphoto-shrink_400_400/0/1708509514455?e=1718841600&v=beta&t=d3e3t2Quow9g771UvalVt7ildDCj5bouNGZMA9Y1R-k',
        },
      },
       {
        body: "I've struggled with writer's block for years, but Graphia has helped me overcome it. Its intuitive interface and AI-driven suggestions have sparked my creativity and made writing enjoyable again. Thank you for such an incredible tool!",
        author: {
          name: 'Subodh Kolhe',
          handle: 'SubodhKolhe',
          imageUrl:
		'https://media.licdn.com/dms/image/C4E03AQE19-p711LTHw/profile-displayphoto-shrink_400_400/0/1644933603791?e=1718841600&v=beta&t=I6SMLcIHvrh5qH7UnU8ZyYkOXH69yGSEY677clmRb-Q',
        },
      },
 
      // More testimonials...
    ],
  ],
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  return (
    <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
      <div
        className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true"
      >
        <div
          className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true"
      >
        <div
          className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Testimonials</h2>
          <p className="mt-2 text-6xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            We've been receiving some love!
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <div className="flex-auto">
                <div className="font-semibold">{featuredTestimonial.author.name}</div>
                {/*<div className="text-gray-600">{`${featuredTestimonial.author.handle}`}</div>*/}
              </div>
            </figcaption>
          </figure>
          {testimonials.map((columnGroup, columnGroupIdx) => (
            <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={classNames(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                      ? 'xl:row-span-2'
                      : 'xl:row-start-1',
                    'space-y-8'
                  )}
                >
                  {column.map((testimonial) => (
                    <figure
                      key={testimonial.author.handle}
                      className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                      <blockquote className="text-gray-900">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <div>
                          <div className="font-semibold">{testimonial.author.name}</div>
                          {/*<div className="text-gray-600">{`${testimonial.author.handle}`}</div>*/}
                        </div>
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

