import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
//import BacklinkOutreachEmailInput from './assets/BacklinkOutreachEmailInput.png';
//import BacklinkOutreachEmailOutput from './assets/BacklinkOutreachEmailOutput.png';

const BacklinkOutreachEmailPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Backlink Outreach Email</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Backlink Outreach Email template is designed to assist businesses in reaching out to other websites for link exchange opportunities. With this tool, you can craft professional and persuasive emails that effectively convey the benefits of collaboration and encourage other website owners to link to your content.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Customization:</strong> Tailor emails to suit the tone and style of each recipient.</li>
            <li><strong>Personalization:</strong> Address recipients by name and reference specific content or topics relevant to their website.</li>
            <li><strong>Call-to-Action:</strong> Include clear and compelling calls-to-action to encourage recipients to take the desired action.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Details</h3>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Email</h3>
            <h3 className="text-xl text-indigo-800 mb-2">3. Send and Follow Up</h3>
            <p>Send the email to your chosen recipients and follow up as necessary to maximize response rates.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Backlinks:</strong> Build a stronger backlink profile by securing links from relevant and authoritative websites.</li>
            <li><strong>Enhanced SEO:</strong> Improve your website's search engine rankings with quality backlinks from reputable sources.</li>
            <li><strong>Expanded Reach:</strong> Reach new audiences and drive more traffic to your website through referral traffic from linked sites.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default BacklinkOutreachEmailPage;
