import React from 'react';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const LanguageComponent = () => {
  const languages = [
    { name: "Albanian", flag: "🇦🇱" },
    { name: "Arabic", flag: "🇸🇦" },
    { name: "Armenian", flag: "🇦🇲" },
    { name: "Awadhi", flag: "🇮🇳" },
    { name: "Azerbaijani", flag: "🇦🇿" },
    { name: "Bashkir", flag: "🇷🇺" },
    { name: "Basque", flag: "🇪🇸" },
    { name: "Belarusian", flag: "🇧🇾" },
    { name: "Bengali", flag: "🇧🇩" },
    { name: "Bhojpuri", flag: "🇮🇳" },
    { name: "Bosnian", flag: "🇧🇦" },
    { name: "Brazilian Portuguese", flag: "🇧🇷" },
    { name: "Bulgarian", flag: "🇧🇬" },
    { name: "Cantonese", flag: "🇨🇳" },
    { name: "Catalan", flag: "🇪🇸" },
    { name: "Chhattisgarhi", flag: "🇮🇳" },
    { name: "Chinese", flag: "🇨🇳" },
    { name: "Croatian", flag: "🇭🇷" },
    { name: "Czech", flag: "🇨🇿" },
    { name: "Danish", flag: "🇩🇰" },
    { name: "Dogri", flag: "🇮🇳" },
    { name: "Dutch", flag: "🇳🇱" },
    { name: "English", flag: "🇺🇸" },
    { name: "Estonian", flag: "🇪🇪" },
    { name: "Faroese", flag: "🇫🇴" },
    { name: "Finnish", flag: "🇫🇮" },
    { name: "French", flag: "🇫🇷" },
    { name: "Galician", flag: "🇪🇸" },
    { name: "Georgian", flag: "🇬🇪" },
    { name: "German", flag: "🇩🇪" },
    { name: "Greek", flag: "🇬🇷" },
    { name: "Gujarati", flag: "🇮🇳" },
    { name: "Haryanvi", flag: "🇮🇳" },
    { name: "Hindi", flag: "🇮🇳" },
    { name: "Hungarian", flag: "🇭🇺" },
    { name: "Indonesian", flag: "🇮🇩" },
    { name: "Irish", flag: "🇮🇪" },
    { name: "Italian", flag: "🇮🇹" },
    { name: "Japanese", flag: "🇯🇵" },
    { name: "Javanese", flag: "🇮🇩" },
    { name: "Kannada", flag: "🇮🇳" },
    { name: "Kashmiri", flag: "🇮🇳" },
    { name: "Kazakh", flag: "🇰🇿" },
    { name: "Konkani", flag: "🇮🇳" },
    { name: "Korean", flag: "🇰🇷" },
    { name: "Kyrgyz", flag: "🇰🇬" },
    { name: "Latvian", flag: "🇱🇻" },
    { name: "Lithuanian", flag: "🇱🇹" },
    { name: "Macedonian", flag: "🇲🇰" },
    { name: "Maithili", flag: "🇮🇳" },
    { name: "Malay", flag: "🇲🇾" },
    { name: "Maltese", flag: "🇲🇹" },
    { name: "Mandarin", flag: "🇨🇳" },
    { name: "Mandarin Chinese", flag: "🇨🇳" },
    { name: "Marathi", flag: "🇮🇳" },
    { name: "Marwari", flag: "🇮🇳" },
    { name: "Min Nan", flag: "🇹🇼" },
    { name: "Moldovan", flag: "🇲🇩" },
    { name: "Mongolian", flag: "🇲🇳" },
    { name: "Montenegrin", flag: "🇲🇪" },
    { name: "Nepali", flag: "🇳🇵" },
    { name: "Norwegian", flag: "🇳🇴" },
    { name: "Oriya", flag: "🇮🇳" },
    { name: "Pashto", flag: "🇦🇫" },
    { name: "Persian (Farsi)", flag: "🇮🇷" },
    { name: "Polish", flag: "🇵🇱" },
    { name: "Portuguese", flag: "🇵🇹" },
    { name: "Punjabi", flag: "🇮🇳" },
    { name: "Rajasthani", flag: "🇮🇳" },
    { name: "Romanian", flag: "🇷🇴" },
    { name: "Russian", flag: "🇷🇺" },
    { name: "Sanskrit", flag: "🇮🇳" },
    { name: "Santali", flag: "🇮🇳" },
    { name: "Serbian", flag: "🇷🇸" },
    { name: "Sindhi", flag: "🇵🇰" },
    { name: "Sinhala", flag: "🇱🇰" },
    { name: "Slovak", flag: "🇸🇰" },
    { name: "Slovene", flag: "🇸🇮" },
    { name: "Slovenian", flag: "🇸🇮" },
    { name: "Spanish", flag: "🇪🇸" },
    { name: "Turkish", flag: "🇹🇷" },
    { name: "Ukrainian", flag: "🇺🇦" },
    { name: "Urdu", flag: "🇵🇰" },
    { name: "Uzbek", flag: "🇺🇿" },
    { name: "Vietnamese", flag: "🇻🇳" },
    { name: "Welsh", flag: "🏴" },
    { name: "Wu", flag: "🇨🇳" }
  ];

  const shuffledLanguages = shuffleArray([...languages]);

  return (
    <div className="py-8 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Languages</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">90+ Supported Languages</p>
        </div>
        <div className="flex flex-wrap -mx-2 mt-10">
          {shuffledLanguages.map((language, index) => (
            <div
              key={index}
              className="bg-white py-2 px-4 rounded-3xl border border-[#4665e5] flex items-center justify-center mx-2 mb-2 shadow-lg transition-transform transform hover:scale-105 hover:bg-indigo-100"
            >
              <span className="mr-2">{language.flag}</span>
              <p className="text-gray-900 text-md">{language.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
  
};

export default LanguageComponent;

