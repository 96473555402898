import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import ReviewResponderInput from './assets/ReviewResponderInput.png';
import ReviewResponderOutput from './assets/ReviewResponderOutput.png';

const ReviewResponderPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Review Responder</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>The Review Responder is a powerful tool designed to help businesses and individuals efficiently manage their online reputation by automating the creation of personalized replies to reviews. Whether it's a glowing five-star review or constructive feedback, the Review Responder ensures that each response is thoughtfully crafted, maintaining a personal touch while saving you time.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Automated Responses:</strong> Generate personalized replies to reviews based on predefined templates and AI-driven suggestions.</li>
            <li><strong>Customizable Templates:</strong> Tailor response templates to fit your brand's voice and tone.</li>
            <li><strong>Language Support:</strong> Respond to reviews in multiple languages, broadening your reach and engagement.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input the Review</h3>
            <img src={ReviewResponderInput} alt="Input Review" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Response</h3>
            <img src={ReviewResponderOutput} alt="Generated Response" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Download Response</h3>
            <p>Download the generated response and use it.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Time-Saving:</strong> Drastically reduce the time spent on responding to reviews.</li>
            <li><strong>Consistency:</strong> Ensure that all responses are consistent with your brand's voice and standards.</li>
            <li><strong>Engagement:</strong> Increase customer engagement by promptly addressing their feedback.</li>
            <li><strong>Reputation Management:</strong> Maintain a positive online presence by actively engaging with your customers.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Small Businesses:</strong> Owners can manage customer feedback efficiently without dedicating extensive time to writing replies.</li>
            <li><strong>E-commerce:</strong> Online retailers can handle large volumes of reviews, ensuring each customer feels valued.</li>
            <li><strong>Hospitality:</strong> Hotels and restaurants can quickly respond to reviews, enhancing customer satisfaction and loyalty.</li>
            <li><strong>Healthcare:</strong> Clinics and medical practices can address patient feedback promptly, maintaining a caring and responsive image.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default ReviewResponderPage;
