import React from 'react';
import ElaborateFooter from '../../../common/ElaborateFooter';
import Header from '../../../common/Header';
import TranslatorInput from './assets/TranslatorInput.png';
import TranslatorOutput from './assets/TranslatorOutput.png';

const TranslatorPage = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg">
        <div className="flex items-center mb-8">
          <button 
            onClick={() => window.location.href = '/templates'}
            className="text-indigo-600 text-md"
          >
            &larr; Back to templates
          </button>
        </div>
        <h1 className="text-3xl text-indigo-600">Translator</h1>
        
        <section className="mb-8 mt-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Overview</h2>
          <p>Translate your content into any language you want. This tool helps you break language barriers and reach a global audience.</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Key Features</h2>
          <ul className="list-disc list-inside">
            <li><strong>Multilingual Support:</strong> Translate content into multiple languages.</li>
            <li><strong>Accurate Translations:</strong> Ensure high-quality translations that retain the original meaning.</li>
            <li><strong>Customizable:</strong> Tailor translations to fit your specific needs and tone.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">How It Works</h2>
          <div>
            <h3 className="text-xl text-indigo-800 mb-2">1. Input Your Text</h3>
            <img src={TranslatorInput} alt="Input Text" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">2. Generate Translation</h3>
            <img src={TranslatorOutput} alt="Generated Translation" className="mb-4"/>
            <h3 className="text-xl text-indigo-800 mb-2">3. Customize (Optional)</h3>
            <p>Modify the translation to better fit your needs and preferences.</p>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Benefits</h2>
          <ul className="list-disc list-inside">
            <li><strong>Increased Reach:</strong> Connect with a global audience by translating your content.</li>
            <li><strong>Improved Communication:</strong> Break language barriers and communicate more effectively.</li>
            <li><strong>Time-Saving:</strong> Quickly translate content without the need for manual translation.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl text-indigo-600 mb-4">Use Cases</h2>
          <ul className="list-disc list-inside">
            <li><strong>Bloggers:</strong> Translate your blog posts to reach a wider audience.</li>
            <li><strong>Content Creators:</strong> Make your content accessible in multiple languages.</li>
            <li><strong>Businesses:</strong> Translate marketing materials to connect with international customers.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl text-indigo-600 mb-4">Use This Template</h2>
          <p className="mt-4"><a className="text-md text-indigo-600" href="https://app.graphia.ai">Sign up</a> & get started</p>
        </section>
      </div>
      <ElaborateFooter />
    </>
  );
};

export default TranslatorPage;
